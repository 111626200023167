/* eslint-disable no-param-reassign, react/jsx-props-no-spreading */
import { h } from 'preact';
import { useEffect, useRef, useState } from 'preact/hooks';
import { memo } from 'preact/compat';
import { signal } from '@preact/signals';
import memoizeOne from 'memoize-one';
import isDeepEqual from 'lodash.isequal';

import Logger from '../../utils/logger';
import Tracer from '../../utils/tracer';
import loadAppManifest from '../../app-manifest/manifest-loader';
import loadAppAssets from '../../app-manifest/assets-loader';
import { registerTranslations } from '../../utils/translations';
import { NOOP } from '../../constants';

export function createCache() {
  return signal({});
}

const EMPTY_STYLE = {};
const ongoingCreateRequestMap = new Map();
const destroyMicroAppCache = new WeakMap();
const renderAppCache = createCache();

function registerAppLocales(localesDataList) {
  localesDataList.forEach(({ namespace, data }) => {
    registerTranslations(namespace, data);
  });
}

function destroy(node) {
  const unmount = destroyMicroAppCache.get(node) || NOOP;
  destroyMicroAppCache.delete(node);

  return unmount();
}

function singleComponentMicroAppRender(App) {
  return (node, props, callback) => {
    destroy(node);

    return App.create({ container: node, ...props }).then(() =>
      callback({ unmount: App.destroy || NOOP }),
    );
  };
}

async function multiComponentMicroAppRender(
  App,
  componentName,
  globalSettings,
) {
  const ongoingCreateRequest =
    ongoingCreateRequestMap.get(App.name) || App.create(globalSettings);

  ongoingCreateRequestMap.set(App.name, ongoingCreateRequest);

  const { render } = await ongoingCreateRequest;

  return (node, { settings, ...props }, callback) => {
    destroy(node);

    const wrapper = render(componentName, props, node);

    callback({ unmount: wrapper.unmount });
  };
}

function microAppCachedSetup(cache) {
  async function importMicroApp(microApp, componentName, manifest, locale) {
    const isMultipleComponentMicroApp = !!componentName;
    const isCached = !!cache.value[microApp];
    const hasMatchingReleaseVersion =
      (isCached && cache.value[microApp].createdAt === manifest.createdAt) ||
      false;

    if (isCached && hasMatchingReleaseVersion) return cache.value[microApp];

    const { App, translationDataList } = await loadAppAssets(
      microApp,
      manifest,
      locale,
    );

    registerAppLocales(translationDataList);
    const createFn = isMultipleComponentMicroApp
      ? memoizeOne(App.create, isDeepEqual)
      : App.create;

    cache.value[microApp] = {
      name: microApp,
      create: createFn,
      destroy: App.destroy,
      createdAt: manifest.createdAt,
    };

    return cache.value[microApp];
  }

  async function loadRenderFunction(App, componentName, settings) {
    const render = await (componentName
      ? multiComponentMicroAppRender(App, componentName, settings)
      : singleComponentMicroAppRender(App));

    return render;
  }

  return {
    importMicroApp,
    loadRenderFunction,
  };
}

async function loadMicroAppRender(
  microApp,
  componentName,
  manifest,
  settings,
  cache,
) {
  const { locale } = settings;

  if (!locale) {
    throw new Error('Missing settings.locale value!');
  }

  const { importMicroApp, loadRenderFunction } = microAppCachedSetup(cache);

  const App = await importMicroApp(microApp, componentName, manifest, locale);
  const render = await loadRenderFunction(App, componentName, settings);

  return render;
}

function cacheDestroyFn(node, renderCallbackArgs) {
  destroyMicroAppCache.set(node, renderCallbackArgs.unmount);
}

function Content({
  microApp,
  componentName,
  manifest,
  onAction,
  settings,
  apiClient,
  el,
  cache,
  loadingStart,
  style,
  loading,
  ...props
}) {
  const ref = useRef(null);
  const [isLoading, setLoading] = useState(!!loading);

  const appLoadedCallback = (renderCallbackArgs) => {
    setLoading(false);
    cacheDestroyFn(ref.current, renderCallbackArgs);
    ongoingCreateRequestMap.delete(microApp);

    Tracer.addAction(`${microApp}.micro_app.loaded`, {
      microApp: { loadingTimeInMs: Date.now() - loadingStart },
    });
  };

  useEffect(() => () => destroy(ref.current), [ref.current]);

  useEffect(() => {
    async function renderApp() {
      const render = await loadMicroAppRender(
        microApp,
        componentName,
        manifest,
        settings,
        cache,
      );

      const microAppProps = {
        useHashHistory: true,
        ...props,
        apiClient,
        settings,
        onAction,
      };

      return render(ref.current, microAppProps, appLoadedCallback);
    }

    if (ref.current && manifest) {
      renderApp().catch((error) => {
        Logger.error('MicroApp Error: Failed to load app assets', {
          app: microApp,
          args: JSON.stringify({ ...props, settings }),
          raisedError: {
            name: error.name,
            message: error.message,
          },
        });
      });
    }
  }, [ref.current, manifest, props]);

  return h(el, { ref, style }, isLoading ? loading : null);
}

function MicroApp({
  el = 'div',
  cache = renderAppCache,
  loadingStart = Date.now(),
  name,
  style = EMPTY_STYLE,
  loading = null,
  ...props
}) {
  const [manifest, setManifest] = useState(null);
  const [microApp, componentName] = name.split('/');

  const updateManifest = (newManifest) => {
    setManifest(newManifest);
  };

  useEffect(() => {
    loadAppManifest(microApp)
      .then(updateManifest)
      .catch((error) => {
        Logger.error('MicroApp Error: Failed to load app manifest', {
          app: name,
          args: JSON.stringify(props),
          raisedError: {
            name: error.name,
            message: error.message,
          },
        });
      });
  }, []);

  return (
    <Content
      microApp={microApp}
      componentName={componentName}
      el={el}
      manifest={manifest}
      cache={cache}
      loadingStart={loadingStart}
      style={style}
      loading={loading}
      {...props}
    />
  );
}

export default memo(MicroApp);
/* eslint-enable no-param-reassign, react/jsx-props-no-spreading */
