/* eslint-disable no-console  */
import cn from 'classnames';
import { h, Fragment } from 'preact';
import { useEffect } from 'preact/compat';
import { Outlet } from 'react-router-dom';
import { useSelector } from 'react-redux';

import Widgets from './containers/Widgets';
import Overlay from './containers/Overlay';
import Spinner from './containers/Spinner';
import Tracer from './utils/tracer';
import decodeMinifiedStacktrace from './utils/decode-minified-stacktrace';
import apiEventBus from './api-event-bus';
import createQueryConfig from './queries';
import { shouldBlur } from './selectors';
import { PageLayout } from './components/Layouts';
import { useGlobals } from './globals-context';
import { API_EVENTS } from './constants/event-bus-events';
import { NOOP } from './constants';
import { window } from './env/browser';

function trackDecodedError(type) {
  return (encodedError) => {
    if (!encodedError) {
      return Promise.resolve();
    }

    return decodeMinifiedStacktrace(encodedError)
      .then((error) => {
        console.error(error);
        const context = error.appName ? { appName: error.appName } : {};

        Tracer.addError(error, {
          customError: { ...context, type, decoded: true },
        });
      })
      .catch(() => {
        console.error(new Error('Decoding error process has failed'));
        console.error(encodedError);
        Tracer.addError(encodedError, {
          customError: { type, decoded: false },
        });
      });
  };
}

const globalErrorHandler = (event) => {
  event.preventDefault();

  return trackDecodedError('error')(event.error);
};

const globalPromiseRejectionHandler = (event) => {
  event.preventDefault();

  return trackDecodedError('unhandled_promise_rejection')(event.reason);
};

function eventsMap(queryClient, apiClient) {
  const queryConfig = createQueryConfig(queryClient, apiClient);
  const { token } = API_EVENTS;

  return (withCallbacks) => ({
    [token.load]: withCallbacks(
      token.success,
      token.error,
    )(function requestToken() {
      return queryClient.fetchQuery(queryConfig.authToken());
    }),
  });
}

function DeprecatedOverlay({ tools, children }) {
  const currentOverlay = useSelector((state) => state.currentOverlay);
  const isBlurred = useSelector(shouldBlur);

  const overlayBlurEffect = cn('as-App', {
    'is-blurred': isBlurred,
    'is-temporarilyUnblurred': currentOverlay.isTemporarilyHidden,
  });

  return (
    <Fragment>
      <Spinner />
      <div class={overlayBlurEffect}>{children}</div>

      {/* eslint-disable-next-line react/jsx-props-no-spreading */}
      <Overlay {...tools} onProxyAction={NOOP} onInstance={NOOP} />
    </Fragment>
  );
}

function AppShell() {
  const tools = useGlobals();
  const { apiClient, queryClient, eventBus, trackMethod } = tools;

  function subscribeToEventBusEvents() {
    const { unsubscribe } = apiEventBus(eventsMap(queryClient, apiClient))(
      eventBus,
    );

    function tracerActionCallback(action) {
      const { actionName, payload } = action.payload;
      Tracer.addAction(actionName, { payload, metadata: action.metadata });
    }

    eventBus.on('tracer.action', tracerActionCallback);

    return () => {
      unsubscribe();

      eventBus.off('tracer.action', tracerActionCallback);
    };
  }

  function subscribeToMicroAppsErrorHandlers() {
    window.addEventListener(
      'unhandledrejection',
      globalPromiseRejectionHandler,
    );

    window.addEventListener('error', globalErrorHandler);

    return () => {
      window.removeEventListener(
        'unhandledrejection',
        globalPromiseRejectionHandler,
      );

      window.removeEventListener('error', globalErrorHandler);
    };
  }

  useEffect(subscribeToEventBusEvents, []);
  useEffect(subscribeToMicroAppsErrorHandlers, []);

  return (
    <Fragment>
      <PageLayout
        apiClient={apiClient}
        eventBus={eventBus}
        trackMethod={trackMethod}
      >
        <div id="asMain" class="as-Main">
          <DeprecatedOverlay tools={tools}>
            <Outlet />
          </DeprecatedOverlay>
        </div>
      </PageLayout>
      <Widgets />
    </Fragment>
  );
}

export default AppShell;
/* eslint-enable no-console  */
