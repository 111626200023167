import Tracer from './utils/tracer';

function withCallbacks(eventBus) {
  return (successEvent, errorEvent) =>
    (callbackFn) =>
    ({ payload, metadata = {} }) => {
      Tracer.addAction('api.event_bus.request', {
        eventBus: {
          payload,
          metadata,
        },
      });

      return callbackFn(payload)
        .then((response) => {
          Tracer.addAction('api.event_bus.success_response', {
            eventBus: {
              payload,
              metadata: {
                ...metadata,
                event: successEvent,
              },
            },
          });

          eventBus.emit(successEvent, response);
        })
        .catch((error) => {
          // eslint-disable-next-line no-console
          console.error(error);

          Tracer.addAction('api.event_bus.error_response', {
            eventBus: {
              payload,
              metadata: {
                ...metadata,
                event: successEvent,
              },
            },
          });

          eventBus.emit(errorEvent, error);
        });
    };
}

export default function apiEventBus(createEventCallbackMap) {
  return (eventBus) => {
    const subscribedEventsList = Object.entries(
      createEventCallbackMap(withCallbacks(eventBus)),
    );

    subscribedEventsList.forEach(([event, callback]) => {
      eventBus.on(event, callback);
    });

    return {
      unsubscribe: () => {
        subscribedEventsList.forEach(([event, callback]) => {
          eventBus.off(event, callback);
        });
      },
    };
  };
}
