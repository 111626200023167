/* eslint-disable react/jsx-props-no-spreading */
import { h } from 'preact';
import Button from '../Button';

function IconButton({ iconName, ...buttonProps }) {
  return (
    <Button {...buttonProps}>
      <shore-icon name={iconName} />
    </Button>
  );
}

export default IconButton;
/* eslint-enable react/jsx-props-no-spreading */
