import { h } from 'preact';
import { useState, useEffect, useCallback } from 'preact/hooks';
import { signal } from '@preact/signals';
import debounce from 'lodash.debounce';
import mitt from 'mitt';

import { NO_PROPS, containerStyle } from './constants';
import { useGlobals } from '../src/globals-context';
import DevMicroApp from './dev-micro-app';

const propsFormCache = signal({});
const playgroundEventBus = mitt();
const debouncedEmit = debounce(playgroundEventBus.emit, 300);

function getComponentNames(iterator, item, names = []) {
  const currentItem = item || iterator.next();

  if (currentItem.done) {
    return names;
  }

  return getComponentNames(iterator, iterator.next(), [
    ...names,
    currentItem.value,
  ]);
}

const menuStyle = {
  flex: '2',
  height: '100%',
  padding: '7% 0%',
};

const playgroundContainerStyle = {
  flex: '8',
  height: '100%',
};

const inputStyle = {
  display: 'flex',
  flexDirection: 'column',
};

function ComponentsMenu({ items, showComponent }) {
  return (
    <nav style={menuStyle}>
      <ul>
        {items.map((name) => (
          <li>
            <button onClick={showComponent} type="button">
              {name}
            </button>
          </li>
        ))}
      </ul>
    </nav>
  );
}

function PropsForm({ serviceName, componentName, propsSchema, toggleForm }) {
  const propsCacheKey = `${serviceName}/${componentName}`;
  const [props, setProps] = useState(propsFormCache.value[propsCacheKey] || {});

  function closeForm() {
    toggleForm(false);
  }

  function onInput(propName) {
    return (event) => {
      const value = propsSchema[propName].type(event.target.value);

      const newProps = { ...props, [propName]: value };
      propsFormCache.value[propsCacheKey] = newProps;
      setProps(newProps);
    };
  }

  useEffect(() => {
    function validateProps() {
      return Object.entries(propsSchema).every(
        ([propKey, { required }]) => required && !!props[propKey],
      );
    }

    if (validateProps()) {
      debouncedEmit(`${componentName}.ready`, props);
    }
  }, [props]);

  return (
    <div style={menuStyle}>
      <button onClick={closeForm} type="button">
        Back
      </button>
      <form>
        {Object.keys(propsSchema).map((propName) => (
          <div style={inputStyle}>
            <label htmlFor={propName}>{propName}</label>
            <input
              id={propName}
              onChange={onInput(propName)}
              value={props[propName]}
            />
          </div>
        ))}
      </form>
    </div>
  );
}

function Playground({
  serviceName,
  componentsApiSchema,
  useSetupSettingsHook,
}) {
  const deps = useGlobals();
  const componentsApiSchemaMap = new Map(componentsApiSchema);
  const menuItems = getComponentNames(componentsApiSchemaMap.keys());
  const [componentName, setComponent] = useState(menuItems[0]);
  const propsSchema = componentsApiSchemaMap.get(componentName);

  const [isPropsFormRequired, togglePropsForm] = useState(!!propsSchema);
  const [[isComponentReady, props], setReadiness] = useState([false, null]);
  const { isLoading: isLoadingSettings, settings } = useSetupSettingsHook(deps);

  if (isLoadingSettings) {
    return null;
  }

  const showComponent = useCallback((e) => {
    setComponent(e.target.innerHTML);
  }, []);

  function resetComponentReadiness() {
    setReadiness([propsSchema === NO_PROPS, null]);
  }

  function watchComponentReadinessEvents() {
    function toggleReady(incomingProps) {
      setReadiness([true, incomingProps]);
    }

    playgroundEventBus.on(`${componentName}.ready`, toggleReady);

    return () => {
      playgroundEventBus.off(`${componentName}.ready`, toggleReady);
    };
  }

  function collectRequiredProps() {
    togglePropsForm(!!propsSchema);
  }

  useEffect(resetComponentReadiness, [componentName]);
  useEffect(watchComponentReadinessEvents, [componentName]);
  useEffect(collectRequiredProps, [propsSchema]);

  return (
    <div style={containerStyle}>
      {isPropsFormRequired ? (
        <PropsForm
          serviceName={serviceName}
          componentName={componentName}
          propsSchema={propsSchema}
          toggleForm={togglePropsForm}
          playgroundEventBus={playgroundEventBus}
        />
      ) : (
        <ComponentsMenu items={menuItems} showComponent={showComponent} />
      )}

      <div style={playgroundContainerStyle}>
        {isComponentReady ? (
          <DevMicroApp
            key={componentName}
            serviceName={serviceName}
            componentName={componentName}
            settings={settings}
            props={props}
          />
        ) : null}
      </div>
    </div>
  );
}

export default Playground;
