const EVENTS = {
  CLOSE_MENU: 'ui.action.close_menu',
  DISABLE_SPINNER: 'ui.global_spinner.disable',
  ENABLE_SPINNER: 'ui.global_spinner.enable',
  NAVIGATE: 'navigate',
};

function dataEventBusMap(resourceName) {
  return {
    load: `data.${resourceName}.load`,
    success: `data.${resourceName}.success`,
    error: `data.${resourceName}.error`,
  };
}

export const API_EVENTS = {
  token: dataEventBusMap('token'),
};

export default EVENTS;
