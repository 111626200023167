import {
  isString,
  isArray,
  isObject,
  isNotBlank,
  isSHA1,
  isISODateString,
  isBaseURL,
  isRelativeFilePath,
  includes,
  endsWith,
  every,
  key,
} from '../utils/validators';

const translationValidators = [
  isObject,
  key('template', isString, isRelativeFilePath('json'), includes('__LANG__')),
  key('namespace', isString, isNotBlank),
];

const schema = {
  lastModified: {
    required: true,
    validators: [isString, isNotBlank],
  },
  exposed: {
    required: true,
    validators: [isString, isNotBlank],
  },
  createdAt: {
    required: true,
    validators: [isString, isISODateString],
  },
  gitRevision: {
    required: true,
    validators: [isString, isSHA1],
  },
  baseURL: {
    required: true,
    validators: [isString, isBaseURL, endsWith('/')],
  },
  script: {
    required: true,
    validators: [isString, isRelativeFilePath('js')],
  },
  style: {
    required: false,
    validators: [isString, isRelativeFilePath('css')],
  },
  translation: {
    required: false,
    validators: translationValidators,
  },
  translations: {
    required: false,
    validators: [isArray, every(...translationValidators)],
  },
  externalDependencies: {
    required: false,
    validators: [isArray, every(isBaseURL, endsWith('.js', '.css'))],
  },
  additionalCreateOptions: {
    required: false,
    validators: [isObject],
  },
};

export default schema;
