// eslint-disable-next-line no-unused-vars
import { h } from 'preact';
import { useEffect } from 'preact/compat';
// eslint-disable-next-line import/no-extraneous-dependencies
import { MessageOutlined } from '@ant-design/icons';
import { document, window } from '../../../env/browser';

function loadIntercomScript({ appId, onIntercomReady }) {
  const scriptId = 'shore-intercom';
  if (!document.getElementById(scriptId)) {
    const head = document.querySelector('head');
    const script = document.createElement('script');

    script.id = scriptId;
    script.type = 'text/javascript';
    script.async = true;
    script.src = `https://widget.intercom.io/widget/${appId}`;
    script.onload = () => {
      if (typeof onIntercomReady === 'function') {
        onIntercomReady();
      }
    };
    script.onerror = () => {
      console.error('Failed to load Intercom script.');
    };

    if (head) {
      head.appendChild(script);
    }
  }
}

export function IntercomChatbot({
  appId,
  name,
  email,
  userId,
  isUserAdmin,
  isTrialUser,
  isKeyAccount,
  merchantId,
  merchantPhoneNumber,
  merchantIndustry,
  organizationId,
  organisationName,
  onIntercomReady,
}) {
  useEffect(() => {
    window.intercomSettings = {
      // Identifier
      user_id: userId,
      // Standard Intercom fields
      app_id: appId,
      name,
      email,
      phone: merchantPhoneNumber,
      api_base: 'https://api-iam.eu.intercom.io',
      alignment: 'right',
      action_color: '#5860d6',
      background_color: '#5860d6',
      custom_launcher_selector: '#shore_intercom_launcher',
      hide_default_launcher: true,
      company: {
        name: organisationName,
        company_id: organizationId,
        industry: merchantIndustry,
      },
      // Custom attributes
      merchant_id: merchantId,
      organization_id: organizationId,
      is_user_admin: isUserAdmin,
      is_trial_user: isTrialUser,
      account_type: isTrialUser ? 'trial' : 'paid',
      is_key_account: isKeyAccount,
      bo_pos_link: '',
      bo_bnm_link: `https://backoffice.shore.com/organizations/${organizationId}/merchants`,
    };

    if (window.Intercom && typeof window.Intercom === 'function') {
      window.Intercom('update', window.intercomSettings);
    } else {
      const intercom = (...args) => intercom.c(args);
      intercom.q = [];
      intercom.c = (args) => intercom.q.push(args);

      window.Intercom = intercom;

      if (document.readyState === 'complete') {
        loadIntercomScript({ appId, onIntercomReady });
      } else if (window.attachEvent) {
        window.attachEvent(
          'onload',
          loadIntercomScript({ appId, onIntercomReady }),
        );
      } else {
        window.addEventListener(
          'load',
          loadIntercomScript({ appId, onIntercomReady }),
          false,
        );
      }

      window.Intercom('boot', window.intercomSettings);
    }
    return () => {
      if (window.Intercom) {
        window.Intercom('shutdown');
      }
    };
  }, [appId, name, email, userId, organizationId, organisationName]);

  return (
    <div class="as-ShoreIntercomLauncher" id="shore_intercom_launcher">
      <MessageOutlined />
    </div>
  );
}
