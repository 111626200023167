import { applyMiddleware, compose, createStore } from 'redux';
import * as tracker from '@shore/shore-tracker';

import createDebug from '../utils/debug';
import reducers from '../reducers';
import * as effects from '../effects';
import { values } from '../utils/object';
import actionEventMapping from './action-event-mapping';
import { createFxMiddleware } from './fx-middleware';
import { window } from '../env/browser';
import { NODE_ENV } from '../constants';

const debug = createDebug('store');

const withDevTools = (middleware) => {
  debug('using redux dev tools');
  // eslint-disable-next-line no-underscore-dangle
  const devToolExtension = window.__REDUX_DEVTOOLS_EXTENSION__;
  const devTools = devToolExtension && devToolExtension();
  if (devTools) return compose(middleware, devTools);
  return middleware;
};

export default (deps, initialState = {}) => {
  const fxList = values(effects);
  const fxMiddleware = createFxMiddleware(fxList, deps);
  const middlewares = [fxMiddleware];
  const { trackMethod } = deps;

  if (trackMethod) {
    tracker.initialize({ trackMethod });
    const trackerMiddleware = tracker.createReduxMiddleware(actionEventMapping);
    middlewares.push(trackerMiddleware);
  }

  let middleware = applyMiddleware(...middlewares);

  if (NODE_ENV === 'development') {
    middleware = withDevTools(middleware);
  }

  const store = middleware(createStore)(reducers, initialState);

  return store;
};
