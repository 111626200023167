import createQueryConfig, { NotFoundError } from '../queries';

export async function fetchSubscription(merchantId, apiClient, queryClient) {
  try {
    const queryConfig = createQueryConfig(queryClient, apiClient);
    const { queryKey, queryFn } = queryConfig.subscription({ merchantId });
    const response = await queryClient.fetchQuery(queryKey, queryFn);

    return response;
  } catch (error) {
    if (error instanceof NotFoundError) {
      return null;
    }

    throw error;
  }
}
