// eslint-disable-next-line import/prefer-default-export
export const defaultUiVisibility = {
  employee: {},
  user_profile: { company: 'active', settings: 'active' },
};

export const getUiVisibility = (response) => {
  const getVisibilityValue = (resp) =>
    resp && resp.ui_visibility ? resp.ui_visibility : null;

  const hasUserProfileSet = (uiVisibility) =>
    !!(
      uiVisibility &&
      uiVisibility.user_profile &&
      uiVisibility.user_profile.settings &&
      uiVisibility.user_profile.company
    );

  return hasUserProfileSet(getVisibilityValue(response))
    ? getVisibilityValue(response)
    : defaultUiVisibility;
};
