import { combineReducers } from 'redux';
import { handleAction, handleActions } from 'redux-actions';
import safeMatch from '../utils/safe-match';
import { extractHash, extractPathAndQuery } from '../utils/url';
import { getValue } from '../utils/object';
import {
  defaultUiVisibility,
  getUiVisibility,
} from '../utils/default-ui-visibility';
import * as actions from '../actions';
import { SEVERITY } from '../constants';
import { localStorage } from '../env/browser';

// helpers

const safeMatchPos1 = safeMatch(1);
const getAppName = safeMatchPos1(/^\/([a-z0-9-]+)/);
const getOverlayName = safeMatchPos1(/^#\/([a-z0-9-]+)/);
const removeBy = (predicate, array) => {
  const index = array.findIndex(predicate);
  return index !== -1
    ? [...array.slice(0, index), ...array.slice(index + 1)]
    : array;
};

const doesUrlMatchSomeApp = (url, appList) =>
  appList?.some((appName) => url.startsWith(`#/${appName}`));

const isSidebarApp = (url) =>
  doesUrlMatchSomeApp(url, [
    'appointment',
    'wrapper/service',
    'wait-list',
    'appointment-v2',
  ]);

/*
  Use this variable to hide the sidebar for specific app path 
  in hash url Example: ['appointment-v2']
*/
const isSidebarHidden = (url) => doesUrlMatchSomeApp(url, []);

// reducers

const errorReducer = (state, { payload }) => ({ ...state, error: payload });
const dismissErrorReducer = (state) => ({ ...state, error: null });
const isLoadingReducer = (state, { payload: isLoading }) => ({
  ...state,
  isLoading,
});

export const currentApp = handleActions(
  {
    [actions.changeLocation]: (state, { payload: { url } }) => ({
      ...state,
      name: getAppName(extractPathAndQuery(url)) || null,
      pathAndQuery: extractPathAndQuery(url),
    }),
    [actions.setLoadingApp]: isLoadingReducer,
    [actions.appError]: errorReducer,
    [actions.dismissAppAndOverlayError]: dismissErrorReducer,
  },
  {
    name: null,
    pathAndQuery: '',
    isLoading: false,
    error: null,
  },
);

export const currentSidebar = handleActions(
  {
    [actions.changeLocation]: (state, { payload: { url } }) => {
      const hashUrl = extractHash(url);
      const name = getOverlayName(hashUrl) || null;
      if (isSidebarApp(hashUrl)) {
        return {
          ...state,
          name,
          pathAndQuery: extractHash(url).replace(/^#/, ''),
          isHidden: isSidebarHidden(hashUrl),
        };
      }
      return {
        ...state,
        name: null,
        pathAndQuery: null,
        isHidden: false,
      };
    },
    [actions.openSidebar]: (state, { payload: { url } }) => ({
      ...state,
      name: getAppName(extractPathAndQuery(url)) || null,
      pathAndQuery: extractPathAndQuery(url),
      isHidden: false,
    }),
    [actions.closeSidebar]: (state) => ({
      ...state,
      name: null,
    }),
    [actions.setLoadingSidebar]: isLoadingReducer,
  },
  {
    name: null,
    pathAndQuery: '',
    isLoading: false,
    error: null,
    isHidden: false,
  },
);

export const currentOverlay = handleActions(
  {
    [actions.changeLocation]: (state, { payload: { url } }) => {
      const hashUrl = extractHash(url);
      const name = getOverlayName(hashUrl) || null;
      if (isSidebarApp(hashUrl)) {
        return {
          ...state,
          name: null,
          pathAndQuery: '',
          isTemporarilyHidden: false,
        };
      }
      const pathAndQuery = extractHash(url).replace(/^#/, '');
      const isTemporarilyHidden = name === 'search';

      return { ...state, name, pathAndQuery, isTemporarilyHidden };
    },
    [actions.setLoadingOverlay]: isLoadingReducer,
    [actions.overlayError]: errorReducer,
    [actions.dismissAppAndOverlayError]: dismissErrorReducer,
    'APP(search)/HIDE_CONTAINER': (state) => ({
      ...state,
      isTemporarilyHidden: true,
    }),
    'APP(search)/SHOW_CONTAINER': (state) => ({
      ...state,
      isTemporarilyHidden: false,
    }),
  },
  {
    name: null,
    pathAndQuery: '',
    isLoading: false,
    isTemporarilyHidden: false,
    error: null,
  },
);

export const currentModal = handleActions(
  {
    [actions.openModal]: () => true,
    [actions.closeModal]: () => false,
  },
  false,
);

export const initialModalAppState = {
  name: null,
  instance: null,
  loading: false,
  visible: false,
};
const eventuallyDestroyModalApp = ({ instance }) => {
  if (instance && instance.destroy) {
    instance.destroy();
  }
  return initialModalAppState;
};

export const currentModalApp = handleActions(
  {
    [actions.openAppInModal]: (state, { payload }) => {
      if (!payload.name) {
        return initialModalAppState;
      }
      eventuallyDestroyModalApp(state);
      return {
        ...payload,
        loading: true,
        instance: null,
        visible: false,
      };
    },
    [actions.modalAppLoaded]: (state, { payload }) => ({
      ...state,
      instance: payload,
      loading: false,
      visible: !!payload,
    }),
    [actions.appError]: eventuallyDestroyModalApp,
    [actions.closeModal]: eventuallyDestroyModalApp,
  },
  initialModalAppState,
);

export const currentMerchant = handleActions(
  {
    [actions.initializeSuccess]: (
      state,
      { payload: { currentMerchantData, packageCode, subscription } },
    ) => ({
      ...state,
      id: currentMerchantData.id,
      name: currentMerchantData.name,
      createdAt: currentMerchantData.created_at,
      whiteLabel: getValue(
        currentMerchantData,
        'organization.whitelabel_slug',
        null,
      ),
      isSelfSignUp: getValue(
        currentMerchantData,
        'organization.self_sign_up',
        false,
      ),
      trialEndsOn:
        subscription?.trial_end ||
        getValue(currentMerchantData, 'organization.trial_ends_on', null),
      trialDaysLeft: subscription
        ? subscription.trial_days_left
        : getValue(currentMerchantData, 'organization.trial_days_left', null),
      isTest: getValue(currentMerchantData, 'organization.test', false),
      packageCode:
        packageCode ||
        getValue(currentMerchantData, 'organization.package_code', null),
      featureToggles: getValue(currentMerchantData, 'feature_toggles', {}),
      source: currentMerchantData.source,
      category: currentMerchantData.category,
      uiVisibility: getUiVisibility(currentMerchantData),
      pixelId: currentMerchantData.pixel_id,
      phoneNumber: currentMerchantData.phone || '',
    }),
    [actions.updateTrialPeriod]: (state, { payload: trialEndsOn }) => ({
      ...state,
      trialEndsOn,
    }),
  },
  {
    id: null,
    name: '',
    whiteLabel: null,
    createdAt: null,
    isSelfSignUp: false,
    trialEndsOn: null,
    trialDaysLeft: null,
    featureToggles: {},
    source: '',
    uiVisibility: defaultUiVisibility,
    category: '',
    isTest: false,
    packageCode: null,
    pixelId: null,
    phoneNumber: null,
  },
);

export const actionPanel = handleActions(
  {
    [actions.setOrganizationStatus]: (state, { payload }) => ({
      ...state,
      showPaymentWarning: payload.show_payment_warning,
      dunningWarning: payload.dunning_warning,
    }),
  },
  {
    showPaymentWarning: false,
    dunningWarning: null,
  },
);

export const currentAccount = handleActions(
  {
    [actions.receiveAvatarUrl]: (state, { payload: avatarUrl }) => ({
      ...state,
      avatarUrl,
    }),
    [actions.setContractDetails]: (state, { payload: contractDetails }) => ({
      ...state,
      contractDetails,
    }),
    [actions.initializeSuccess]: (
      state,
      {
        payload: {
          myAccountData: {
            locale,
            id,
            display_name: displayName,
            roles,
            email,
            employee: { color, given_name: firstName, surname: lastName },
            // eslint-disable-next-line camelcase
            organization_admin,
            merchants,
            organization: {
              id: organizationId,
              key_account_contract: isKeyAccount,
              test: isTestAccount,
              name: organisationName,
            },
            current_sign_in_at: currentSigninAt,
          },
        },
      },
    ) => ({
      ...state,
      id,
      locale,
      displayName,
      roles,
      email,
      firstName,
      lastName,
      color,
      // eslint-disable-next-line camelcase
      organization_admin,
      numberOfMerchants: merchants.length,
      isKeyAccount,
      isTestAccount,
      currentSigninAt,
      organisationName,
      organizationId,
    }),
  },
  {
    id: null,
    displayName: '',
    locale: 'en',
    email: '',
    roles: [],
    avatarUrl: null,
    firstName: '',
    lastName: '',
    color: '',
    organization_admin: false,
    numberOfMerchants: 0,
    isKeyAccount: false,
    isTestAccount: false,
    currentSigninAt: '',
    contractDetails: {
      defaultPaymentSourceType: undefined,
      packages: [],
      paymentSources: [],
    },
    organisationName: '',
    organizationId: '',
  },
);

export const sessionPermissions = handleActions(
  {
    [actions.initializeSuccess]: (
      state,
      {
        payload: {
          sessionPermissions: {
            meta: {
              hr,
              insights,
              reportings,
              location,
              marketplace,
              messenger,
              networks,
              newsletter,
              reminders,
              resources,
              services,
              settings,
              shiftplan,
              transactions,
              dashboard,
              calendar,
              customers,
              feedback,
              web_widgets: webWidgets,
              website_duda: websiteDuda,
            },
          },
        },
      },
    ) => ({
      ...state,
      calendar,
      customers,
      dashboard,
      feedback,
      hr,
      insights,
      location,
      marketplace,
      messenger,
      networks,
      newsletter,
      reminders,
      reportings,
      resources,
      services,
      settings,
      shiftplan,
      transactions,
      websiteDuda,
      webWidgets,
    }),
  },
  {
    calendar: null,
    customers: null,
    dashboard: null,
    feedback: null,
    hr: null,
    insights: null,
    location: null,
    marketplace: null,
    messenger: null,
    networks: null,
    newsletter: null,
    reminders: null,
    reportings: null,
    resources: null,
    services: null,
    settings: null,
    shiftplan: null,
    transactions: null,
    websiteDuda: null,
    webWidgets: null,
  },
);

export const initialization = handleActions(
  {
    [actions.initializeSuccess]: (state) => ({ ...state, success: true }),
    [actions.initializeError]: errorReducer,
  },
  {
    success: false,
    error: null,
  },
);

export const whiteLabel = handleActions(
  {
    [actions.initializeSuccess]: (
      state,
      { payload: { whiteLabelConfig } },
    ) => ({
      ...state,
      config: whiteLabelConfig,
    }),
  },
  {
    config: {},
  },
);

export const routing = combineReducers({
  location: combineReducers({
    url: handleAction(
      actions.changeLocation,
      (state, { payload: { url } }) => url,
      '',
    ),
  }),
});

export const documentHeaders = handleAction(
  actions.receiveDocumentHeaders,
  (state, { payload }) => ({
    ...state,
    hasLastModifiedChanged:
      state.hasLastModifiedChanged ||
      (!!payload.lastModified &&
        !!state.lastModified &&
        payload.lastModified !== state.lastModified),
    ...payload,
  }),
  {
    hasLastModifiedChanged: false,
    lastModified: null,
    date: null,
  },
);

export const hubspot = handleAction(
  actions.setHubspotReady,
  (state) => ({
    ...state,
    isHubspotInitialized: true,
  }),
  {
    isHubspotInitialized: false,
  },
);

export const intercom = handleAction(
  actions.setIntercomReady,
  (state) => ({
    ...state,
    isIntercomInitialized: true,
  }),
  {
    isIntercomInitialized: false,
  },
);

export const notifications = handleActions(
  {
    [actions.initNotificationCounts]: (state, { payload }) => {
      const { appointments: appointmentCount, messages: messageCount } =
        payload;
      return { ...state, appointmentCount, messageCount };
    },
    [actions.newNotificationCounts]: (state, { payload }) => {
      const { appointments: appointmentCount, messages: messageCount } =
        payload;

      return {
        ...state,
        appointmentCount,
        messageCount,
      };
    },
    [actions.initNotificationClientMessages]: (state, { payload }) => ({
      ...state,
      clientMessages: payload.map((id) => ({ id, closed: false })),
    }),
    [actions.notificationsClientMessageCloseClick]: (state, { payload }) => ({
      ...state,
      clientMessages: state.clientMessages.map((m) => ({
        ...m,
        closed: m.id === payload ? true : m.closed,
      })),
    }),
    [actions.notificationsAppointmentClick]: (state) => ({
      ...state,
      ui: { ...state.ui, open: !state.ui.open },
    }),
    [actions.notificationsMessageClick]: (state) => ({
      ...state,
      messageCount: 0,
    }),
    [actions.notificationsAppointmentItemClick]: (
      state,
      { payload: { id: notificationId } },
    ) => ({
      ...state,
      appointmentCount: Math.max(state.appointmentCount - 1, 0),
      appointments: removeBy(
        (item) => item.id === notificationId,
        state.appointments,
      ),
      ui: { ...state.ui, open: false },
    }),
    [actions.notificationsAppointmentCloseClick]: (state, { payload }) => {
      const count = Math.max(state.appointmentCount - 1, 0);
      return {
        ...state,
        appointmentCount: count,
        appointments: removeBy(
          (item) => item.id === payload,
          state.appointments,
        ),
        ui: { ...state.ui, open: count > 0 ? state.ui.open : false },
      };
    },
    [actions.notificationsAppointmentFetchSuccess]: (state, { payload }) => ({
      ...state,
      // set to zero if no appointments could be fetched to prevent sync errors
      appointmentCount: payload.length === 0 ? 0 : state.appointmentCount,
      appointments: payload,
      ui: { ...state.ui, error: false, loaded: true },
    }),
    [actions.notificationsAppointmentFetchError]: (state) => ({
      ...state,
      ui: { ...state.ui, error: true, loaded: true },
    }),
    [actions.documentClick]: (state) =>
      state.ui.open ? { ...state, ui: { ...state.ui, open: false } } : state,
    [actions.notificationSMSDisplaySeverity]: (state, { payload }) => ({
      ...state,
      severity: payload,
    }),
  },
  {
    appointmentCount: 0,
    messageCount: 0,
    appointments: [],
    clientMessages: [],
    ui: { open: false, error: false, loaded: false },
    severity: localStorage.getItem(SEVERITY.LABEL)
      ? localStorage.getItem(SEVERITY.LABEL)
      : SEVERITY.NONE,
  },
);

// root reducer

export default combineReducers({
  currentAccount,
  currentApp,
  currentMerchant,
  currentModal,
  currentModalApp,
  currentOverlay,
  currentSidebar,
  documentHeaders,
  initialization,
  notifications,
  routing,
  whiteLabel,
  actionPanel,
  hubspot,
  intercom,
  sessionPermissions,
});
