import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

export function checkRouteAccessByPermissions({
  path,
  permissions = {},
  featureToggles = {},
}) {
  /* 
    since we have consistency issue between BE/FE, meaning we label 
    it differently. This denyRouteList helps with that
  */
  const denyRouteList = new Set([
    'reportings',
    'marketplace',
    'webWidgets',
    'websiteDuda',
  ]);

  switch (path) {
    /* 
      since these are the routes accessible to all roles
      and not available in endpoint, we decided to hardcoded in FE
    */
    case 'upsell':
    case 'contract':
    case 'user-profile':
    case 'appointment':
    case 'developer':
    case 'search':
    case 'wrapper':
    case 'locations':
      return true;
    case 'website-builder':
      return Boolean(permissions.websiteDuda?.available);
    case 'settings':
    case 'application-settings':
      return Boolean(permissions.settings?.available);
    case 'cancelled-appointments-report':
      return Boolean(permissions.insights?.available);
    case 'pos-reports-v2':
      return Boolean(permissions.reportings?.available);
    case 'fbe2-connect':
      return Boolean(featureToggles?.facebook_instagram_integration);

    // TODO: Remove this test URL when app is ready
    case 'appointment-v2':
    case 'customers-v2':
    case 'old-customers':
      return true;

    default:
      if (denyRouteList.has(path)) {
        return false;
      }
      return Boolean(permissions[path]?.available);
  }
}

// eslint-disable-next-line import/prefer-default-export
export function useSessionPermissions() {
  const location = useLocation();
  const { currentAccount, currentMerchant, sessionPermissions } = useSelector(
    (state) => state,
  );
  const merchantId = currentMerchant?.id;
  const { featureToggles } = currentMerchant;
  const currentRole = currentAccount?.roles?.find(
    (i) => i.merchant.id === merchantId,
  );

  if (!currentRole) {
    return { role: null, hasPermission: false };
  }

  const isMainRoute = location.pathname === '/';
  const hashRoute = location.hash.replace(/^#\//, '').split('/')[0];
  const browserRoute = location.pathname.replace(/^\//, '').split('/')[0];
  const requestedRoute = hashRoute || browserRoute;

  const hasPermission =
    isMainRoute ||
    checkRouteAccessByPermissions({
      path: requestedRoute,
      permissions: sessionPermissions,
      featureToggles,
    });

  return { role: currentRole, hasPermission };
}
