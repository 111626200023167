import { useDispatch } from 'react-redux';

import { proxyAppAction } from '../actions';
import { useGlobals } from '../globals-context';

const defaultCache = new Map();

function initialize(serviceName, tools, dispatch, cache) {
  if (cache.get(serviceName)) {
    return cache.get(serviceName);
  }

  const { apiClient, trackMethod } = tools;
  const proxyAction = proxyAppAction();

  const onAction = (action) => dispatch(proxyAction(serviceName, action));

  const logError = (error, extraTagsFromMicroApp = {}) => {
    tools.errorLogger.log(
      error instanceof Error ? error : new Error(error),
      {
        tags: {
          app: serviceName,
        },
      },
      extraTagsFromMicroApp,
    );
  };

  const eventBus = {
    on: tools.eventBus.on,
    off: tools.eventBus.off,
    emit: (eventName, payload = {}) => {
      tools.eventBus.emit(eventName, {
        payload,
        metadata: { appName: serviceName, event: eventName },
      });
    },
  };

  const cachedTools = {
    apiClient,
    logError,
    trackMethod,
    eventBus,
    onAction,
  };

  cache.set(serviceName, cachedTools);

  return cachedTools;
}

function useMicroServicesTools(serviceName, cache = defaultCache) {
  const tools = useGlobals();
  const dispatch = useDispatch();

  return initialize(serviceName, tools, dispatch, cache);
}

export default useMicroServicesTools;
