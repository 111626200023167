export const antdThemeToken = {
  colorPrimary: '#00d0be',
  colorWarning: '#fbe4b2',
  colorError: '#ff2e52',
  colorText: '#222222',
  colorPrimaryText: '#00d0be',
  fontFamily: '"Nunito Sans","Open Sans",Arial,sans-serif',
  fontSize: 14,
  lineHeight: 1.42,
  borderRadius: 4,
};

export const antdTheme = {
  token: antdThemeToken,
};
