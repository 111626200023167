import { datadogLogs } from '@datadog/browser-logs';

import { API_ENV, DATADOG_SITE } from '../constants';

const CLIENT_TOKEN = 'pubc889ffff426ed9c686812fa21927998d';

const Logger = {
  init({ merchantId, employeeId }) {
    const env = API_ENV === 'staging' ? 'staging' : 'prod';

    datadogLogs.init({
      env,
      clientToken: CLIENT_TOKEN,
      service: 'web-calendar-app',
      site: DATADOG_SITE,
      forwardErrorsToLogs: false,
      forwardConsoleLogs: [],
      sampleRate: 100,
      beforeSend: (log) => {
        /* eslint-disable no-param-reassign */
        log.user = {
          id: employeeId,
          merchantId,
        };
        /* eslint-enable no-param-reassign */
      },
    });

    datadogLogs.createLogger('default', {
      level: 'error',
      handler: ['console', 'http'],
    });
  },

  error(message, payload = {}) {
    const logger = datadogLogs.getLogger('default');

    logger?.error(message, payload);
  },
};

export default Logger;
