import { Component, h } from 'preact';

import { createScriptNode } from '../../../utils/dom';
import { document, window } from '../../../env/browser';
import { setQueryStringParameter } from '../../../utils/url';

const HubSpotElementId = 'hs-script-loader';
const HubSpot = {
  execute: (action) => {
    // If external API methods are already available, use them.
    if (window.HubSpotConversations) {
      action();
      return;
    }
    /*
      Otherwise, callbacks can be added to the hsConversationsOnReady on the window object.
      These callbacks will be called once the external API has been initialized.
    */
    window.hsConversationsOnReady = window.hsConversationsOnReady || [];
    window.hsConversationsOnReady.push(action);
  },

  removeWidget: () => {
    if (
      window &&
      window.HubSpotConversations &&
      window.HubSpotConversations.widget
    ) {
      window.HubSpotConversations.widget.remove();
    }
  },

  loadWidget: () => {
    const status = window.HubSpotConversations.widget.status() || null;

    if (status && status.loaded) {
      window.HubSpotConversations.widget.refresh({ openToNewThread: true });
    } else {
      window.HubSpotConversations.widget.load({ widgetOpen: false });
    }
  },

  setUserIdentity: ({ email, organizationId }) => {
    // eslint-disable-next-line no-underscore-dangle, no-multi-assign
    const _hsq = (window._hsq = window._hsq || []);
    // Identify current user on chat
    _hsq.push([
      'identify',
      {
        email,
        organization_id: organizationId,
      },
    ]);
  },

  setChatflow: ({ chatflow, callAfterUrlChanged }) => {
    // Force the widget to open to a specific chat flow with targeted query string
    setQueryStringParameter({
      name: 'chatflow',
      value: chatflow,
      append: true,
      cb: callAfterUrlChanged,
    });
  },
};

export default class HubSpotWidget extends Component {
  constructor(props) {
    super(props);
    this.onConversationsAPIReady = this.onConversationsAPIReady.bind(this);
  }

  componentDidMount() {
    const { hubId } = this.props;

    window.hsConversationsSettings = { loadImmediately: true };
    const script = document.getElementById(HubSpotElementId);

    if (!script) {
      const scriptNode = createScriptNode({
        src: `//js.hs-scripts.com/${hubId}.js`,
        id: HubSpotElementId,
        async: true,
        defer: true,
      });
      document.body.appendChild(scriptNode);
    }

    // Load widget when ready
    HubSpot.execute(this.onConversationsAPIReady);
  }

  componentWillUnmount() {
    HubSpot.removeWidget();
  }

  async onConversationsAPIReady() {
    const { organizationId, email, chatflow, onHubspotReady } = this.props;

    HubSpot.setUserIdentity({ email, organizationId });
    HubSpot.setChatflow({ chatflow, callAfterUrlChanged: onHubspotReady });
    HubSpot.loadWidget();
  }

  render() {
    const { show } = this.props;

    // hide hubspot on pages where it covers important UI
    /* eslint-disable react/no-danger */
    return (
      <style
        dangerouslySetInnerHTML={{
          __html: `#hubspot-messages-iframe-container {
              transition: opacity 0.3s;
              ${show ? '' : 'opacity: 0; pointer-events: none;'}
            }`,
        }}
      />
    );
    /* eslint-enable react/no-danger */
  }
}

export { HubSpotWidget };
