import { h } from 'preact';
import { useEffect } from 'preact/hooks';
import { useLocation, Outlet } from 'react-router-dom';
import { Spin } from 'antd';

import EVENTS from '../../constants/event-bus-events';
import MicroApp from '../../components/MicroApp';
import useServicesSettings from './use-services-settings';
import { useGlobals } from '../../globals-context';

const containerStyle = {
  display: 'flex',
  height: '100%',
  flex: 1,
};

const loadingContainerStyle = {
  ...containerStyle,
  justifyContent: 'center',
  alignItems: 'center',
};

const servicesListStyle = {
  flex: 1,
};

function Loading() {
  return (
    <div style={loadingContainerStyle}>
      <Spin />
    </div>
  );
}

export function ServicesList() {
  const { eventBus } = useGlobals();
  const { isLoading: isLoadingSettings, settings } = useServicesSettings();
  const { pathname } = useLocation();

  useEffect(() => {
    eventBus.emit(EVENTS.DISABLE_SPINNER);
    eventBus.emit(EVENTS.CLOSE_MENU);

    return () => {
      eventBus.emit(EVENTS.ENABLE_SPINNER);
    };
  }, []);

  if (isLoadingSettings) {
    return <Loading />;
  }

  return (
    <div style={containerStyle}>
      <MicroApp
        name="services/ServicesList"
        settings={settings}
        pathname={pathname}
        style={servicesListStyle}
        loading={<Loading />}
      />
      <Outlet />
    </div>
  );
}
