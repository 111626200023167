import _i18next from 'i18next';
import createDebug from '../utils/debug';
import { window } from '../env/browser';
import { FALLBACK_LANGUAGES, CDN_BASE_URL } from '../constants';
import { loadTranslations, registerTranslations } from '../utils/translations';

const debug = createDebug('initializers:i18next');

export default function initI18next(lng, i18next = _i18next) {
  return new Promise((resolve, reject) => {
    i18next.init(
      {
        fallbackLng: FALLBACK_LANGUAGES,

        // NOTE: Currently we don't have proper translations for en-US, so we
        //       switch to en here. This should be fixed asap.
        // TODO: uncomment after fix
        // lng,
        // TODO: remove after fix
        lng: lng === 'en-US' ? 'en' : lng,

        interpolation: {
          prefix: '__',
          suffix: '__',
          // FIXME: possibly unsafe, but was default in v1.
          // see http://i18next.com/docs/options/#interpolation-options
          escapeValue: false,
        },
      },
      (error) => {
        if (error) {
          debug(error);
          reject(new Error('Cannot initialize i18next'));
        } else {
          /*
           * Provide patches for supporting legacy Apps based on i18next v1
           *
           * `loadNamespace` is not provided by newer version of i18next, so we
           * provide a stub that immediately calls the given callback for any
           * given namespace.
           *
           * This is desired behaviour, because ../app-manifest/app-loader.js
           * takes care of loading and initialising the namespace data.
           */
          const i18nextLegacyApi = {
            // eslint-disable-next-line no-param-reassign
            loadNamespace: (namespace, callback) => {
              callback();
            },
            lng: () => i18next.language,
          };

          // v1 was exposed on window.i18n
          window.i18n = Object.assign(Object.create(i18next), i18nextLegacyApi);

          resolve(i18next);
        }
      },
    );
  });
}

export async function setupTranslations(language) {
  const urlTemplate = `${CDN_BASE_URL}/locales/translation.__LANG__.json`;
  const data = await loadTranslations(urlTemplate, language);
  registerTranslations('app-shell', data);
}
