import { h } from 'preact';
import { useQuery } from 'react-query';
import { Navigate } from 'react-router-dom';
import { useState, useEffect, useRef } from 'preact/hooks';
import { Spin } from 'antd';
import debounce from 'lodash.debounce';

import { useGlobals } from '../../globals-context';
import { t } from '../../utils/i18n';
import createQueryConfig from '../../queries';
import useMerchantSettings from '../../hooks/use-merchant-settings';

const containerStyle = {
  display: 'flex',
  height: '100%',
  flex: '1 1 0%',
};

const containerContentStyle = {
  flex: '1 1 0%',
};

const headerContainerStyle = {
  alignItems: 'center',
  display: 'flex',
  margin: 'auto',
  marginBottom: '1.6rem',
  minHeight: '94px',
  padding: '1.6rem 2.4rem 0',
  width: '100%',
};

const overlayStyle = {
  width: '100%',
  display: 'flex',
  minHeight: '100vh',
};

const headerTitleStyle = {
  fontSize: '25px',
  fontWeight: 700,
  margin: 0,
  padding: 0,
  flex: 1,
};

function Header() {
  return (
    <header style={headerContainerStyle} slot="header">
      <h1 style={headerTitleStyle}>{t('company-settings.title')}</h1>
    </header>
  );
}

function Navigation() {
  return (
    <div slot="navigation">
      <shore-navigation>
        <shore-navigation-item
          data-url
          title={t('company-settings.nav.basic-information')}
          url="/settings/basic-information"
        />
        <shore-navigation-item
          data-url
          title={t('company-settings.nav.booking-hours')}
          url="/settings/booking-hours"
        />
        <shore-navigation-item
          data-url
          title={t('company-settings.nav.closed-dates-and-absences')}
          url="/settings/closing-and-absences"
        />
        <shore-navigation-item
          data-url
          title={t('company-settings.nav.company-profile')}
          url="/settings/company-profile"
        />
        <shore-navigation-item data-url title="Subscription" isActive />
        <shore-navigation-item
          data-url
          title={t('company-settings.nav.privacy-security')}
          url="/settings/privacy"
        />
      </shore-navigation>
    </div>
  );
}

const settingsContainerStyle = {
  height: '100%',
  position: 'relative',
  overflowY: 'auto',
};

const headerStyle = {
  fontWeight: 600,
  fontSize: '25px',
  lineHeight: '40px',
};

const settingInfoContainerStyle = {
  maxWidth: '650px',
};

const settingInfoStyle = {
  fontWeight: 400,
  fontSize: '12px',
  lineHeight: '18px',
  color: '#909497',
};

const settingsDefinitionContainerStyle = {
  display: 'flex',
  flexDirection: 'column',
};

const shoreSwitchStyle = {
  fontSize: '14px',
  fontWeight: 400,
  lineHeight: '22px',
};

const smsFormContainerStyle = {
  display: 'flex',
  flexDirection: 'column',
  marginTop: '10px',
};

const smsLimitInputWrapperStyle = {
  margin: 0,
};

const smsLimitInputStyle = {
  maxWidth: '200px',
};

function SMSLimitForm({ merchantId, value, updateSmsLimit }) {
  const [smsLimit, onChange] = useState(value || 0);

  function updateLimit(event) {
    const merchant = { id: merchantId };
    const newSmsLimit = Number(event.target.value);
    onChange(newSmsLimit);

    updateSmsLimit({
      merchant,
      limit_type: 'BY_LIMIT',
      additional_value: newSmsLimit,
    });
  }

  return (
    <div style={smsFormContainerStyle}>
      <label htmlFor="smsLimit">
        {t('company-settings.subscription.sms-settings.sms-limit-input')}
        <shore-input-wrapper style={smsLimitInputWrapperStyle}>
          <input
            id="smsLimit"
            name="smsLimit"
            style={smsLimitInputStyle}
            type="number"
            value={smsLimit}
            onChange={updateLimit}
          />
        </shore-input-wrapper>
      </label>
    </div>
  );
}

const manageSubscriptionStyle = {
  flex: 1,
  marginTop: '1em',
};

function SMS({ merchantId }) {
  const { queryClient, apiClient } = useGlobals();
  const queryConfig = createQueryConfig(queryClient, apiClient);
  const {
    data: smsUsage,
    status,
    refetch: refetchUsage,
  } = useQuery(queryConfig.smsCurrentMonthUsage(merchantId));
  const hasError = status === 'error';
  const isLoading = status === 'loading';

  if (isLoading || hasError) {
    return null;
  }

  const isSmsFeatureActive =
    smsUsage.limit_type === 'UNLIMITED' || smsUsage.additional_value > 0;
  const [smsEnabled, toggleSms] = useState(isSmsFeatureActive);
  const [isSmsLimitFormVisible, setSmsLimitFormVisibility] = useState(
    isSmsFeatureActive && smsUsage.limit_type !== 'UNLIMITED',
  );
  const merchant = { id: merchantId };

  async function updateSmsLimit(payload) {
    await apiClient.smsUsage.updateLimit(payload).then(refetchUsage);
  }

  const debouncedUpdateSmsLimit = debounce(updateSmsLimit, 1000);

  function toggleSmsFormVisibility() {
    if (smsEnabled) {
      setSmsLimitFormVisibility(!isSmsLimitFormVisible);
    }
  }

  async function toggleSmsFeature() {
    const payload = smsEnabled
      ? { limit_type: 'BY_LIMIT', merchant, additional_value: 0 }
      : { limit_type: 'UNLIMITED', merchant };

    await updateSmsLimit(payload).then(() => {
      toggleSms(!smsEnabled);
    });
  }

  useEffect(() => {
    if (!smsEnabled) {
      setSmsLimitFormVisibility(false);
    }
  }, [smsEnabled]);

  useEffect(() => {
    if (
      smsEnabled &&
      !isSmsLimitFormVisible &&
      smsUsage.limit_type === 'BY_LIMIT'
    ) {
      updateSmsLimit({ limit_type: 'UNLIMITED', merchant });
    }
  }, [smsEnabled, isSmsLimitFormVisible, smsUsage]);

  return (
    <div>
      <h2 style={headerStyle}>
        {t('company-settings.subscription.sms-settings.title')}
      </h2>
      <div style={settingInfoContainerStyle}>
        <p style={settingInfoStyle}>
          {t('company-settings.subscription.sms-settings.description')}
        </p>
      </div>
      <div style={settingsDefinitionContainerStyle}>
        <shore-switch
          onClick={toggleSmsFeature}
          style={shoreSwitchStyle}
          label={t(
            'company-settings.subscription.sms-settings.activate-unlimited-sms',
          )}
          checked={smsEnabled}
        />
        <shore-switch
          style={shoreSwitchStyle}
          onClick={toggleSmsFormVisibility}
          label={t(
            'company-settings.subscription.sms-settings.activate-sms-limit',
          )}
          checked={isSmsLimitFormVisible}
        />
      </div>
      {isSmsLimitFormVisible ? (
        <SMSLimitForm
          merchantId={merchantId}
          value={smsUsage.additional_value}
          updateSmsLimit={debouncedUpdateSmsLimit}
        />
      ) : null}
    </div>
  );
}

const modalBackgroundStyles = {
  position: 'fixed',
  top: '0',
  left: '0',
  width: '100%',
  height: '100%',
  backgroundColor: 'rgba(0, 0, 0, 0.5)', // semi-transparent black
  zIndex: '1000', // ensure it appears above other elements
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  overflow: 'hidden',
  outline: 'none',
};

const subscriptionPortalSessionStyle = {
  height: '35em',
  backgroundColor: 'white',
};

const portalSessionSpinStyle = (iframeLoaded) => ({
  position: 'absolute',
  display: iframeLoaded ? 'none' : 'block',
});

function SubscriptionPortalSession({ closePortal }) {
  const { isLoading: isLoadingMerchantSettings, merchantSettings } =
    useMerchantSettings();
  const { queryClient, apiClient } = useGlobals();
  const queryConfig = createQueryConfig(queryClient, apiClient);
  const { status: portalSessionsStatus, data } = merchantSettings
    ? useQuery(queryConfig.subscriptionPortalSession(merchantSettings))
    : { status: 'loading' };
  const [iframeLoaded, setIframeLoaded] = useState(false);
  const modalRef = useRef(null);

  if (isLoadingMerchantSettings || portalSessionsStatus === 'loading') {
    return null;
  }

  if (portalSessionsStatus === 'error') {
    return (
      <p> Failed to get portal session information. Please try again later. </p>
    );
  }

  useEffect(() => {
    if (modalRef.current) {
      modalRef.current.focus();
    }
  }, []);

  function handleKeyDown(event) {
    if (event.key === 'Escape') {
      closePortal();
    }
  }

  function handleIframeLoad() {
    setIframeLoaded(true);
  }

  return (
    <div
      ref={modalRef}
      style={modalBackgroundStyles}
      onClick={closePortal}
      onKeyDown={handleKeyDown}
      tabIndex={0}
      role="button"
    >
      <Spin style={portalSessionSpinStyle(iframeLoaded)} />
      <iframe
        style={subscriptionPortalSessionStyle}
        title="Shore's subscription management portal session"
        src={data.access_url}
        onLoad={handleIframeLoad}
      />
    </div>
  );
}

function SubscriptionManagement() {
  const [isPortalSessionVisible, setPortalSessionVisibility] = useState(false);

  function closePortal() {
    setPortalSessionVisibility(false);
  }

  function openPortal() {
    setPortalSessionVisibility(true);
  }

  return (
    <div style={manageSubscriptionStyle}>
      <div>
        <h2 style={headerStyle}>
          {t('company-settings.subscription.manage-subscription.title')}
        </h2>
        <div style={settingInfoContainerStyle}>
          <p style={settingInfoStyle}>
            {t('company-settings.subscription.manage-subscription.description')}
          </p>
        </div>
        <shore-button variant="primary" onClick={openPortal}>
          {t('button.manage-subscription.title')}
        </shore-button>
      </div>
      {isPortalSessionVisible ? (
        <SubscriptionPortalSession closePortal={closePortal} />
      ) : null}
    </div>
  );
}

function Settings({ merchantId }) {
  return (
    <div slot="content" style={settingsContainerStyle}>
      <SubscriptionManagement />
      <SMS merchantId={merchantId} />
    </div>
  );
}

export function SubscriptionSettings() {
  const { isLoading: isLoadingMerchantSettings, merchantSettings } =
    useMerchantSettings();
  const { queryClient, apiClient } = useGlobals();
  const queryConfig = createQueryConfig(queryClient, apiClient);
  const { status: subscriptionStatus } = merchantSettings
    ? useQuery(queryConfig.subscription(merchantSettings))
    : { status: 'loading' };
  const hasChargebeeSubscription = subscriptionStatus === 'success';

  if (isLoadingMerchantSettings || subscriptionStatus === 'loading') {
    return null;
  }

  if (!hasChargebeeSubscription) {
    return <Navigate to="/settings/contract" />;
  }

  return (
    <div style={containerStyle}>
      <div style={containerContentStyle}>
        <div>
          <shore-overlay-layout style={overlayStyle} withoutPadding showContent>
            <Header />
            <Navigation />
            <Settings merchantId={merchantSettings.merchantId} />
          </shore-overlay-layout>
        </div>
      </div>
    </div>
  );
}
