import { connect } from 'react-redux';
import { getSupportContactUrl } from '../../utils/url';
import { t } from '../../utils/i18n';
import Error from '../../components/Error';
import * as selectors from '../../selectors';
import { dismissAppAndOverlayError } from '../../actions';
import { COMPANY_NAME, IS_DOWNTIME } from '../../constants';

const statusPageUrl = 'https://shore.instatus.com/';

const getMessage = (companyName, isDowntime) => {
  if (isDowntime) {
    return t('downtime.description');
  }

  return companyName === 'shore'
    ? t('error.description-shore')
    : t('error.description-general');
};

const getActionButton = ({ companyName }) => {
  const SHORE = 'shore';

  if (IS_DOWNTIME && companyName !== SHORE) {
    return { href: null, title: null, isVisible: false };
  }
  return {
    href: statusPageUrl,
    title: t('error.button.status'),
    isVisible: true,
  };
};

const mapStateToProps = (state) => {
  const isInitializationError = selectors.hasInitializationError(state);
  const isUnauthorized = selectors.hasUnauthorizedError(state);
  const isAppError = selectors.hasAppError(state);
  const isOverlayError = selectors.hasOverlayError(state);
  const { locale } = selectors.getAppSettings(state);

  const isVisible =
    (!isUnauthorized && isInitializationError) || isAppError || isOverlayError;

  return {
    isVisible,
    isDowntime: IS_DOWNTIME,
    title: IS_DOWNTIME ? t('downtime.title') : t('error.title'),
    message: getMessage(COMPANY_NAME, IS_DOWNTIME),
    actionButton: getActionButton({
      supportContactUrl: getSupportContactUrl(),
      companyName: COMPANY_NAME,
      locale,
    }),
    isDismissable: isAppError || isOverlayError,
  };
};

const mapDispatchToProps = {
  onDismiss: dismissAppAndOverlayError,
};

export default connect(mapStateToProps, mapDispatchToProps)(Error);
