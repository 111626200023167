import { document } from '../env/browser';

export const createScriptNode = (props = {}) => {
  const scriptNode = document.createElement('script');
  scriptNode.type = 'text/javascript';
  Object.keys(props).forEach((key) => {
    scriptNode[key] = props[key];
  });
  return scriptNode;
};

export const createAsyncScriptNode = (props = {}) =>
  createScriptNode({ async: true, ...props });
