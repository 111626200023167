import { h } from 'preact';
import { useParams } from 'react-router-dom';

import MicroApp from '../../components/MicroApp';
import useServicesSettings from './use-services-settings';

export function EditService() {
  const { isLoading, settings } = useServicesSettings();
  const { serviceId } = useParams();

  if (isLoading) {
    return null;
  }

  return (
    <MicroApp
      name="services/EditService"
      settings={settings}
      serviceId={serviceId}
    />
  );
}
