// NOTE: does not support
// - storage['key'] = value
// - storage event
const createMemoryStorage = () => {
  let data = {};

  return {
    getItem(key) {
      return data[key] || null;
    },
    setItem(key, value) {
      data[key] = value.toString();
    },
    removeItem(key) {
      delete data[key];
    },
    key(num) {
      return Object.keys(data)[num];
    },
    clear() {
      data = {};
    },
    get length() {
      return Object.keys(data).length;
    },
  };
};

export default createMemoryStorage;
