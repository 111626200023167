import createMemoryStorage from '../utils/memory-storage';

const NOOP = Function.prototype;

export const window =
  (typeof window === 'object' && window) || // eslint-disable-line no-use-before-define
  (typeof global === 'object' && global.window) || // fallback for testing
  {};
export const document = window.document || {
  getElementById: () => null,
};
export const history = window.history || {};
export const location = window.location || {};
export const addEventListener = window.addEventListener || NOOP;
export const Event = window.Event || NOOP;
export const fetch = window.fetch || NOOP;
export const removeEventListener = window.removeEventListener || NOOP;
export const localStorage = window.localStorage || createMemoryStorage();
export const sessionStorage = window.sessionStorage || createMemoryStorage();
