import { h, Component } from 'preact';

import Tracer from '../../utils/tracer';
import getGlobalValue from '../../utils/get-global-value';
import EVENTS from '../../constants/event-bus-events';
import FeatureRollout from '../../utils/feature-rollout';
import { NOOP } from '../../constants';
import { isAllowedToLoadAppName } from '../../utils';

const moment = getGlobalValue('moment');

function isAppLoaderDeprecatedFor(appName) {
  // This function basically means that the given micro app is now rendered by MicroApp component, in accordance to the new app-shell architecture design. For more info, check this ADR: https://www.notion.so/shorewiki/App-Shell-architecture-redesign-a842d1054de44867a9fa9a1228dab64d

  return !FeatureRollout.isActive('services-app-refactor')
    ? ['subscription'].includes(appName)
    : ['services', 'subscription'].includes(appName);
}

const eventuallyDestroyInstance = async (instancePromise) => {
  if (!instancePromise) return;
  const instance = await instancePromise;
  if (!instance) return;
  Tracer.clearAppConfig();
  instance.destroy();
};

const isAllowedToLoad = (isFreeProduct, name) =>
  isFreeProduct ? isAllowedToLoadAppName(name) : true;

class AppLoader extends Component {
  componentDidMount() {
    const { settings, name } = this.props;
    if (isAllowedToLoad(settings.isFreeProduct, name))
      this.setAppPromise(this.loadAndCreateApp(this.props), this.props.name);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    // only load app if name, disabled or pathAndQuery has changed
    const { name, pathAndQuery, disabled } = this.props;
    const {
      name: nextName,
      disabled: nextDisabled,
      settings: nextSettings,
      pathAndQuery: nextPathAndQuery,
    } = nextProps;
    if (name !== nextName || disabled !== nextDisabled) {
      // if minimal product prevent some apps from loading
      if (isAllowedToLoad(nextSettings.isFreeProduct, nextName)) {
        eventuallyDestroyInstance(this.appPromise);
        this.setAppPromise(this.loadAndCreateApp(nextProps), nextProps.name);
      }
    } else if (name === nextName && pathAndQuery !== nextPathAndQuery) {
      // NOTE: we need to 'notify' the app if pathAndQuery has changed
      //       because the app's router (React Router) won't get notified on
      //       location changes from outside of the app.
      this.appPromise.then((instance) => {
        if (instance && 'dispatchAction' in instance) {
          instance.dispatchAction({
            type: 'ROUTE_CHANGE',
            payload: nextPathAndQuery,
          });
        }
      });
    }

    // set class directly in DOM if changed
    if (this.props.class !== nextProps.class) {
      this.base.className = nextProps.class;
    }
  }

  // we don't want rerender here and manually take care of updating the DOM,
  // see componentWillReceiveProps
  shouldComponentUpdate() {
    return false;
  }

  setAppPromise(promise, appName) {
    const loadingStart = Date.now();

    this.appPromise = promise;

    function trackLoadingTime() {
      Tracer.addAction(`${appName}.micro_app.loaded`, {
        microApp: { loadingTimeInMs: Date.now() - loadingStart },
      });
    }

    if (appName) {
      promise.then(this.props.onInstance).then(trackLoadingTime);
    } else {
      promise.then(this.props.onInstance);
    }
  }

  async loadAndCreateApp({
    name,
    eventBus,
    disabled = false,
    onLoadingApp = NOOP,
    onAppAction = NOOP,
    onError = NOOP,
    onDocumentOutdated = NOOP,
    settings = {},
    pathAndQuery = '',
    useHashHistory = false,
    routingType = 'path',
    modal = {},
    loadApp = NOOP,
    documentDate = null,
    trackMethod = NOOP,
    apiClient = {},
    errorLogger = {},
  }) {
    if (!name || disabled || isAppLoaderDeprecatedFor(name)) return null;

    const logError = (error, extraTagsFromMicroApp = {}) => {
      errorLogger.log(
        error instanceof Error ? error : new Error(error),
        {
          tags: {
            app: name,
          },
        },
        extraTagsFromMicroApp,
      );
    };

    onLoadingApp(true);
    try {
      this.props.eventBus.emit(EVENTS.CLOSE_MENU);

      const { create, manifest } = await loadApp({
        name,
        apiClient,
        trackMethod,
        settings,
        pathAndQuery,
        useHashHistory,
        routingType,
        modal,
        eventBus: {
          on: eventBus.on,
          off: eventBus.off,
          emit: (eventName, payload = {}) => {
            eventBus.emit(eventName, {
              payload,
              metadata: { appName: name, event: eventName },
            });
          },
        },
        onAction: (action) => {
          onAppAction(name, action);
        },
        container: this.base,
        logError,
      });

      Tracer.setAppName(name);

      const appYoungerThanDocument =
        !!documentDate && moment(manifest.lastModified) > moment(documentDate);

      // prevent app creation if app is younger than document
      if (appYoungerThanDocument) {
        onDocumentOutdated();
        return null;
      }

      return create();
    } catch (error) {
      onError(error);
      return null;
    } finally {
      onLoadingApp(false);
    }
  }

  render(props) {
    return <div class={props.class} />;
  }
}

export default AppLoader;
