/* eslint-disable no-console */
import { useSelector } from 'react-redux';
import { useState } from 'preact/hooks';
import { useEffect } from 'preact/compat';

import { getAppSettings } from '../src/selectors';

function useCreateAppConfig(appName, deps) {
  const initialization = useSelector((store) => store.initialization);
  const settings = useSelector(getAppSettings);
  const { apiClient, eventBus } = deps;
  const [isLoading, toggleLoading] = useState(true);

  const logError = (error, extraTagsFromMicroApp = {}) => {
    deps.errorLogger.log(
      error instanceof Error ? error : new Error(error),
      {
        tags: {
          app: appName,
        },
      },
      extraTagsFromMicroApp,
    );
  };

  const trackMethod = (eventName, data) => {
    if (data) {
      console.log('Event tracked:', eventName, data);
    } else {
      console.log('Event tracked:', eventName);
    }
  };

  useEffect(() => {
    if (initialization.success) {
      toggleLoading(false);
    }
  }, [initialization]);

  if (isLoading) {
    return { isLoading };
  }

  return {
    isLoading,
    config: {
      settings,
      apiClient,
      routingType: 'path',
      useHashHistory: false,
      onAction: () => {
        // TODO: Implement this
      },
      logError,
      eventBus: {
        on: eventBus.on,
        off: eventBus.off,
        emit: (eventName, payload = {}) => {
          eventBus.emit(eventName, {
            payload,
            metadata: { appName, event: eventName },
          });
        },
      },
      trackMethod,
    },
  };
}

export default useCreateAppConfig;
/* eslint-enable no-console */
