import { h } from 'preact';
import { Outlet } from 'react-router-dom';

import { ApplicationSettingsApp } from './application-settings-app';
import { CalendarApp } from './calendar-app';
import { ContractApp } from './contract-app';
import { CustomersApp } from './customers-app';
import { LaunchpadApp } from './launchpad-app';
import { LegacyApp } from './legacy-app';
import { UserProfileApp } from './user-profile-app';
import { ResourcesApp } from './resources-app';
import { FeedbackApp } from './feedback-app';
import { RemindersApp } from './reminders-app';
import { LocationsApp } from './locations-app';
import Playground from './playground';

import useServicesSettings from '../src/views/services/use-services-settings';

import { NO_PROPS } from './constants';

const SERVICES_MICRO_SERVICE_COMPONENTS_SCHEMA = [
  ['ServicesList', NO_PROPS],
  ['NewCategory', NO_PROPS],
  ['NewCourse', NO_PROPS],
  ['NewService', NO_PROPS],
  ['EditCategory', { categoryId: { type: String, required: true } }],
  ['EditCourse', { courseId: { type: String, required: true } }],
  ['EditService', { serviceId: { type: String, required: true } }],
];

const playgroundRoutes = [
  {
    path: '/playground/',
    element: <Outlet />,
    children: [
      {
        path: 'application-settings',
        element: <ApplicationSettingsApp />,
      },
      {
        path: 'calendar',
        element: <CalendarApp />,
      },
      {
        path: 'contract',
        element: <ContractApp />,
      },
      {
        path: 'customers',
        element: <CustomersApp />,
      },
      {
        path: 'locations',
        element: <LocationsApp />,
      },
      {
        path: 'launchpad',
        element: <LaunchpadApp />,
      },
      {
        path: 'resources',
        element: <ResourcesApp />,
      },
      {
        path: 'feedback',
        element: <FeedbackApp />,
      },
      {
        path: 'reminders',
        element: <RemindersApp />,
      },
      {
        path: 'user-profile',
        element: <UserProfileApp />,
      },
      {
        path: 'services/components',
        element: (
          <Playground
            serviceName="services"
            componentsApiSchema={SERVICES_MICRO_SERVICE_COMPONENTS_SCHEMA}
            useSetupSettingsHook={useServicesSettings}
          />
        ),
      },
      {
        path: ':appName',
        // TODO: We need to modify this component
        element: <LegacyApp />,
      },
    ],
  },
];

export default playgroundRoutes;
