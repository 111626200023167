import { h } from 'preact';

export default function ProfileImage({ src, placeholder }) {
  return (
    <div class="as-ProfileImage">
      {src ? (
        <img
          alt="Profile"
          class="as-ProfileImage-image"
          id="asHeaderProfileImage"
          src={src}
        />
      ) : (
        placeholder
      )}
    </div>
  );
}
