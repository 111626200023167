/* eslint-disable no-undef, func-names, camelcase */
// TODO: import { localStorage } from '../utils/namespaced-storage';
import { QueryClient } from 'react-query';

import createDebug from '../utils/debug';
import getGlobalValue from '../utils/get-global-value';
import createQueryConfig from '../queries';
import {
  UnauthorizedError,
  UnconfirmedEmailError,
  WrappedError,
} from '../utils/custom-errors';
import { fetch } from '../env/browser';
import { fetchJson } from '../utils/fetch';
import {
  API_BASE_URL,
  LEGACY_API_BASE_URL,
  COMPANY_BASE_URL,
  GRAPHQL_BASE_URL,
  REALTIME_APP_KEY,
  REALTIME_HOST,
  WHITE_LABEL,
  AUTH_BASE_URL,
  CELLO_PRODUCT_ID,
} from '../constants';

const debug = createDebug('initializers:api-client');

const switchLocationOnCore = (id) =>
  fetch(
    `${COMPANY_BASE_URL}/merchant/api/locations?merchant_profile_id=${id}`,
    { credentials: 'include' },
  );

function fetchLatestSubscription(merchantId, token) {
  const url = `${API_BASE_URL}/v3/subscription/merchants/${merchantId}/latest_subscription`;

  return fetch(url, {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
}

function fetchPortalSession(merchantId, token) {
  const url = `${API_BASE_URL}/v3/subscription/portal_sessions`;

  return fetch(url, {
    method: 'POST',
    body: JSON.stringify({ merchant_id: merchantId }),
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
  });
}

export const getCurrentLocationFromCore = () =>
  fetchJson(`${COMPANY_BASE_URL}/merchant/api/locations`, {
    credentials: 'include',
  }).then(({ json: data }) => data.current_location_id);

export function createApiClient({
  merchantId = '00000000-0000-0000-0000-000000000000',
  merchantAccountId = '00000000-0000-0000-0000-000000000000',
  organizationId = '00000000-0000-0000-0000-000000000000',
  // NOTE: dependency injection only for testing, intentionally getting
  //       Shore JS Client from window here, because it's coming from
  //       Shore CDN and we don't want to manage the npm package
  createShoreClient = getGlobalValue('Shore.Client.create'),
} = {}) {
  const apiBaseUrl = API_BASE_URL;
  const companyBaseUrl = COMPANY_BASE_URL;
  const config = {
    // Legacy Endpint
    availabilityBaseUrl: LEGACY_API_BASE_URL,
    // TODO: remove these when SJC was refactored
    apiV1BaseUrl: `${apiBaseUrl}/v1`,
    apiV2BaseUrl: `${apiBaseUrl}/v2`,
    appointmentBaseUrl: `${apiBaseUrl}/v1`,
    appointmentV2BaseUrl: `${apiBaseUrl}/v2`,
    // auth needs to use the base url for cookie reasons
    authBaseUrl: `${companyBaseUrl}/merchant/api/auth`,
    communicationBaseUrl: `${apiBaseUrl}/v1`,
    customerBaseUrl: `${apiBaseUrl}/v1`,
    customerMerchantBaseUrl: `${companyBaseUrl}/merchant/api/customers`,
    customersV2BaseUrl: `${apiBaseUrl}/v2`,
    employeeBaseUrl: `${apiBaseUrl}/v1`,
    employeeV2BaseUrl: `${apiBaseUrl}/v2`,
    filestorageBaseUrl: `${apiBaseUrl}/v1`,
    merchantAccountBaseUrl: `${apiBaseUrl}/v1`,
    merchantAccountV2BaseUrl: `${apiBaseUrl}/v2`,
    merchantBaseUrl: `${apiBaseUrl}/v1`,
    merchantV2BaseUrl: `${apiBaseUrl}/v2`,
    messagingBaseUrl: `${apiBaseUrl}/v1`,
    newsletterBaseUrl: `${apiBaseUrl}/v1`,
    notificationsBaseUrl: `${apiBaseUrl}/v1`,
    paymentsBaseUrl: `${companyBaseUrl}/merchant/api/charges`,
    reminderBaseUrl: `${apiBaseUrl}`,
    resourceBaseUrl: `${apiBaseUrl}/v1`,
    resourcesV2BaseUrl: `${apiBaseUrl}/v2`,
    seriesV2BaseUrl: `${apiBaseUrl}/v2`,
    serviceBaseUrl: `${apiBaseUrl}/v1`,
    appointmentGroupBaseUrl: `${apiBaseUrl}/v1`,

    realtime: {
      appKey: REALTIME_APP_KEY,
      authEndpoint: `${apiBaseUrl}/v1/pusher/auth`,
      httpHost: REALTIME_HOST,
      wsHost: REALTIME_HOST,
      encrypted: true,
      disableStats: true,
    },
    organizationId,
    merchantId,
    merchantAccountId,
    apiBaseUrl: API_BASE_URL,
    graphqlBaseUrl: GRAPHQL_BASE_URL,
    authDomain: AUTH_BASE_URL,
  };

  debug('using config', config);

  return createShoreClient(config);
}

export default (async function configureApiClient(
  apiClientCreator = createApiClient,
  queryClient = new QueryClient(),
) {
  let accountData;
  let merchantData;
  let sessionPermissions;
  const tmpApiClient = apiClientCreator();
  const queryConfig = createQueryConfig(queryClient, tmpApiClient);

  try {
    await queryClient.fetchQuery(queryConfig.authToken());
  } catch (error) {
    if (error && error?.status === 401) {
      if (
        error?.responseBody?.errors?.detail === 'email_confirmation_missing'
      ) {
        throw new UnconfirmedEmailError(
          'Please ensure your email is confirmed',
          error,
        );
      }
      throw new UnauthorizedError('Please ensure you are logged in!', error);
    }

    throw new WrappedError('Unable to authorize', error);
  }

  try {
    accountData = await queryClient.fetchQuery(queryConfig.merchantAccountV2());
  } catch (error) {
    throw new WrappedError('Requesting account infos failed', error);
  }

  // TODO:
  // // try to get currentMerchantId from localStorage
  // const currentMerchantId = localStorage.getItem(
  //   `${accountData.id}:currentMerchantId`,
  // );

  // XXX: get current location from CORE (temporarily)
  const currentMerchantId = await getCurrentLocationFromCore();
  const merchantDataFields = {
    merchants: [
      'feature_toggles',
      'name',
      'organization',
      'created_at',
      'source',
      'ui_visibility',
      'category',
      'pixel_id',
      'phone',
    ],
  };
  const organizationDataFields = {
    organizations: [
      'id',
      'trial_ends_on',
      'trial_days_left',
      'self_sign_up',
      'test',
      'package_code',
    ],
  };

  // try to fetch merchant data of currentMerchantId if defined
  if (currentMerchantId) {
    try {
      merchantData = await queryClient.fetchQuery(
        queryConfig.merchantV2(currentMerchantId),
      );
    } catch (error) {
      debug(error);
    }
  }

  // try to fetch first merchant data entry (for 1st visit with current account)
  if (!merchantData) {
    try {
      [merchantData] = await tmpApiClient.merchant.findMerchants({
        page: { size: 1 },
        fields: { ...merchantDataFields, ...organizationDataFields },
        include: ['organization'],
      });
    } catch (error) {
      throw new WrappedError('Requesting merchants failed', error);
    }
  }

  try {
    const { cello_jwt, given_name, surname, email } = accountData.employee;
    const { locale } = accountData;
    const localeFormattedForCello =
      (locale && locale.split && locale.split('-')[0]) || 'en';

    if (CELLO_PRODUCT_ID) {
      window.cello = window.cello || { cmd: [] };

      window.cello.cmd.push(function (cello) {
        cello.boot({
          productId: CELLO_PRODUCT_ID,
          token: cello_jwt,
          hideDefaultLauncher: true,
          customLauncherSelector: '#cello-launcher',
          language: localeFormattedForCello,
          productUserDetails: {
            firstName: given_name,
            lastName: surname,
            email,
          },
          announcement: {
            selector: '#cello-launcher-announcement',
            position: 'bottom',
            positionOffset: {
              x: '0px',
              y: '0px',
            },
          },
        });
      });
    }
  } catch (error) {
    throw new WrappedError('Unable to boot Cello', error);
  }

  // create final API client
  const merchantAccountId = accountData.id;
  const merchantId = merchantData.id;
  const organizationId = merchantData.organization.id;
  const finalApiClient = apiClientCreator({
    merchantAccountId,
    merchantId,
    organizationId,
  });

  // convenience method for loading the avatar image URL
  const getAvatarUrl = async () => {
    try {
      const data = await finalApiClient.employeeV2.find(
        accountData.employee.id,
      );
      return data.image_url;
    } catch (error) {
      /* istanbul ignore next */
      debug(error);
      return null; // just return 'null' if there's no image available
    }
  };

  const getWhiteLabelConfig = async () => {
    try {
      const { json } = await fetchJson(WHITE_LABEL);
      return json;
    } catch (error) {
      debug(error);
      return {};
    }
  };

  // try to fetch current user app permissions data of currentMerchantId if  defined
  if (currentMerchantId) {
    try {
      sessionPermissions = await queryClient.fetchQuery(
        queryConfig.merchantAppsV2(currentMerchantId),
      );
    } catch (error) {
      throw new WrappedError(
        'Requesting merchant user app permissions failed',
        error,
      );
    }
  }

  return {
    ...finalApiClient,

    /* custom convenience methods */
    getAvatarUrl,
    getCurrentMerchantData: () => Promise.resolve(merchantData),
    getMyAccountData: () => Promise.resolve(accountData),
    getSessionPermissions: () => Promise.resolve(sessionPermissions),
    subscriptionV3: {
      fetchLatestSubscription,
      fetchPortalSession,
    },
    getWhiteLabelConfig,
    switchLocationOnCore,
  };
});

/* eslint-enable no-undef, func-names, camelcase */
