import { h } from 'preact';
import { t } from '../../utils/i18n';

export default function PaymentWarning() {
  return (
    <div className="as-PaymentWarning">
      <shore-icon name="request-status" className="warning-icon" />
      <span>{t('payment-warning.message.line-1')}</span>
      <a href="/settings/payment">{t('payment-warning.message.click-here')}</a>
    </div>
  );
}
