export const isDefined = (value) => value !== undefined && value !== null;
export const isString = (value) => typeof value === 'string';
export const isArray = (value) => Array.isArray(value);
export const isObject = (value) => typeof value === 'object' && value !== null;
export const isNotBlank = (value) => value.trim().length > 0;
export const isSHA1 = (value) => /^[0-9a-f]{4,40}$/.test(value);
export const isISODateString = (value) =>
  /^\d{4}(-\d{2}){2}T\d{2}(:\d{2}){2}\.\d{1,3}Z$/.test(value);
export const isBaseURL = (value) =>
  /^(https?:)?\/{2,}[-\w]+([-:./\w@]+)?$/.test(value);
export const isRelativeFilePath =
  (...extensions) =>
  (value) =>
    new RegExp(`^[-\\w]([-./\\w]+)?\\.(${extensions.join('|')})$`).test(value);
export const includes = (thing) => (value) => value.includes(thing);
export const endsWith =
  (...strings) =>
  (value) =>
    strings.some((str) => value.endsWith(str));
export const every =
  (...validators) =>
  (arr) =>
    arr.every((value) => validators.every((validator) => validator(value)));
export const key =
  (name, ...validators) =>
  (obj) =>
    name in obj && validators.every((validator) => validator(obj[name]));
