import { createAction, createActions } from 'redux-actions';
import { NOOP } from '../constants';
import createDebug from '../utils/debug';

const debug = createDebug('actions');

export const {
  receiveAvatarUrl,
  closeOverlay,
  closeSidebar,
  closeModal,
  hitEscape,
  storeMerchantId,
  reloadAppShell,
  setLoadingApp,
  setLoadingOverlay,
  setLoadingSidebar,
  openModal,
  openAppInModal,
  modalAppLoaded,
  clickModalBackground,
  notificationsAppointmentClick,
  notificationsMessageClick,
  initNotificationCounts,
  newNotificationCounts,
  initNotificationClientMessages,
  notificationsClientMessageCloseClick,
  notificationsAppointmentItemClick,
  notificationsAppointmentCloseClick,
  notificationsAppointmentFetchSuccess,
  notificationsAppointmentFetchError,
  dismissAppAndOverlayError,
  clickHeaderButton,
  receiveDocumentHeaders,
  clickLogout,
  updateTrialPeriod,
  documentClick,
  setOrganizationStatus,
  setDefaultPaymentSourceType,
  setContractDetails,
  setHubspotReady,
  setIntercomReady,
} = createActions(
  'RECEIVE_AVATAR_URL',
  'CLOSE_OVERLAY',
  'CLOSE_SIDEBAR',
  'CLOSE_MODAL',
  'HIT_ESCAPE',
  'STORE_MERCHANT_ID',
  'RELOAD_APP_SHELL',
  'SET_LOADING_APP',
  'SET_LOADING_OVERLAY',
  'SET_LOADING_SIDEBAR',
  'OPEN_MODAL',
  'OPEN_APP_IN_MODAL',
  'MODAL_APP_LOADED',
  'CLICK_MODAL_BACKGROUND',
  'NOTIFICATIONS_APPOINTMENT_CLICK',
  'NOTIFICATIONS_MESSAGE_CLICK',
  'INIT_NOTIFICATION_COUNTS',
  'NEW_NOTIFICATION_COUNTS',
  'INIT_NOTIFICATION_CLIENT_MESSAGES',
  'NOTIFICATIONS_CLIENT_MESSAGE_CLOSE_CLICK',
  'NOTIFICATIONS_APPOINTMENT_ITEM_CLICK',
  'NOTIFICATIONS_APPOINTMENT_CLOSE_CLICK',
  'NOTIFICATIONS_APPOINTMENT_FETCH_SUCCESS',
  'NOTIFICATIONS_APPOINTMENT_FETCH_ERROR',
  'DISMISS_APP_AND_OVERLAY_ERROR',
  'CLICK_HEADER_BUTTON',
  'RECEIVE_DOCUMENT_HEADERS',
  'CLICK_LOGOUT',
  'UPDATE_TRIAL_PERIOD',
  'DOCUMENT_CLICK',
  'SET_ORGANIZATION_STATUS',
  'SET_DEFAULT_PAYMENT_SOURCE_TYPE',
  'SET_CONTRACT_DETAILS',
  'SET_HUBSPOT_READY',
  'SET_INTERCOM_READY',
);

// creating error action creators without `createAction`, because when passing
// an instanceof Error, the resulting action object is different from what
// is expected in this case.
const createErrorAction = (name) => {
  const actionCreator = (error) => ({
    type: name,
    payload:
      error instanceof Error
        ? {
            message: error.message,
            name: error.name,
          }
        : {
            message: error ? error.toString() : 'An unknown error occured',
            name: 'Error',
          },
    meta: {
      error,
    },
  });
  actionCreator.toString = () => name;
  return actionCreator;
};

export const notificationSMSDisplaySeverity = createAction(
  'NOTIFICATIONS_SMS_DISPLAY_SEVERITY',
  (severity) => severity,
);

export const initializeError = createErrorAction('INITIALIZE_ERROR');

export const appError = createErrorAction('APP_ERROR');
export const setupBrowserLocaleAndErrorState = createErrorAction(
  'SETUP_BROWSER_LOCALE',
);

export const overlayError = createErrorAction('OVERLAY_ERROR');

export const sidebarError = createErrorAction('SIDEBAR_ERROR');

export const initializeSuccess = createAction(
  'INITIALIZE_SUCCESS',
  (
    myAccountData,
    currentMerchantData,
    whiteLabelConfig,
    packageCode,
    sessionPermissions,
    subscription,
  ) => ({
    myAccountData,
    currentMerchantData,
    whiteLabelConfig,
    packageCode,
    sessionPermissions,
    subscription,
  }),
);

export const openSidebar = createAction('OPEN_SIDEBAR', (url) => ({
  url,
}));

export const changeLocation = createAction(
  'ROUTING/CHANGE_LOCATION',
  (url) => ({
    url,
  }),
);

export const navigateTo = createAction('APP(app-shell)/NAVIGATE_TO', (url) => ({
  url,
}));

export const proxyAppAction =
  (onProxyAction = NOOP) =>
  (appName, appAction) => {
    if (!appAction.meta || !appAction.meta.proxy) return null;

    const proxiedAction = {
      ...appAction,
      type: `APP(${appName})/${appAction.type}`,
      meta: { ...appAction.meta, proxy: false }, // prevent proxy loop
    };

    debug('proxyAppAction', proxiedAction);

    onProxyAction(proxiedAction);

    return proxiedAction;
  };
