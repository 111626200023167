import StackTrace from 'stacktrace-js';

function formatStacktraceOutput(errorName, errorMessage, stackframes) {
  const stackWithSourcemapsTranslation = stackframes
    .map(
      ({ functionName, fileName, lineNumber, columnNumber }) =>
        `at ${functionName} (${fileName}:${lineNumber}:${columnNumber})`,
    )
    .join('\n');

  return `${errorName}: ${errorMessage}\n${stackWithSourcemapsTranslation}`;
}

function extractAppNameFromRawStacktrace(stacktrace) {
  const [microAppNameMatch] = stacktrace.match(/\w?[-\w]*-app\.min\.js/);

  if (microAppNameMatch) {
    return microAppNameMatch.replace('-app.min.js', '');
  }

  return null;
}

function decodeMinifiedStacktrace(error) {
  return StackTrace.fromError(error).then((stackframes) => {
    const appName = extractAppNameFromRawStacktrace(error.stack);

    // eslint-disable-next-line no-param-reassign
    error.message = `[${appName}-app] ${error.message}`;
    // eslint-disable-next-line no-param-reassign
    error.appName = appName;
    // eslint-disable-next-line no-param-reassign
    error.stack = formatStacktraceOutput(
      error.name,
      error.message,
      stackframes,
    );

    return error;
  });
}

export default decodeMinifiedStacktrace;
