export const containerStyle = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  flex: '1',
  height: '100%',
};

export const iframeStyle = {
  flex: 1,
  height: '100%',
};

export const MICRO_APP_URL = `http://shore.localhost:${process.env.SERVER_PORT}/microapp`;

export const MICRO_APP_STARTUP_MESSAGE = 'initializeApp';

export const NO_PROPS = null;
