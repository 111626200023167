import { TRACK_IDENTIFY, TRACK_PAGE, NOOP } from '../constants';
import createDebug from '../utils/debug';

const debug = createDebug('track-method');
const defaultInstance = {
  track: (...args) => debug('track', ...args),
  identify: (...args) => debug(TRACK_IDENTIFY, ...args),
  page: (...args) => debug(TRACK_PAGE, ...args),
};

export default function createTrackMethod(getAnalytics = NOOP) {
  return (eventName, eventPayload) => {
    const throwError = (error) => {
      throw new Error(error);
    };

    const {
      track = throwError('track method missing'),
      identify = throwError('identify method missing'),
      page = throwError('page method missing'),
    } = getAnalytics() || defaultInstance;

    switch (eventName) {
      case TRACK_IDENTIFY:
        identify(eventPayload.id, eventPayload.data);
        return;

      case TRACK_PAGE:
        page(eventPayload.name, eventPayload.properties);
        return;

      default:
        if (eventName) {
          track(eventName, eventPayload);
        }
    }
  };
}
