import { connect } from 'react-redux';
import Spinner from '../../components/Spinner';

const mapStateToProps = (state) => ({
  isVisible:
    !(state.initialization.success || state.initialization.error) ||
    state.currentApp.isLoading ||
    state.currentOverlay.isLoading,
});

export default connect(mapStateToProps)(Spinner);
