/* eslint-disable react/jsx-props-no-spreading */
import { h } from 'preact';
import { useEffect } from 'preact/hooks';
import { useLocation } from 'react-router-dom';
import { Spin } from 'antd';
import { useQuery } from 'react-query';

import { useGlobals } from '../../globals-context';
import EVENTS from '../../constants/event-bus-events';
import MicroApp from '../../components/MicroApp';
import useMicroServicesTools from '../../hooks/use-micro-services-tools';
import useMerchantSettings from '../../hooks/use-merchant-settings';
import createQueryConfig from '../../queries';

const containerStyle = {
  display: 'flex',
  height: '100%',
  flex: 1,
};

const loadingContainerStyle = {
  ...containerStyle,
  justifyContent: 'center',
  alignItems: 'center',
};

const contractStyle = {
  flex: 1,
};

function Loading() {
  return (
    <div style={loadingContainerStyle}>
      <Spin />
    </div>
  );
}

export function SettingsApp() {
  const { eventBus, queryClient, apiClient } = useGlobals();
  const microServicesTools = useMicroServicesTools('settings');
  const { isLoading: isLoadingSettings, merchantSettings } =
    useMerchantSettings();
  const queryConfig = createQueryConfig(queryClient, apiClient);
  const { status: subscriptionStatus } = merchantSettings
    ? useQuery(queryConfig.subscription(merchantSettings))
    : { status: 'loading' };
  const hasChargebeeSubscription = subscriptionStatus === 'success';

  useEffect(() => {
    eventBus.emit(EVENTS.DISABLE_SPINNER);
    eventBus.emit(EVENTS.CLOSE_MENU);

    return () => {
      eventBus.emit(EVENTS.ENABLE_SPINNER);
    };
  }, []);

  if (isLoadingSettings || subscriptionStatus === 'loading') {
    return <Loading />;
  }

  const location = useLocation();
  const fullPath = `${location.pathname}${location.search}`;

  return (
    <div style={containerStyle}>
      <MicroApp
        name="settings"
        style={contractStyle}
        loading={<Loading />}
        settings={merchantSettings}
        mountPath="/settings"
        pathAndQuery={fullPath}
        useHashHistory={false}
        hasChargebeeSubscription={hasChargebeeSubscription}
        {...microServicesTools}
      />
    </div>
  );
}
/* eslint-enable react/jsx-props-no-spreading */
