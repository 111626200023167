import { NOOP } from './constants';
import {
  location,
  history,
  addEventListener,
  removeEventListener,
} from './env/browser';
import createDebug from './utils/debug';

const debug = createDebug('router');

// helpers

export function findRouteLink(node) {
  if (!node || !node.tagName) return undefined;
  if (node.tagName.toLowerCase() === 'body') return undefined;

  const hasDataRoute = node.hasAttribute('data-route');
  const href = node.getAttribute('href');

  if (hasDataRoute && href) return node;

  return findRouteLink(node.parentNode);
}

// router

export default function createRouter() {
  let changeLocationCallback = NOOP;

  function handleLocationChange() {
    changeLocationCallback(location.href);
  }

  function route(url, { replace = false, forceReload = false } = {}) {
    debug('navigate', url, { replace, forceReload });

    if (forceReload) {
      location.href = url;
      return;
    }

    if (location.href === url) return;

    history[replace ? 'replaceState' : 'pushState'](null, null, url);
    handleLocationChange();
  }

  function navigate(url) {
    route(url, { replace: false });
  }

  function redirect(url) {
    route(url, { replace: true });
  }

  function load(url) {
    route(url, { forceReload: true });
  }

  function onChangeLocation(callback, shouldTriggerForCurrentLocation = false) {
    changeLocationCallback = callback;
    if (shouldTriggerForCurrentLocation) handleLocationChange();
  }

  function reloadLocation() {
    location.reload();
  }

  function subscribe() {
    addEventListener('popstate', handleLocationChange);
  }

  function destroy() {
    removeEventListener('popstate', handleLocationChange);
  }

  // public interface

  return Object.freeze({
    navigate,
    redirect,
    load,
    reloadLocation,
    onChangeLocation,
    subscribe,
    destroy,
  });
}
