import { h } from 'preact';
import { Navigate, useLocation } from 'react-router-dom';

import AppShell from './AppShell';
import Main from './views/Main';
import FeatureRollout from './utils/feature-rollout';
import { NODE_ENV, START_PATH } from './constants';
import { Page404 } from './components/Page404';
import { PageAccessDenied } from './components/PageAccessDenied';
import { useSessionPermissions } from './hooks';
import { SubscriptionCheckout } from './views/subscription-checkout';
import { SubscriptionSettings } from './views/settings/subscription';
import { SettingsApp } from './views/settings/app';
import { ContractApp } from './views/contract';

import {
  ServicesList,
  EditService,
  EditCourse,
  EditCategory,
  NewService,
  NewCourse,
  NewCategory,
} from './views/services';

const isServicesRefactorActive = FeatureRollout.activate(
  'services-app-refactor',
  { percentage: 0 },
);

/*
  IMPORTANT: The reason we have only one registered route (which is, by the way, a wildcard)
  pointing to the Main component is that we are leveraging the app-shell design, specifically
  the way it loads micro FE apps.

  The improvements consist of two steps:
   1) Deprecating the old way of doing this loading process, which relies on the browser routing
      that is used to listen to route changes, get the new route path and load a micro app matching that
      routing path name. All this logic is now encapsulated into the Main component, which should
      remain untouched from now on;
   2) Start to define new route paths that are associated to a single page component with a well defined
      context and responsibilities set. These page components will use the MicroApp component to load the
      micro apps, and legacy routes should be refactored - on a iterative manner - to use react-router routes
      and have their own individual page components.

  For more information, refer to the ADR link:
  https://www.notion.so/shorewiki/App-Shell-architecture-redesign-a842d1054de44867a9fa9a1228dab64d

*/
let playgroundRoutes = [];

if (NODE_ENV === 'development') {
  // eslint-disable-next-line global-require
  const { default: pRoutes } = require('../playground/routes');

  playgroundRoutes = pRoutes;
}

const servicesRoutes = isServicesRefactorActive
  ? [
      {
        path: '/services',
        element: <ServicesList />,
        children: [
          {
            path: 'edit/service/:serviceId',
            element: <EditService />,
          },
          {
            path: 'edit/course/:courseId',
            element: <EditCourse />,
          },
          {
            path: 'edit/category/:categoryId',
            element: <EditCategory />,
          },
          {
            path: 'new/service',
            element: <NewService />,
          },
          {
            path: 'new/course',
            element: <NewCourse />,
          },
          {
            path: 'new/category',
            element: <NewCategory />,
          },
        ],
      },
    ]
  : [];

export const INIT_APP_ROUTES = [
  {
    path: '*',
    element: <AppShell />,
  },
];

function RedirectToSubscriptionCheckout() {
  const location = useLocation();

  if (location.pathname !== '/subscription') {
    return <Navigate to="/subscription" />;
  }

  return null;
}

export const INACTIVE_SUBSCRIPTION_ROUTES = [
  {
    element: <AppShell />,
    children: [
      {
        path: '/subscription',
        element: <SubscriptionCheckout />,
      },
      {
        path: '/settings/*',
        element: <SubscriptionSettings />,
      },
      {
        path: '*',
        element: <RedirectToSubscriptionCheckout />,
      },
    ],
  },
];

export const ROUTES = [
  {
    element: <AppShell />,
    children: [
      ...playgroundRoutes,
      ...servicesRoutes,
      {
        path: '/',
        element: <Navigate to={START_PATH} />,
      },
      {
        path: '/404',
        element: <Page404 />,
      },
      {
        path: '/contract',
        element: <ContractApp />,
      },
      {
        path: '/subscription',
        element: <SubscriptionCheckout />,
      },
      {
        path: '/settings/subscription',
        element: <SubscriptionSettings />,
      },
      {
        path: '/settings/*',
        element: <SettingsApp />,
      },
      {
        path: '/access-denied',
        element: <PageAccessDenied />,
      },
      {
        path: '*',
        // This component is DEPRECATED and must remain untouched.
        element: (
          <PrivateRoute>
            <Main />
          </PrivateRoute>
        ),
      },
    ],
  },
];

function PrivateRoute({ children }) {
  const sessionPermissions = useSessionPermissions();

  if (sessionPermissions.role && !sessionPermissions.hasPermission) {
    return <Navigate to="/access-denied" />;
  }

  if (sessionPermissions.role && sessionPermissions.hasPermission) {
    return children;
  }

  return null;
}
