/* eslint-disable react/jsx-props-no-spreading  */
import { h } from 'preact';
import PaymentWarning from '../PaymentWarning';
import DunningWarning from '../DunningWarning';

const resolvePanelContent = ({
  showPaymentWarning,
  dunningWarning,
  ...props
}) => {
  if (showPaymentWarning) {
    return <PaymentWarning {...props} />;
  }
  if (dunningWarning) {
    return <DunningWarning stage={dunningWarning} />;
  }

  return <span />;
};

export default function ActionPanel(props) {
  const content = resolvePanelContent(props);
  return (
    content && (
      <div className="as-ActionPanel">
        <div className="content">{content}</div>
      </div>
    )
  );
}
/* eslint-enable react/jsx-props-no-spreading  */
