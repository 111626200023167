/* eslint-disable import/prefer-default-export */
import { h } from 'preact';
import { DowntimeIcon } from './DowntimeIcon';

export function DowntimeModal({ title, message, actionButton }) {
  return (
    <div class="as-Error-body">
      <h2 class="as-Error-title">{title}</h2>
      {
        /* eslint-disable  react/no-danger */
        <p
          class="as-Error-message"
          dangerouslySetInnerHTML={{ __html: message }}
        />
        /* eslint-enable */
      }

      <div className="as-Error-icon">
        <DowntimeIcon />
      </div>
      {actionButton.isVisible && (
        <a
          href={actionButton.href}
          class="as-Error-actionButton"
          target="_blank"
          rel="noopener noreferrer"
        >
          {actionButton.title}
        </a>
      )}
    </div>
  );
}
