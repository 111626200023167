import { h } from 'preact';

import MicroApp from '../../components/MicroApp';
import useServicesSettings from './use-services-settings';

export function NewCourse() {
  const { isLoading, settings } = useServicesSettings();

  if (isLoading) {
    return null;
  }

  return <MicroApp name="services/NewCourse" settings={settings} />;
}
