import { h } from 'preact';
import cn from 'classnames';

const Button = ({
  inverted = false,
  isActive = false,
  href,
  children,
  dataRoute,
  ...props
}) =>
  h(
    href ? 'a' : 'button',
    {
      'data-route': dataRoute === false ? undefined : '',
      ...props,
      href,
      class: cn('as-Button', props.class, {
        'as-Button--inverted': inverted,
        'is-active': isActive,
      }),
    },
    children,
  );

export default Button;
