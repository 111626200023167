import { Component, h } from 'preact';
import { createAsyncScriptNode } from '../../../utils/dom';
import { getUserlaneTags } from '../../../selectors/widgetsVisibility';
import { window, document } from '../../../env/browser';

export default class UserlaneWidget extends Component {
  componentDidMount() {
    const { appId, merchant, account, whiteLabel } = this.props;
    const userlaneTags = getUserlaneTags(merchant, account);

    window.userlaneSettings = {
      app_id: appId,
      user: {
        id: account.id,
        tags: userlaneTags,
      },
      lang: account.locale,
      configs: {
        assistantPosition: whiteLabel === 'shore' ? 'right' : 'left',
      },
    };

    document.head.appendChild(
      createAsyncScriptNode({
        src: '//cdn.userlane.com/userlane.js',
        onload: () => {
          const { analytics, Userlane } = window;

          if (
            typeof analytics !== 'undefined' &&
            typeof analytics.track === 'function' &&
            typeof Userlane === 'function'
          ) {
            const trackUserlaneEvent = (when, what) => {
              Userlane(when, (userlaneId) => {
                analytics.track(what, {
                  userlaneId,
                });
              });
            };
            trackUserlaneEvent('onStart', 'Userlane started');
            trackUserlaneEvent('onComplete', 'Userlane completed');
            trackUserlaneEvent('onExit', 'Userlane cancelled');

            if (whiteLabel === 'shore') {
              Userlane('hide');
            }
          }
        },
      }),
    );
  }

  render() {
    const { whiteLabel } = this.props;
    // Offset's userlane position so it doesn't clash with other page elements
    const userlaneShoreAssistantCSS = `
      #userlane-assistant-container {
        bottom: 90px !important;
        right: 20px !important;
        left: unset !important;
        width: 50px;
        height: 50px;
        min-height: 50px !important;

        #usln-p-assistant-bubble{
          right: 0;
        }

        #usln-p-assistant-avatar[aria-expanded="false"] {
          display: none;
        }
      }`;

    const userlaneWhiteLabelAssistantCSS = `
      #userlane-assistant-container {
        bottom: 20px !important;
        right: unset !important;
        left: 20px !important;
        width: 50px;
        height: 50px;
        min-height: 50px !important;

        #usln-p-assistant-bubble{
          right: unset;
        }

        #usln-p-assistant-avatar[aria-expanded="false"] {
          display: block;
        }
      }`;
    /* eslint-disable react/no-danger */
    return (
      <style
        dangerouslySetInnerHTML={{
          __html:
            whiteLabel === 'shore'
              ? userlaneShoreAssistantCSS
              : userlaneWhiteLabelAssistantCSS,
        }}
      />
    );
    /* eslint-enable react/no-danger */
  }
}
