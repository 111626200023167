import cn from 'classnames';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { document } from '../../env/browser';
import {
  setLoadingApp,
  proxyAppAction,
  appError,
  reloadAppShell,
} from '../../actions';
import { getAppSettings, shouldBlur } from '../../selectors';
import AppLoader from '../../components/AppLoader';
import { MODAL_ELEMENT_ID } from '../../constants';

const mapStateToProps = (state, props) => ({
  settings: getAppSettings(state),
  name: props.name || state.currentApp.name,
  disabled: !state.initialization.success,
  pathAndQuery: state.currentApp.pathAndQuery,
  useHashHistory: false,
  routingType: 'path',
  documentDate: state.documentHeaders.date,
  modal: {
    element: document.getElementById(MODAL_ELEMENT_ID),
  },
  class: cn('as-App', {
    'is-blurred': shouldBlur(state),
    'is-temporarilyUnblurred': state.currentOverlay.isTemporarilyHidden,
  }),
});

const mapDispatchToProps = (dispatch, { onProxyAction }) =>
  bindActionCreators(
    {
      onLoadingApp: setLoadingApp,
      onAppAction: proxyAppAction(onProxyAction),
      onError: appError,
      onDocumentOutdated: reloadAppShell,
    },
    (action) => action && dispatch(action),
  );

export default connect(mapStateToProps, mapDispatchToProps)(AppLoader);
