import { h } from 'preact';
import { useState, useEffect } from 'preact/hooks';
import cn from 'classnames';

import { useGlobals } from '../../globals-context';
import EVENTS from '../../constants/event-bus-events';

function Spinner({ isVisible = false }) {
  const [isDisabled, disable] = useState(false);
  const { eventBus } = useGlobals();
  const shouldShow = !isDisabled && isVisible;

  useEffect(() => {
    function disableVisibility() {
      disable(true);
    }

    function enableVisibility() {
      disable(false);
    }

    eventBus.on(EVENTS.DISABLE_SPINNER, disableVisibility);
    eventBus.on(EVENTS.ENABLE_SPINNER, enableVisibility);

    return () => {
      eventBus.off(EVENTS.DISABLE_SPINNER, disableVisibility);
      eventBus.off(EVENTS.ENABLE_SPINNER, enableVisibility);
    };
  }, []);

  return (
    <div class={cn('as-Spinner', { 'is-visible': shouldShow })}>
      <shore-spinner />
    </div>
  );
}

export default Spinner;
