/* eslint-disable import/prefer-default-export */
import { h } from 'preact';
import { Result, Button } from 'antd';
import { useNavigate } from 'react-router-dom';
import { t } from '../../utils/i18n';

import Shore404Icon from '../../assets/images/svg/shore-404.svg';

export function Page404() {
  const navigate = useNavigate();

  const handleOnBackToCalendarClick = () => {
    navigate(`/calendar`);
  };

  return (
    <div className="as-Page404">
      <Result
        icon={<Shore404Icon />}
        title={t('page-404.title')}
        subTitle={t('page-404.description')}
        extra={
          <Button type="primary" onClick={handleOnBackToCalendarClick}>
            {t('page-404.button.back-to-calendar')}
          </Button>
        }
      />
    </div>
  );
}
