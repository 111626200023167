export function createAssetEntry(manifest, type) {
  return (
    manifest[type] && {
      url: manifest.baseURL + manifest[type],
      type,
    }
  );
}

export function createStyleEntry(manifest) {
  return createAssetEntry(manifest, 'style');
}

export function createScriptEntry(manifest) {
  const entry = createAssetEntry(manifest, 'script');
  entry.exposed = manifest.exposed;
  return entry;
}
