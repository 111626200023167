import cn from 'classnames';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  setLoadingSidebar,
  proxyAppAction,
  sidebarError,
  reloadAppShell,
} from '../../actions';
import { getAppSettings } from '../../selectors';
import AppLoader from '../../components/AppLoader';
import { document } from '../../env/browser';
import { MODAL_ELEMENT_ID } from '../../constants';

const mapStateToProps = (state) => ({
  settings: getAppSettings(state),
  name: state.currentSidebar.name,
  disabled: !state.initialization.success,
  pathAndQuery: state.currentSidebar.pathAndQuery,
  useHashHistory: true,
  routingType: 'hash',
  documentDate: state.documentHeaders.date,
  modal: {
    element: document.getElementById(MODAL_ELEMENT_ID),
  },
  class: cn('as-Sidebar', {
    'is-hidden': state.currentSidebar.isHidden,
  }),
});

const mapDispatchToProps = (dispatch, { onProxyAction }) =>
  bindActionCreators(
    {
      onLoadingApp: setLoadingSidebar,
      onAppAction: proxyAppAction(onProxyAction),
      onError: sidebarError,
      onDocumentOutdated: reloadAppShell,
    },
    (action) => action && dispatch(action),
  );

export default connect(mapStateToProps, mapDispatchToProps)(AppLoader);
