import { datadogRum } from '@datadog/browser-rum';
import { v4 as uuidv4 } from 'uuid';

import { API_ENV, DATADOG_SITE } from '../constants';
import { localStorage, window } from '../env/browser';

const CLIENT_SECRET = 'pub7cdc7336b1df5870d03746538cfb8dd1';

const ANONYMOUS_USER_STORAGE_KEY = 'app-shell/tracer/anonymous_id';

function anonymousId() {
  const currentAnonymousId = localStorage.getItem(ANONYMOUS_USER_STORAGE_KEY);

  if (!currentAnonymousId) {
    const newId = uuidv4();

    localStorage.setItem(ANONYMOUS_USER_STORAGE_KEY, newId);

    return newId;
  }

  return currentAnonymousId;
}

const Tracer = {
  init() {
    const { CIRCLE_BUILD_NUM } = process.env;
    const env = API_ENV === 'staging' ? 'staging' : 'prod';

    datadogRum.init({
      env,
      applicationId: '579dc7ba-05b1-4551-b639-358d011e98c3',
      clientToken: CLIENT_SECRET,
      site: DATADOG_SITE,
      service: 'web-calendar-app',
      defaultPrivacyLevel: 'mask-user-input',
      allowedTracingOrigins: [
        /https:\/\/.*\.shore\.com/,
        /https:\/\/.*\.localsearch\.ch/,
      ],
      sessionSampleRate: 3,
      sessionReplaySampleRate: 0,
      replaySampleRate: 0,
      premiumSampleRate: 0,
      trackInteractions: true,
      trackResources: true,
      trackLongTasks: true,
      version: `${env}-${CIRCLE_BUILD_NUM}`,
    });

    this.setGlobalContext('anonymous_id', anonymousId());
    this.setGlobalContext('hostname', window.location.hostname);
  },

  setUser({ merchantId, employeeId }) {
    datadogRum.removeGlobalContextProperty('anonymous_id');
    datadogRum.setUser({ id: employeeId, merchantId });
  },

  setAppName(name) {
    datadogRum.setGlobalContextProperty('app', name);
  },

  setGlobalContext(contextKey, contextValue) {
    datadogRum.setGlobalContextProperty(contextKey, contextValue);
  },

  getGlobalContextProperty(name) {
    const context = datadogRum.getGlobalContext();

    return context && context[name];
  },

  addAction(name, payload = {}) {
    datadogRum.addAction(name, payload);
  },

  addError(error, context) {
    datadogRum.addError(error, context);
  },

  addTiming(name, timing) {
    datadogRum.addTiming(name, timing);
  },

  clearAppConfig() {
    datadogRum.removeGlobalContextProperty('app');
  },

  removeUser() {
    datadogRum.clearUser();
  },
};

export default Tracer;
