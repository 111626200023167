/* eslint-disable max-classes-per-file  */
class ApiError extends Error {
  constructor(message, statusCode) {
    super(message);
    this.name = 'ApiError';
    this.statusCode = statusCode;
  }
}

export class NotFoundError extends Error {
  constructor() {
    super('Resource not found');
    this.name = 'NotFoundError';
    this.statusCode = 404;
  }
}

export const keys = {
  authToken: ['merchant_account_token'],
  merchantAccountV2: (token) => ['merchant_account', 'v2', token],
  merchantV2: (merchantId, token) => ['merchant', 'v2', merchantId, token],
  merchantAppsV2: (merchantId, token) => [
    'merchant_app',
    'v2',
    merchantId,
    token,
  ],
  merchantsSearch: (token) => ['merchantSearch', token],
  contractV2: {
    findAll: (organizationId, token) => [
      'contractV2',
      'findAll',
      organizationId,
      token,
    ],
  },
  sms: {
    currentMonthUsage: (merchantId) => [
      'sms',
      'current_month_usage',
      merchantId,
    ],
  },
  subscription: {
    latest: (merchantId) => ['subscription', merchantId],
    portalSession: (merchantId) => ['subscriptionPortalSession', merchantId],
  },
};

function injectErrorMetadata(metadata) {
  return (error) => {
    // eslint-disable-next-line no-param-reassign
    error.metadata = metadata;

    return Promise.reject(error);
  };
}

function authenticatedQuery(queryClient) {
  return (configSetupFn) =>
    (...args) => {
      const token = queryClient.getQueryData(keys.authToken);

      if (!token) {
        throw new Error('Missing auth token!');
      }

      return configSetupFn(token, ...args);
    };
}

function retryDelayFn(attemptIndex) {
  return Math.min(1000 * 2 ** attemptIndex, 30000);
}

function retryUnless404(retryCount) {
  return (failureCount, error) => {
    if (error instanceof NotFoundError) {
      return false;
    }

    return failureCount < retryCount;
  };
}

async function handleApiResponse(response) {
  if (response.status === 404) {
    throw new NotFoundError();
  }

  const errorResponse = response.status >= 400;

  const responseData = await response.json();

  if (errorResponse) {
    throw new ApiError(JSON.stringify(responseData), response.status);
  }

  return responseData;
}

export default function createQueryConfig(queryClient, apiClient) {
  return {
    authToken: () => {
      const queryKey = keys.authToken;

      function fetchToken() {
        return apiClient.authV2
          .getOrCreateAccessToken()
          .then(String)
          .catch(injectErrorMetadata({ queryKey }));
      }

      return {
        queryKey,
        queryFn: fetchToken,
        cacheTime: Infinity,
      };
    },

    subscription: authenticatedQuery(queryClient)((token, { merchantId }) => {
      const queryKey = keys.subscription.latest(merchantId);

      function fetchLatestSubscription() {
        return apiClient.subscriptionV3
          .fetchLatestSubscription(merchantId, token)
          .then(handleApiResponse)
          .catch(injectErrorMetadata({ queryKey, token }));
      }

      return {
        queryKey,
        queryFn: fetchLatestSubscription,
        cacheTime: Infinity,
        retry: retryUnless404(3),
        retryDelay: retryDelayFn,
      };
    }),

    subscriptionPortalSession: authenticatedQuery(queryClient)(
      (token, { merchantId }) => {
        const queryKey = keys.subscription.portalSession(merchantId);

        function fetchPortalSession() {
          return apiClient.subscriptionV3
            .fetchPortalSession(merchantId, token)
            .then(handleApiResponse)
            .catch(injectErrorMetadata({ queryKey, token }));
        }

        return {
          queryKey,
          queryFn: fetchPortalSession,
          cacheTime: Infinity,
          retry: retryUnless404(3),
          retryDelay: retryDelayFn,
        };
      },
    ),

    smsCurrentMonthUsage: (merchantId) => {
      const queryKey = keys.sms.currentMonthUsage(merchantId);
      const now = new Date();

      function fetchSmsCurrentMonthUsage() {
        return apiClient.smsUsage.findAll({
          filter: {
            month: now.getMonth() + 1,
            year: now.getFullYear(),
            merchant_id: merchantId,
          },
        });
      }

      return {
        queryKey,
        queryFn: fetchSmsCurrentMonthUsage,
        cacheTime: Infinity,
        retry: retryUnless404(3),
        retryDelay: retryDelayFn,
      };
    },

    merchantAccountV2: authenticatedQuery(queryClient)((token) => {
      const queryKey = keys.merchantAccountV2(String(token));

      function fetchMerchantAccount() {
        return apiClient.merchantAccountV2
          .me({
            fields: {
              merchant_account: ['employee'],
              organizations: ['key_account_contract', 'test', 'name'],
            },
            include: ['employee', 'merchants', 'organization'],
          })
          .catch(injectErrorMetadata({ queryKey, token }));
      }

      return {
        queryKey,
        queryFn: fetchMerchantAccount,
      };
    }),

    merchantV2: authenticatedQuery(queryClient)((token, merchantId) => {
      const queryKey = keys.merchantV2(merchantId, String(token));

      function fetchMerchant() {
        const merchants = [
          'feature_toggles',
          'name',
          'organization',
          'created_at',
          'source',
          'ui_visibility',
          'category',
          'pixel_id',
          'slug',
          'phone',
        ];

        const organizations = [
          'id',
          'trial_ends_on',
          'trial_days_left',
          'self_sign_up',
          'test',
          'package_code',
          'whitelabel_slug',
        ];

        return apiClient.merchantV2
          .find(merchantId, {
            include: ['organization'],
            fields: { organizations, merchants },
          })
          .catch(injectErrorMetadata({ merchantId, queryKey, token }));
      }

      return {
        queryKey,
        queryFn: fetchMerchant,
      };
    }),

    merchantAppsV2: authenticatedQuery(queryClient)((token, merchantId) => {
      const queryKey = keys.merchantAppsV2(merchantId, String(token));

      function fetchSessionPermissions() {
        return apiClient.merchantAppsV2
          .find(merchantId)
          .catch(injectErrorMetadata({ merchantId, queryKey, token }));
      }

      return {
        queryKey,
        queryFn: fetchSessionPermissions,
      };
    }),

    merchantsSearch: authenticatedQuery(queryClient)((token) => {
      const queryKey = keys.merchantsSearch(String(token));

      function searchMerchant() {
        return apiClient.algolia
          .search({
            keyFilter: [apiClient.algolia.MERCHANTS],
            options: {
              attributesToRetrieve: ['name', 'objectID'],
            },
          })
          .catch(injectErrorMetadata({ queryKey, token }));
      }

      return {
        queryKey,
        queryFn: searchMerchant,
      };
    }),

    contractV2: {
      findAll: authenticatedQuery(queryClient)((token, organizationId) => {
        const queryKey = keys.contractV2.findAll(organizationId, String(token));

        function fetchContract() {
          return apiClient.contractV2
            .findAll({
              filter: { organization: organizationId },
              include: ['packages', 'fees'],
            })
            .catch(injectErrorMetadata({ queryKey, token, organizationId }));
        }

        return {
          queryKey,
          queryFn: fetchContract,
        };
      }),
    },
  };
}
/* eslint-enable max-classes-per-file  */
