/* eslint-disable import/prefer-default-export */
import { h } from 'preact';
import { Result, Button } from 'antd';
import { useNavigate } from 'react-router-dom';
import { t } from '../../utils/i18n';

export function PageAccessDenied() {
  const navigate = useNavigate();

  const handleOnBackToCalendarClick = () => {
    navigate(`/calendar`);
  };

  return (
    <div className="as-PageAccessDenied">
      <Result
        status="403"
        title={t('page-access-denied.title')}
        subTitle={t('page-access-denied.description')}
        extra={
          <Button type="primary" onClick={handleOnBackToCalendarClick}>
            {t('page-access-denied.button.back-to-calendar')}
          </Button>
        }
      />
    </div>
  );
}
