import * as browser from '../env/browser';
import createMemoryStorage from './memory-storage';

export const namespaceStorage = (namespace) => (storage) => {
  const ns = (key) => `${namespace}:${key}`;

  return {
    getItem(key) {
      return storage.getItem(ns(key));
    },
    setItem(key, value) {
      storage.setItem(ns(key), value);
    },
    removeItem(key) {
      storage.removeItem(ns(key));
    },

    // TODO: adapt for namespace usage
    key(num) {
      return storage.key(num);
    },
    clear() {
      storage.clear();
    },
    get length() {
      return storage.length;
    },
  };
};

const appShellStorageFrom = namespaceStorage('app-shell');

export const localStorage = appShellStorageFrom(browser.localStorage);
export const sessionStorage = appShellStorageFrom(browser.sessionStorage);
export const memoryStorage = appShellStorageFrom(createMemoryStorage());
