import { window } from '../env/browser';

function logErrorToConsole(error, context, extraTagsFromMicroApp = {}) {
  if (window.console) {
    window.console.error(error, context, extraTagsFromMicroApp);
  }
}

function logErrorToApi(
  Sentry,
  error,
  context = {},
  extraTagsFromMicroApp = {},
) {
  const extra = context.extra || {
    cause: error.cause && error.cause.toString(),
  };

  Sentry.withScope((scope) => {
    scope.setTag('extra', extra);

    Object.keys(extraTagsFromMicroApp).forEach((key) => {
      scope.setTag(key, extraTagsFromMicroApp[key]);
    });

    Object.keys(context).forEach((key) => {
      if (key !== 'extra') {
        scope.setExtra(key, context[key]);
      }
    });

    Sentry.captureException(error);
  });
}

export default function createErrorLogger(Sentry) {
  return {
    log: (error, context, extraTagsFromMicroApp) => {
      if (Sentry && Sentry.captureException) {
        logErrorToApi(Sentry, error, context, extraTagsFromMicroApp);
      } else {
        logErrorToConsole(error, context, extraTagsFromMicroApp);
      }
    },
    setContext: ({ email, id }) => {
      if (Sentry) {
        Sentry.configureScope((scope) => {
          scope.setUser({
            email,
            id,
          });
        });
      }
    },
  };
}
