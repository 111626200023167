import { useSelector, shallowEqual } from 'react-redux';
import { useState } from 'preact/hooks';
import { useEffect } from 'preact/compat';

import { getAppSettings } from '../selectors';

function useMerchantSettings() {
  const initialization = useSelector(
    (store) => store.initialization,
    shallowEqual,
  );
  const merchantSettings = useSelector(getAppSettings, shallowEqual);
  const [isLoading, toggleLoading] = useState(true);

  useEffect(() => {
    if (initialization.success) {
      toggleLoading(false);
    }
  }, [initialization]);

  if (isLoading) {
    return { isLoading, merchantSettings: null };
  }

  return {
    isLoading,
    merchantSettings,
  };
}

export default useMerchantSettings;
