import useMerchantSettings from '../../hooks/use-merchant-settings';
import useMicroServicesTools from '../../hooks/use-micro-services-tools';

function useServicesSettings() {
  const { isLoading, merchantSettings } = useMerchantSettings();
  const microServicesTools = useMicroServicesTools('services');

  if (isLoading) {
    return { isLoading, settings: null };
  }

  return {
    isLoading,
    settings: {
      microServicesTools,
      merchantSettings,
      locale: merchantSettings.locale,
    },
  };
}

export default useServicesSettings;
