import { h } from 'preact';
import cn from 'classnames';
import { t } from '../../utils/i18n';

export default function DunningWarning({ stage }) {
  const stageTranslationKey = stage.replace(/_/g, '-');
  return (
    <div
      className={cn('as-DunningWarning', {
        'is-danger': stage === 'final_call',
      })}
    >
      <shore-icon name="request-status" className="warning-icon" />
      <span>
        {t(
          `dunning-warning.${stageTranslationKey}-message.text-before-email-link`,
        )}
      </span>
      {/* eslint-disable-next-line jsx-a11y/anchor-has-content */}
      <a href="mailto:service@shore.com">service@shore.com</a>
      <span>
        {t(
          `dunning-warning.${stageTranslationKey}-message.text-after-email-link`,
        )}
      </span>
    </div>
  );
}
