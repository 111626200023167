import { h } from 'preact';
import { useParams } from 'react-router-dom';

import MicroApp from '../../components/MicroApp';
import useServicesSettings from './use-services-settings';

export function EditCategory() {
  const { isLoading, settings } = useServicesSettings();
  const { categoryId } = useParams();

  if (isLoading) {
    return null;
  }

  return (
    <MicroApp
      name="services/EditCategory"
      settings={settings}
      categoryId={categoryId}
    />
  );
}
