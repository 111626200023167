import { h } from 'preact';
import cn from 'classnames';
import { ErrorModal } from './ErrorModal';
import { DowntimeModal } from './DowntimeModal';

function Error({
  title = 'Unknown Error',
  message,
  isVisible = true,
  isDismissable = false,
  isDowntime,
  onDismiss = Function.prototype,
  actionButton,
}) {
  return (
    <div class={cn('as-Error', { 'is-visible': isVisible })}>
      {isDowntime ? (
        <DowntimeModal
          message={message}
          title={title}
          actionButton={actionButton}
        />
      ) : (
        <ErrorModal
          title={title}
          message={message}
          isDismissable={isDismissable}
          onDismiss={onDismiss}
          actionButton={actionButton}
        />
      )}
    </div>
  );
}

export default Error;
