import createDebug from '../utils/debug';
import schema from './schema';
import { isObject } from '../utils/validators';

const debug = createDebug('manifest:validator');

export default function validateAppManifest(manifest) {
  if (!isObject(manifest)) return false;

  return Object.keys(schema).every((key) => {
    if (!(key in manifest)) {
      return !schema[key].required;
    }

    return schema[key].validators.every((validator) => {
      const isValid = validator(manifest[key]);

      if (!isValid) {
        debug(
          `prop ${key} invalid for ${validator.name || validator.toString()}`,
        );
      }

      return isValid;
    });
  });
}
