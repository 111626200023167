import getGlobalValue from '../utils/get-global-value';

const {
  API_BASE_URL,
  API_GATEWAY,
  AUTH_BASE_URL,
  LEGACY_API_BASE_URL,
  API_ENV,
  APP_MANIFEST_URL_TEMPLATE,
  BEAMER_ID,
  BOOKING_BASE_URL,
  BUILD_DATE,
  CDN_BASE_URL,
  CDN_HOST,
  COMPANY_BASE_URL,
  COMPANY_DOMAIN,
  COMPANY_NAME,
  DELIGHTED,
  FSS_BASE_URL,
  GIT_REV,
  GOOGLE_TAG_MANAGER_ID,
  GRAPHQL_BASE_URL,
  HUBSPOT_ID,
  INSTAGRAM_BOOKING_BASE_URL,
  LOGO_ORIENTATION,
  NODE_ENV,
  REALTIME_APP_KEY,
  REALTIME_HOST,
  SEGMENT_KEY,
  SENTRY_DSN,
  SETUP_STEPS,
  SUPPORT_EMAIL,
  USERLANE_APP_ID,
  WHITE_LABEL,
  CELLO_PRODUCT_ID,
  INTERCOM_APP_ID,
} = getGlobalValue('Shore.config') || {};

export const NOOP = Function.prototype;
export const FALLBACK_LANGUAGES = ['en', 'zu'];
export const START_PATH = '/calendar/week';
export const NOTIFICATION_ELEMENT_ID = 'notification-bell-9821313';
export const CHECK_FOR_UPDATES_INTERVAL = 5 * 60 * 1000; // 5min in msec
export const CHECK_FOR_NOTIFICATION_COUNT_INTERVAL = 60 * 1000; // 1min in msec
export const REDUNDANT_DEPENDENCIES = [
  'i18next',
  'moment.js',
  'shore-javascript-client',
  'shore-components',

  // TODO: remove when Apps removed this dependency
  // This is the old shore-web-components project, which was included as
  // an external dependency in every App. But now we don't want to load
  // this dependency anymore, because we load the new version via App Shell.
  'shore-web-components',
];

// day (in msec);
export const APP_MANIFEST_CACHE_EXPIRES_IN = 1000 * 60 * 60 * 24;

// ENV vars
export {
  API_BASE_URL,
  API_GATEWAY,
  AUTH_BASE_URL,
  LEGACY_API_BASE_URL,
  API_ENV,
  APP_MANIFEST_URL_TEMPLATE,
  BEAMER_ID,
  BOOKING_BASE_URL,
  BUILD_DATE,
  CDN_BASE_URL,
  CDN_HOST,
  COMPANY_BASE_URL,
  COMPANY_DOMAIN,
  COMPANY_NAME,
  DELIGHTED,
  FSS_BASE_URL,
  GIT_REV,
  GOOGLE_TAG_MANAGER_ID,
  GRAPHQL_BASE_URL,
  HUBSPOT_ID,
  INSTAGRAM_BOOKING_BASE_URL,
  LOGO_ORIENTATION,
  NODE_ENV,
  REALTIME_APP_KEY,
  REALTIME_HOST,
  SEGMENT_KEY,
  SENTRY_DSN,
  SETUP_STEPS,
  SUPPORT_EMAIL,
  USERLANE_APP_ID,
  WHITE_LABEL,
  CELLO_PRODUCT_ID,
  INTERCOM_APP_ID,
};

export const IS_DOWNTIME = process.env.IS_DOWNTIME === 'true';

// Segment default event type
export const TRACK_IDENTIFY = 'track:identify';
export const TRACK_PAGE = 'track:page';

export const MODAL_ELEMENT_ID = 'as-modal-id';

export const FREE_PRODUCT_ALLOWED_APP_NAMES = [
  'wrapper',
  'launchpad',
  'location',
  'calendar',
  'dashboard',
  'hr',
  'services',
  'settings',
  'application-settings',
  'appointment',
  'search',
  'contract',
  'user-profile',
];

export const DEPRECATED_BROWSERS = [{ name: 'ie', version: 11 }];
export const SEVERITY = {
  NONE: 'NONE',
  WARNING: 'WARNING',
  DANGER: 'DANGER',
  LABEL: 'sms_info_severity',
};

export const DATADOG_SITE = 'datadoghq.eu';

export const SUBSCRIPTION_STATUS = {
  ACTIVE: 'active',
  TRIAL: 'in_trial',
  CANCELLED: 'cancelled',
  PAUSED: 'paused',
};
