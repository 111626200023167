import { h } from 'preact';
import { useQuery, useQueries, useQueryClient } from 'react-query';
import { useEffect, useRef, useState, useCallback } from 'preact/compat';

import { t } from '../../utils/i18n';
import { LOGO_ORIENTATION, BEAMER_ID, SEVERITY, NOOP } from '../../constants';
import { ErrorBoundary } from '../ErrorBoundary';
import MicroApp from '../MicroApp';
import IconButton from '../IconButton';
import Button from '../Button';
import Profile from '../Profile';
import SearchWrapper from '../SearchWrapper';
import SMSInfoMessage from '../SMSInfoMessage';
import TrialMessage from '../TrialMessage';
import Notifications from '../../containers/Notifications';
import Tracer from '../../utils/tracer';
import EVENTS from '../../constants/event-bus-events';
import createQueryConfig from '../../queries';
import Logger from '../../utils/logger';

const getLogoDimensions = () => {
  switch (LOGO_ORIENTATION) {
    case 'LANDSCAPE':
      return { width: 'auto', height: '24px' };
    case 'SQUARE':
    default:
      return { width: '42px', height: '42px' };
  }
};

function launchpadErrorFallback(error, info) {
  Logger.error('MicroApp Error: Uncaught exception at Launchpad', {
    raisedError: {
      name: error.name,
      message: error.message,
      componentStack: info.componentStack,
    },
  });
}

function Launchpad({ onAction, apiClient, settings }) {
  const queryClient = useQueryClient();
  const queryConfig = createQueryConfig(queryClient, apiClient);

  const queries = useQueries([
    queryConfig.merchantAccountV2(),
    queryConfig.merchantV2(settings.merchantId),
    queryConfig.merchantsSearch(),
    queryConfig.authToken(),
  ]);

  const [
    { data: merchantAccountData },
    { data: merchantData },
    { data: merchantsSearchData },
    { data: authToken },
  ] = queries;

  const contractQuery = useQuery({
    ...queryConfig.contractV2.findAll(merchantAccountData?.organization.id),
    enabled: !!merchantAccountData,
  });

  const { data: contractData } = contractQuery;
  const allQueries = [...queries, contractQuery];

  if (allQueries.some(({ isLoading }) => isLoading)) {
    return null;
  }

  const errors = allQueries.filter(({ status }) => status === 'error');

  errors.forEach(({ error, data }) => {
    Logger.error('MicroApp Error: Failed to load Launchpad data', {
      name: error.name,
      message: error.message,
      metadata: error.metadata,
      isDataEmpty: data === undefined,
    });
  });

  if (errors.length > 0 && allQueries.some(({ data }) => data === undefined)) {
    return null;
  }

  return (
    <MicroApp
      name="launchpad"
      settings={settings}
      apiClient={apiClient}
      onAction={onAction}
      merchantAccount={merchantAccountData}
      merchant={merchantData}
      contract={contractData}
      merchantsSearch={merchantsSearchData}
      authToken={authToken}
    />
  );
}

function Menu({ onClick, settings, onAction, apiClient, eventBus }) {
  const [isMenuVisible, toggleVisibility] = useState(false);
  const overlayHref = useRef(null);

  useEffect(() => {
    function closeMenu() {
      toggleVisibility(false);
    }

    eventBus.on(EVENTS.CLOSE_MENU, closeMenu);

    return () => eventBus.off(EVENTS.CLOSE_MENU, closeMenu);
  }, []);

  function toggleMenu() {
    toggleVisibility(!isMenuVisible);
  }

  const handleClick = useCallback(() => {
    onClick();
    toggleMenu();
  }, [isMenuVisible]);

  function handleOverlayClick(e) {
    if (e.target === overlayHref.current) {
      handleClick();
    }
  }

  return (
    <div>
      <IconButton
        data-testid="shore-main-menu"
        data-dd-action-name="Main Menu"
        inverted
        title={t('button.launchpad.title')}
        href={null}
        class="is-active as-Header-mainMenu"
        iconName="main-menu"
        onClick={handleClick}
      />

      {isMenuVisible ? (
        <div
          ref={overlayHref}
          data-testid="shore-main-menu-overlay"
          onKeyDown={NOOP}
          onClick={handleOverlayClick}
          className="as-Header-launchpadOverlay"
        >
          <ErrorBoundary
            fallback={launchpadErrorFallback}
            fallbackComponent={null}
          >
            <Launchpad
              settings={settings}
              apiClient={apiClient}
              onAction={onAction}
            />
          </ErrorBoundary>
        </div>
      ) : null}
    </div>
  );
}

function Header({
  logoUrl,
  onSearchActivation,
  onClickButton,
  onClickSMSClose,
  merchant,
  account,
  onClickLogout,
  company,
  uiVisibility,
  severity,
  isAdmin,
  whiteLabelSchemaless,
  locale,
  settings,
  onAction,
  apiClient,
  eventBus,
}) {
  const onButtonClick = useCallback((event) => {
    onClickButton('menu', event);
    Tracer.addAction('menu_clicked');
  }, []);

  const handleClickButton = (name) => (event) => {
    onClickButton(name, event);
  };

  const getHelpCenterLink = ({ config, tmpLocale, companyName }) => {
    switch (companyName) {
      case 'shore':
        return config && config.helpcenter && config.helpcenter.home
          ? config.helpcenter.home.replace(
              '__locale__',
              tmpLocale.toLowerCase(),
            )
          : '';

      case 'localsearch':
        return 'https://cc.localsearch.ch/s/help?language=de&topic=Product_related_questions_NV&subtopic=MyCOCKPIT_booking_system_NV';
      default:
        return null;
    }
  };

  // eslint-disable-next-line no-shadow
  const smsDisplayInfo = (clickEvent, severityType, isAdmin) => {
    switch (severityType) {
      case SEVERITY.DANGER:
        return (
          <SMSInfoMessage
            onCloseClicked={clickEvent}
            isHiddenSm
            status={SEVERITY.DANGER}
            isAdmin={isAdmin}
          />
        );

      case SEVERITY.WARNING:
        return (
          <SMSInfoMessage
            onCloseClicked={clickEvent}
            isHiddenSm
            status={SEVERITY.WARNING}
            isAdmin={isAdmin}
          />
        );
      case SEVERITY.NONE:
        return null;

      default:
        return null;
    }
  };

  return (
    <header class="as-Header">
      <div class="as-Header-top">
        <Menu
          onClick={onButtonClick}
          settings={settings}
          onAction={onAction}
          apiClient={apiClient}
          eventBus={eventBus}
        />
        <Button
          data-testid="shore-home-button"
          title={t('button.calendar.title')}
          href="/calendar"
          class="as-Header-logoButton"
          onClick={handleClickButton('calendar')}
        >
          {logoUrl && (
            <img
              alt="Logo"
              style={getLogoDimensions()}
              src={logoUrl}
              class="as-Header-logo"
            />
          )}
        </Button>
        <div class="as-Header-searchBox">
          <SearchWrapper
            onActivate={onSearchActivation}
            title={t('button.search.title')}
            placeholder={t('button.search.placeholder')}
          />
        </div>
        {merchant.id && (
          <TrialMessage
            merchantId={merchant.id}
            trialDaysLeft={merchant.trialDaysLeft}
            isHiddenSm
          />
        )}
        {smsDisplayInfo(onClickSMSClose, severity, isAdmin)}
        <Notifications shouldShowProductNotification={Boolean(BEAMER_ID)} />
        <Profile
          {...{
            account,
            merchant,
            onClickButton,
            onClickLogout,
            company,
            uiVisibility,
            helpcenterLink: getHelpCenterLink({
              config: whiteLabelSchemaless,
              tmpLocale: locale,
              companyName: company,
            }),
          }}
        />
      </div>
      <div class="as-Header-bottom">
        {merchant.id && (
          <TrialMessage
            merchantId={merchant.id}
            trialDaysLeft={merchant.trialDaysLeft}
            isVisibleSm
          />
        )}
      </div>
    </header>
  );
}

export default Header;
