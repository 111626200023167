/* eslint-disable import/prefer-default-export */
import { h } from 'preact';
import SettingIcon from './SettingIcon';

export function ErrorModal({
  title = 'Unknown Error',
  message,
  isDismissable = false,
  onDismiss = Function.prototype,
  actionButton,
}) {
  return (
    <div class="as-Error-body">
      {isDismissable && (
        <button
          type="button"
          class="as-Error-dismissButton"
          onClick={onDismiss}
        >
          <shore-icon name="delete" />
        </button>
      )}
      <h2 class="as-Error-title">{title}</h2>
      {
        /* eslint-disable  react/no-danger */
        <p
          class="as-Error-message"
          dangerouslySetInnerHTML={{ __html: message }}
        />
        /* eslint-enable */
      }

      <div className="as-Error-icon">
        <SettingIcon />
      </div>
      {actionButton && (
        <a href={actionButton.href} class="as-Error-actionButton">
          {actionButton.title}
        </a>
      )}
    </div>
  );
}
