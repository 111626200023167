import { h, createContext } from 'preact';
import { useContext } from 'preact/hooks';
import { Provider } from 'react-redux';
import { QueryClientProvider } from 'react-query';
import { ConfigProvider } from 'antd';

import { antdTheme } from './styles/antd-theme';

export const GlobalsContext = createContext(null);

export function useGlobals() {
  const tools = useContext(GlobalsContext);

  return tools;
}

export function GlobalsProvider({ store, tools, children }) {
  const { queryClient } = tools;

  return (
    <GlobalsContext.Provider value={tools}>
      <Provider store={store}>
        <QueryClientProvider client={queryClient}>
          <ConfigProvider prefixCls="ant-v5" theme={antdTheme}>
            {children}
          </ConfigProvider>
        </QueryClientProvider>
      </Provider>
    </GlobalsContext.Provider>
  );
}
