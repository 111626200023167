import { fetch } from '../env/browser';

export async function fetchJson(url, options = {}) {
  const finalOptions = {
    method: 'GET',
    ...options,
    headers: {
      accept: 'application/json',
      'content-type': 'application/json',
      ...options.headers,
    },
  };
  const response = await fetch(url, finalOptions);
  const json = await response.json();
  const lastModified = response.headers.get('last-modified');

  return { json, lastModified };
}

export const fetchHeaders = (url, names = []) =>
  fetch(url, { method: 'HEAD' }).then((res) =>
    names.map((name) => res.headers.get(name)),
  );
