/* eslint-disable import/prefer-default-export */
import { h } from 'preact';
import { Layout } from 'antd';
import Header from '../../containers/Header';
import ActionPanel from '../../containers/ActionPanel';

const { Content: AntdContent, Header: AntdHeader } = Layout;

export function PageLayout({ children, trackMethod, apiClient, eventBus }) {
  return (
    <Layout className="as-PageLayout">
      <AntdHeader className="as-PageHeader">
        <ActionPanel />
        <Header
          trackMethod={trackMethod}
          apiClient={apiClient}
          eventBus={eventBus}
        />
      </AntdHeader>
      <AntdContent className="as-PageContent">{children}</AntdContent>
    </Layout>
  );
}
