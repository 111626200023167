import moment from 'moment';
import {
  API_ENV,
  API_BASE_URL,
  COMPANY_NAME,
  COMPANY_BASE_URL,
  BOOKING_BASE_URL,
  INSTAGRAM_BOOKING_BASE_URL,
  FSS_BASE_URL,
} from '../constants';
import {
  UnauthorizedError,
  UnconfirmedEmailError,
} from '../utils/custom-errors';

export const getMerchantId = (state) => state.currentMerchant.id;

export const isFreeProduct = (state) =>
  !!state.currentMerchant.featureToggles.free_product;

export const isInitializeErrored = (state) => !!state.initialization.error;

export const getAppSettings = (state) => ({
  organizationId: state.currentAccount.organizationId,
  whiteLabel: state.currentMerchant.whiteLabel,
  merchantAccountId: state.currentAccount.id,
  merchantId: state.currentMerchant.id,
  locale: state.currentAccount.locale,
  whiteLabelConfiguration: state.whiteLabel.config,
  company: COMPANY_NAME,
  companyBaseUrl: COMPANY_BASE_URL,
  apiEnv: API_ENV,
  apiBaseUrl: API_BASE_URL,
  fssBaseUrl: FSS_BASE_URL,
  bookingBaseUrl: BOOKING_BASE_URL,
  instagramBookingBaseUrl: INSTAGRAM_BOOKING_BASE_URL,
  isSelfSignUp: state.currentMerchant.isSelfSignUp,
  trialEndsOn: state.currentMerchant.trialEndsOn,
  trialDaysLeft:
    state.currentMerchant.trialDaysLeft === null
      ? 0
      : state.currentMerchant.trialDaysLeft,
  isFreeProduct: isFreeProduct(state),
  packageCode: state.currentMerchant.packageCode,
});

export const isTrialPeriodExpired = ({ currentMerchant }) =>
  currentMerchant.trialDaysLeft === 0;

export const getFeatureToggles = (state) =>
  state.currentMerchant.featureToggles;

export const getUiVisibility = (state) => state.currentMerchant.uiVisibility;

export const hasAppError = (state) => !!state.currentApp.error;

export const getAppErrorMessage = (state) =>
  hasAppError(state) ? state.currentApp.error.message : '';

export const hasOverlayError = (state) => !!state.currentOverlay.error;

export const getOverlayErrorMessage = (state) =>
  hasOverlayError(state) ? state.currentOverlay.error.message : '';

export const hasInitializationError = (state) => !!state.initialization.error;

export const getInitializationErrorName = (state) =>
  hasInitializationError(state) ? state.initialization.error.name : '';

export const getInitializationErrorMessage = (state) =>
  hasInitializationError(state) ? state.initialization.error.message : '';

export const hasUnauthorizedError = (state) =>
  hasInitializationError(state) &&
  state.initialization.error.name === UnauthorizedError.name;

export const hasUnconfirmedEmailError = (state) =>
  hasInitializationError(state) &&
  state.initialization.error.name === UnconfirmedEmailError.name;

export const overlayIsOpen = (state) => !!state.currentOverlay.name;

export const shouldBlur = (state) => {
  const {
    currentOverlay: { name = '' },
  } = state;
  return overlayIsOpen(state) && name !== 'launchpad';
};

export const getUnreadAppointmentCount = (state) =>
  state.notifications.appointmentCount;

export const getMessageCount = (state) => state.notifications.messageCount;

export const getShowAppointments = (state) => state.notifications.ui.open;

export const getNotificationAppointments = (state) =>
  state.notifications.appointments;

export const getFirstUnreadClientMessage = (state) =>
  state.notifications.clientMessages.find((m) => m.closed === false);

export const notificationsPopupIsOpen = (state) => state.notifications.ui.open;

export const notificationIsLoaded = (state) => state.notifications.ui.loaded;

export const getTaskProgress = (state) => {
  if (!state.currentMerchant.setupTodo) return { completed: 0, total: 0 };
  const { currentMerchant: { setupTodo: { items = [] } = {} } = {} } = state;
  const completed = items.filter(({ status }) => status === 'COMPLETED').length;
  const total = items.length;
  return { completed, total };
};

export const hasUserJustLoggedIn = (state) => {
  const tenSeconds = 10000;
  const timeSinceLastLogin = moment().diff(
    state.currentAccount.currentSigninAt,
  );
  return timeSinceLastLogin < tenSeconds;
};

export const getSessionPermissions = (state) => state.sessionPermissions;
