import escapeStrRegexp from 'escape-string-regexp';

export function matchesType(fx, action) {
  // when fx type string contains * treat it like a glob pattern
  if (fx.type.includes('*')) {
    const escapedFxType = escapeStrRegexp(fx.type);
    const fxTypeStrRegexp = escapedFxType.replace(/\\\*/g, '.*?');
    const fxTypeRegexp = new RegExp(fxTypeStrRegexp);

    return fxTypeRegexp.test(action.type);
  }

  // otherwise test for strict equality
  return fx.type === action.type;
}

export function createFxMiddleware(fxList, deps = {}) {
  return ({ dispatch, getState }) =>
    (next) =>
    (action) => {
      const previousState = getState();
      const result = next(action);
      const relatedFxList = fxList.filter((fx) => matchesType(fx, action));
      relatedFxList.forEach((fx) =>
        fx.process({ action, dispatch, previousState, getState, deps }),
      );
      return result;
    };
}

export function createFx(actionType, process) {
  if (typeof process !== 'function') {
    throw new Error('param process is not a function');
  }

  return {
    type: actionType.toString(),
    process,
  };
}
