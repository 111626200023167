import { Component, h } from 'preact';

const makeStatic = (NonStaticComponent) => {
  class StaticComponent extends Component {
    shouldComponentUpdate() {
      return false;
    }

    render(props) {
      // eslint-disable-next-line react/jsx-props-no-spreading
      return <NonStaticComponent {...props} />;
    }
  }

  const wrappedComponentName = NonStaticComponent.name || 'Component';

  StaticComponent.WrappedComponent = NonStaticComponent;
  StaticComponent.displayName = `Static(${wrappedComponentName})`;

  return StaticComponent;
};

export default makeStatic;
