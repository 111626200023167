import { registerTranslations } from '../utils/translations';
import loadAppManifest from './manifest-loader';
import loadAppAssets from './assets-loader';

export default (async function loadApp({
  name,
  container,
  modal,
  useHashHistory,
  routingType,
  pathAndQuery,
  apiClient,
  trackMethod,
  onAction,
  eventBus,
  settings, // see example in ../../README.md
  logError,
} = {}) {
  const manifest = await loadAppManifest(name, eventBus);
  const options = {
    ...manifest.additionalCreateOptions,
    container,
    modal,
    useHashHistory,
    routingType,
    pathAndQuery,
    apiClient,
    trackMethod,
    onAction,
    settings,
    mountPath: `/${name}`,
    logError,
    eventBus,
  };
  const { App, translationDataList } = await loadAppAssets(
    name,
    manifest,
    settings.locale,
  );

  translationDataList.forEach(({ namespace, data }) => {
    registerTranslations(namespace, data);
  });

  return {
    manifest,
    create: () => App.create(options),
  };
});
