import { compose } from 'redux';
import _moment from 'moment';
import createDebug from '../utils/debug';

const debug = createDebug('initializers:moment');

function fixDefaultEnLocale(locale) {
  return locale === 'en' ? 'en-gb' : locale;
}

function fixNorwegianLocale(locale) {
  return locale === 'nn' ? 'nb' : locale;
}

export default function initMomentJs(lng = 'en', moment = _moment) {
  // TODO: use moment.min.js instead of moment-with-locales.min.js and
  //       just load requested language file
  return new Promise((resolve, reject) => {
    // `en` locale in momentjs by default is `en-us`
    // but in app-shell default locale is `en ` is `en-gb`
    const locale = compose(
      fixNorwegianLocale,
      fixDefaultEnLocale,
    )(lng.toLowerCase());
    try {
      moment.locale(locale);
    } catch (error) {
      debug(error);
      reject(new Error('Cannot initialize moment.js'));
    }
    resolve(moment);
  });
}
