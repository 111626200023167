/* global window */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { h } from 'preact';

const onClickOutside = (el, callback) => (event) => {
  if (!el.contains(event.target)) {
    window.document.removeEventListener('click', onClickOutside);
    callback();
  }
};

const tryToFocusSearch = () => {
  const searchInput = window.document.getElementById('searchInput');
  return searchInput && searchInput.focus();
};

/* eslint-disable jsx-a11y/no-static-element-interactions */
export default function SearchWrapper({ onActivate, placeholder, title }) {
  const onClick = () => {
    const activeClass = 'as-SearchWrapper-active';
    const searchWrapper = window.document.getElementById('searchWrapper');
    window.document.addEventListener(
      'click',
      onClickOutside(searchWrapper, () =>
        searchWrapper.classList.remove(activeClass),
      ),
    );
    searchWrapper.classList.add(activeClass);
    onActivate();
    tryToFocusSearch();
  };

  return (
    <div
      id="searchWrapper"
      class="as-SearchWrapper"
      onClick={onClick}
      role="button"
      tabIndex={0}
    >
      <div class="as-SearchWrapper-appContainer">
        <div class="as-SearchWrapper-placeholderWrapper">
          <input
            class="as-SearchWrapper-placeholder"
            placeholder={placeholder}
          />
        </div>
        {/* Input field is been created by the search-app: src/components/Search/SearchBar */}
        <div class="as-SearchWrapper-searchInput" />
        <span class="as-SearchWrapper-trigger" title={title}>
          <shore-icon name="search" />
        </span>
      </div>
    </div>
  );
}
/* eslint-enable jsx-a11y/click-events-have-key-events */
