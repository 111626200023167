import FeatureRollout from '../utils/feature-rollout';
import createDebug from '../utils/debug';
import validate from './validator';
import EVENT_BUS_EVENTS from '../constants/event-bus-events';
import { fetchJson } from '../utils/fetch';
import { getAppManifestUrl } from '../utils/url';
import { WrappedError } from '../utils/custom-errors';
import { memoryStorage } from '../utils/namespaced-storage';
import { getCachedManifest, cacheManifest } from './manifest-cache';
import { APP_MANIFEST_CACHE_EXPIRES_IN } from '../constants';

const debug = createDebug('manifest:loader');

async function fetchAndCacheManifest(appName, eventBus) {
  // fetch manifest
  let manifest;
  try {
    let manifestDownloadUrl = getAppManifestUrl(appName);

    if (
      FeatureRollout.isActive('services-app-refactor') &&
      appName === 'services'
    ) {
      manifestDownloadUrl =
        'https://assets-cdn.shore.com/services-app/SD-4581-refactor-to-new-app-shell-architecture/current/app-manifest.json';
    }

    const { json, lastModified } = await fetchJson(manifestDownloadUrl);
    manifest = { ...json, lastModified };
    debug('fetched', appName, manifest);
  } catch (error) {
    if (eventBus) {
      eventBus.emit(EVENT_BUS_EVENTS.NAVIGATE, { payload: { route: '/404' } });
    }

    throw new WrappedError(
      `App manifest of ${appName} couldn't be loaded`,
      error,
    );
  }

  // validate fetched manifest
  if (!validate(manifest)) {
    throw new Error(`App manifest of ${appName} is invalid`);
  }

  // cache manifest
  cacheManifest(appName, manifest, memoryStorage);

  return manifest;
}

export default (async function loadAppManifest(appName, eventBus) {
  // return cached manifest if available & valid
  const cachedManifest = getCachedManifest(
    appName,
    memoryStorage,
    APP_MANIFEST_CACHE_EXPIRES_IN,
  );
  if (cachedManifest && validate(cachedManifest)) {
    // try to update manifest cache for later calls (async)
    fetchAndCacheManifest(appName, eventBus).catch((err) => {
      debug('error updating manifest cache', err);
    });

    return cachedManifest;
  }

  // fetch and cache manifest
  const manifest = await fetchAndCacheManifest(appName, eventBus);
  return manifest;
});
