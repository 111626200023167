import { h } from 'preact';
import { useEffect, useRef } from 'preact/compat';

import { window } from '../src/env/browser';
import { MICRO_APP_URL } from './constants';

window.Shore.microService = {};

const style = {
  height: '100%',
  width: '100%',
};

function DevMicroApp({ serviceName, componentName, settings, props = {} }) {
  const iframeRef = useRef(null);

  useEffect(() => {
    window.Shore.microService[serviceName] =
      window.Shore.microService[serviceName] || {};
    window.Shore.microService[serviceName].settings = settings;

    function render() {
      window.Shore.microService[serviceName].render(componentName, props);
    }

    if (window.Shore.microService[serviceName]?.render) {
      render();
    }

    function setupService(event) {
      const message = event.data;

      if (message?.type === 'APP_SUCCESSFULLY_CONFIGURED') {
        render();
      }
    }

    window.addEventListener('message', setupService);

    return () => {
      window.removeEventListener('message', setupService);
    };
  }, [componentName, props]);

  return (
    <iframe
      title="MicroApp"
      ref={iframeRef}
      style={style}
      src={MICRO_APP_URL}
    />
  );
}

export default DevMicroApp;
