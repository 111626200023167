import { DEPRECATED_BROWSERS } from '../constants';

const buildRules = (ruleTuples) =>
  ruleTuples.map((tuple) => ({
    name: tuple[0],
    rule: tuple[1],
  }));

const getBrowserRules = () =>
  buildRules([
    ['aol', /AOLShield\/([0-9._]+)/],
    ['edge', /Edge\/([0-9._]+)/],
    ['yandexbrowser', /YaBrowser\/([0-9._]+)/],
    ['vivaldi', /Vivaldi\/([0-9.]+)/],
    ['kakaotalk', /KAKAOTALK\s([0-9.]+)/],
    ['samsung', /SamsungBrowser\/([0-9.]+)/],
    ['chrome', /(?!Chrom.*OPR)Chrom(?:e|ium)\/([0-9.]+)(:?\s|$)/],
    ['phantomjs', /PhantomJS\/([0-9.]+)(:?\s|$)/],
    ['crios', /CriOS\/([0-9.]+)(:?\s|$)/],
    ['firefox', /Firefox\/([0-9.]+)(?:\s|$)/],
    ['fxios', /FxiOS\/([0-9.]+)/],
    ['opera', /Opera\/([0-9.]+)(?:\s|$)/],
    ['opera', /OPR\/([0-9.]+)(:?\s|$)$/],
    ['ie', /Trident\/7.0.*rv:([0-9.]+).*\).*Gecko$/],
    ['ie', /MSIE\s([0-9.]+);.*Trident\/[4-7].0/],
    ['ie', /MSIE\s(7.0)/],
    ['bb10', /BB10;\sTouch.*Version\/([0-9.]+)/],
    ['android', /Android\s([0-9.]+)/],
    ['ios', /Version\/([0-9._]+).*Mobile.*Safari.*/],
    ['safari', /Version\/([0-9._]+).*Safari/],
    ['facebook', /FBAV\/([0-9.]+)/],
    ['instagram', /Instagram\s([0-9.]+)/],
    ['ios-webview', /AppleWebKit\/([0-9.]+).*Mobile/],
  ]);

const parseUserAgent = (userAgentString) => {
  const browsers = getBrowserRules();
  if (!userAgentString) {
    return null;
  }

  return (
    browsers
      .map((browser) => {
        const match = browser.rule.exec(userAgentString);
        let version = match && match[1].split(/[._]/).slice(0, 3);

        if (version && version.length < 3) {
          version = version.concat(version.length === 1 ? [0, 0] : [0]);
        }

        return (
          match && {
            name: browser.name,
            version: version.join('.'),
          }
        );
      })
      .filter(Boolean)[0] || null
  );
};

export const detectBrowser = () => {
  if (typeof navigator !== 'undefined') {
    // eslint-disable-next-line no-undef
    return parseUserAgent(navigator.userAgent);
  }

  throw new Error('Navigator was not found in global object');
};

export const isBrowserDeprecated = () => {
  const currentBrowser = detectBrowser();
  return !!DEPRECATED_BROWSERS.find(
    (deprecatedBrowser) =>
      currentBrowser &&
      deprecatedBrowser.name === currentBrowser.name &&
      parseFloat(currentBrowser.version) <=
        parseFloat(deprecatedBrowser.version),
  );
};
