import createDebug from '../utils/debug';

const debug = createDebug('manifest:cache');
const getCacheKey = (appName) => `app-manifest:${appName}`;

export function getCachedManifest(appName, storage, expiresIn) {
  const cachedManifestData = storage.getItem(getCacheKey(appName));

  // exit if no cached manifest available
  if (!cachedManifestData) return null;

  // parse cached manifest data
  let createdAt;
  let manifest;
  try {
    ({ createdAt, manifest } = JSON.parse(cachedManifestData));
  } catch (err) {
    return null; // exit on error
  }

  // exit on invalid data
  if (typeof createdAt !== 'number' || typeof manifest !== 'object') {
    return null;
  }

  // do not return expired manifest
  if (Date.now() - createdAt > expiresIn) {
    return null;
  }

  // return cached manifest
  debug('use cached manifest', appName, manifest);
  return manifest;
}

export function cacheManifest(appName, manifest, storage) {
  const data = JSON.stringify({ createdAt: Date.now(), manifest });
  storage.setItem(getCacheKey(appName), data);
  debug('cache manifest', appName, manifest);
}
