import cn from 'classnames';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { proxyAppAction, appError, reloadAppShell } from '../../actions';
import { getAppSettings } from '../../selectors';
import AppLoader from '../../components/AppLoader';

const pickCreationParams = (props) => {
  const propsToSkip = [
    'apiClient',
    'children',
    'errorLogger',
    'instance',
    'loadApp',
    'loading',
    'name',
    'onInstance',
    'trackMethod',
    'visible',
  ];
  return Object.keys(props)
    .filter((key) => !propsToSkip.includes(key))
    .reduce((acc, key) => ({ ...acc, [key]: props[key] }), {});
};

const mapStateToProps = (state, props) => ({
  modal: pickCreationParams(props),
  settings: getAppSettings(state),
  name: props.name || state.currentModalApp.name,
  class: cn('as-ModalApp', {
    'is-visible': state.currentModalApp.visible,
  }),
});

const mapDispatchToProps = (dispatch, { onProxyAction }) =>
  bindActionCreators(
    {
      onAppAction: proxyAppAction(onProxyAction),
      onError: appError,
      onDocumentOutdated: reloadAppShell,
    },
    (action) => action && dispatch(action),
  );

class ModalApp extends AppLoader {}

export default connect(mapStateToProps, mapDispatchToProps)(ModalApp);
