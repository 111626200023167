import { connect } from 'react-redux';
import Main from '../../components/Main';
import {
  clickModalBackground,
  modalAppLoaded,
  closeModal,
  changeLocation,
} from '../../actions';

const mapStateToProps = (state) => ({
  sidebarShown: !!state.currentSidebar.name && !state.currentSidebar.isHidden,
  modalOpen: !!state.currentModal,
  currentModalApp: state.currentModalApp,
});

const mapDispatchToProps = {
  onModalBackgroundClick: clickModalBackground,
  onModalCloseClick: closeModal,
  onModalAppLoaded: modalAppLoaded,
  changeLocation,
};

export default connect(mapStateToProps, mapDispatchToProps)(Main);
