import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { setIntercomReady, setHubspotReady } from '../../actions';
import {
  DELIGHTED,
  COMPANY_NAME,
  HUBSPOT_ID,
  INTERCOM_APP_ID,
} from '../../constants';
import { shouldActivateUserlane } from '../../selectors/widgetsVisibility';
import Widgets from '../../components/Widgets';
import { location } from '../../env/browser';

const PAYMENT_METHODS = ['card', 'sepa_debit', 'sepa_credit_transfer'];
const getSegmentedPaymentSource = (segment, account) =>
  PAYMENT_METHODS.includes(account.contractDetails.defaultPaymentSourceType)
    ? [segment, `${segment}-ProductInvoice`]
    : [segment];
const accountPackageMatches = (packageName) => (account) =>
  account.contractDetails.packages.includes(packageName);
const isPlusPackageAccount = accountPackageMatches('shore_plus');
const isBasicPackageAccount = accountPackageMatches('shore_basic');

const getGenericBeamerSegments = (account) => {
  const segmentsMap = {
    'Plus-Users': isPlusPackageAccount,
    'Basic-Users': isBasicPackageAccount,
    'Test-Accounts': ({ isTestAccount }) => isTestAccount,
  };
  return Object.keys(segmentsMap).reduce(
    (acc, key) => (segmentsMap[key](account) ? [...acc, key] : acc),
    [],
  );
};

const getBeamerSegments = (companyName, account) => {
  switch (companyName) {
    case 'shore':
      return [
        ...getGenericBeamerSegments(account),
        ...getSegmentedPaymentSource('Shore-Users', account),
      ];

    case 'douglas':
      return [
        ...getGenericBeamerSegments(account),
        ...getSegmentedPaymentSource('Douglas-Users', account),
      ];

    default:
      return [];
  }
};

const startsWith = (str1) => (str2) => str1.startsWith(str2);

export const getRoleNameForMerchantId = ({ merchantId, roles }) => {
  const role =
    roles && roles.find((r) => r.merchant && r.merchant.id === merchantId);
  return role && role.name ? role.name : null;
};

export const getIsAccountTerminated = (contractStates) =>
  !!contractStates &&
  contractStates.includes('terminated') &&
  !contractStates.includes('accepted');

export const getHubspotChatflow = ({ isTrialUser, locale }) => {
  const chatflowUser = isTrialUser ? 'trial' : 'paid';
  const supportedLocales = isTrialUser
    ? ['en', 'es', 'de']
    : ['en', 'es', 'de', 'it', 'fr'];
  const chatflowLocale = supportedLocales.some(startsWith(locale))
    ? locale.slice(0, 2)
    : 'zu';

  return `bm_${chatflowUser}_${chatflowLocale}`;
};

export const createBeamerCustomAttributes = ({
  account: {
    organizationId,
    organisationName,
    roles,
    isKeyAccount,
    contractDetails: { packages, states } = {},
  } = {},
  merchant: { id, category, isTest, trialEndsOn, pixelId } = {},
}) => ({
  user_role: getRoleNameForMerchantId({ merchantId: id, roles }) || '',
  user_merchant_id: id || '',
  user_organization_id: organizationId || '',
  user_industry: category || '',
  user_account_type: isKeyAccount ? 'key' : isTest ? 'test' : '',
  user_plans: packages ? packages.join(';') : '',
  user_lifecycle_stage: trialEndsOn
    ? 'trial'
    : getIsAccountTerminated(states)
    ? 'terminated'
    : 'active',
  user_integrations: pixelId ? 'facebook;instagram' : '',
  user_key_account_name: isKeyAccount ? organisationName : '',
});

export const createDelightedCustomAttributes = ({
  account: { organisationName, roles, contractDetails: { packages } = {} } = {},
  merchant: { id, category } = {},
}) => ({
  user_role: getRoleNameForMerchantId({ merchantId: id, roles }) || '',
  user_merchant_id: id || '',
  user_industry: category || '',
  user_plans: packages ? packages.join(';') : '',
  user_organisation_name: organisationName,
});

const isCurrentUserAdmin = (currentAccount, currentMerchant) =>
  currentAccount.organization_admin ||
  currentAccount.roles
    .filter(({ merchant: { id } }) => id === currentMerchant.id)
    .reduce((acc, { name }) => acc || ['admin'].includes(name), false);

const mapStateToProps = (state) => {
  const { currentAccount, currentMerchant, currentSidebar, hubspot, intercom } =
    state;

  const beamerCustomAttributes = createBeamerCustomAttributes({
    account: currentAccount,
    merchant: currentMerchant,
  });

  const delightedCustomAttributes = createDelightedCustomAttributes({
    account: currentAccount,
    merchant: currentMerchant,
  });

  const segments = getBeamerSegments(COMPANY_NAME, currentAccount);
  const hasMerchant = !!currentMerchant.id;
  const isTrialUser = !!currentMerchant.trialEndsOn;
  const { isKeyAccount, isTestAccount, locale } = currentAccount;
  const showHubspot =
    !currentSidebar.name && !!currentAccount.id && !!HUBSPOT_ID;
  const showDelighted =
    DELIGHTED && !isTestAccount && !isTrialUser && !isKeyAccount;

  const showIntercomChatbot =
    !isTrialUser &&
    !currentSidebar.name &&
    !!currentAccount.id &&
    !!INTERCOM_APP_ID &&
    hasMerchant;

  const whiteLabel = COMPANY_NAME;
  const activateUserlane = shouldActivateUserlane({
    whiteLabel,
    merchant: currentMerchant,
    account: currentAccount,
    isChatInitialized: isTrialUser
      ? hubspot.isHubspotInitialized
      : intercom.isIntercomInitialized,
  });
  const activateHubSpot = Boolean(hasMerchant && currentAccount.organizationId);
  const isContractApp = !!(
    location &&
    location.pathname &&
    location.pathname.includes('contract')
  );
  const hubspotChatflow = getHubspotChatflow({
    isTrialUser,
    locale,
  });

  return {
    account: currentAccount,
    activateUserlane,
    activateHubSpot,
    locale,
    merchant: currentMerchant,
    segments,
    showDelighted,
    showHubspot,
    showIntercomChatbot,
    isContractApp,
    isUserAdmin: isCurrentUserAdmin(currentAccount, currentMerchant),
    isTrialUser,
    isKeyAccount,
    hubspotChatflow,
    beamerCustomAttributes,
    delightedCustomAttributes,
    whiteLabel,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      onHubspotReady: setHubspotReady,
      onIntercomReady: setIntercomReady,
    },
    (action) => action && dispatch(action),
  );

export default connect(mapStateToProps, mapDispatchToProps)(Widgets);
