import safeMatch from './safe-match';
import {
  APP_MANIFEST_URL_TEMPLATE,
  CDN_BASE_URL,
  COMPANY_BASE_URL,
  SUPPORT_EMAIL,
} from '../constants';
import { sessionStorage } from './namespaced-storage';

import { window } from '../env/browser';

export const toQueryString = (query = {}) =>
  Object.keys(query).reduce(
    (result, key, i) =>
      `${result}${i > 0 ? '&' : '?'}${key}=${encodeURIComponent(query[key])}`,
    '',
  );

export const extractHash = (url) =>
  ((hash) => hash && `#${hash}`)(url.split('#')[1] || '');

export const extractPathAndQuery = safeMatch(2)(
  /^(https?:\/{2,}[^/?#]*)?(.*?)(#.*)?$/,
);

export const getAppManifestUrl = (appName) =>
  sessionStorage.getItem(`APP_MANIFEST_URL:${appName}`) ||
  APP_MANIFEST_URL_TEMPLATE.replace('__APPNAME__', `${appName}-app`);

export const getCoreUrl = (path = '', query = {}) =>
  `${COMPANY_BASE_URL}/merchant/${path}${toQueryString(query)}`;

export const getLoginUrl = (redirectTo) => {
  const baseUrl = getCoreUrl('sign_in');
  return redirectTo
    ? `${baseUrl}${toQueryString({ redirect_to: redirectTo })}`
    : baseUrl;
};

export const getLogoutUrl = () => getCoreUrl('sign_out');

export const getSupportContactUrl = () => `mailto:${SUPPORT_EMAIL}`;

export const getLogoUrl = (companyName) =>
  `${CDN_BASE_URL}/images/logo-${companyName}.png`;

export const removeQueryParams = (url) => (url && url.split('?')[0]) || '';

export const extractPathAndHash = (url) =>
  removeQueryParams(extractPathAndQuery(url)) +
  removeQueryParams(extractHash(url));

export const getDocumentUrl = (withMutableResponse = false) => {
  const baseUrl = withMutableResponse
    ? CDN_BASE_URL.replace(/\d+$/, 'current')
    : CDN_BASE_URL;
  return `${baseUrl}/index.html`;
};

export const setQueryStringParameter = ({
  name,
  value,
  append = false,
  cb,
}) => {
  // eslint-disable-next-line no-undef
  const url = new URL(window.document.URL);
  // Delete name to avoid duplicate entries
  url.searchParams.delete(name);
  if (append) url.searchParams.append(name, value);
  else url.searchParams.set(name, value);
  window.history.replaceState(null, '', url.toString());

  if (typeof cb === 'function') {
    setTimeout(cb, 500);
  }
};
