import { window } from '../env/browser';

export default function getGlobalValue(path, { bind = false } = {}) {
  return path.split('.').reduce((ref, part, i, arr) => {
    if (!ref) return undefined;
    const nextRef = ref[part];
    if (bind && i === arr.length - 1 && typeof nextRef === 'function') {
      return nextRef.bind(ref);
    }
    return nextRef;
  }, window);
}
