import {
  initializeSuccess,
  changeLocation,
  clickHeaderButton,
} from '../actions';
import { TRACK_IDENTIFY, TRACK_PAGE } from '../constants';
import { extractPathAndHash } from '../utils/url';

// map redux actions to user tracking events
export default {
  [initializeSuccess]: (
    state,
    { myAccountData: { id }, currentMerchantData },
  ) => ({
    eventName: TRACK_IDENTIFY,
    eventPayload: {
      id,
      data: {
        'Merchant uuid': currentMerchantData.id,
        'Merchant account uuid': id,
        id,
      },
    },
  }),
  [changeLocation]: (state, { url }) => ({
    eventName: TRACK_PAGE,
    eventPayload: {
      properties: {
        path: extractPathAndHash(url),
        url,
      },
    },
  }),
  [clickHeaderButton]: (state, name) => ({
    eventName: `header_${name}_click`,
  }),
};
