import { useSelector } from 'react-redux';

import {
  isInitializeErrored,
  getInitializationErrorName,
  getInitializationErrorMessage,
} from '../selectors';

export function useInitializeError() {
  const hasError = useSelector(isInitializeErrored, Boolean);
  const errorName = useSelector(getInitializationErrorName);
  const errorMessage = useSelector(getInitializationErrorMessage);

  return {
    hasInitializationError: hasError,
    initializationErrorName: errorName,
    initializationErrorMessage: errorMessage,
  };
}
