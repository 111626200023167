import { connect } from 'react-redux';
import {
  notificationsAppointmentClick,
  notificationsAppointmentItemClick,
  notificationsAppointmentCloseClick,
  notificationsMessageClick,
  notificationsClientMessageCloseClick,
} from '../../actions';
import Notifications from '../../components/Notifications';
import {
  getMessageCount,
  getUnreadAppointmentCount,
  notificationsPopupIsOpen,
  getNotificationAppointments,
  notificationIsLoaded,
  getFeatureToggles,
  getFirstUnreadClientMessage,
} from '../../selectors';

const mapStateToProps = (state) => ({
  messageCount: getMessageCount(state),
  appointmentCount: getUnreadAppointmentCount(state),
  showAppointments: notificationsPopupIsOpen(state),
  appointments: getNotificationAppointments(state),
  isLoaded: notificationIsLoaded(state),
  featureToggles: getFeatureToggles(state),
  clientMessage: getFirstUnreadClientMessage(state),
});

const mapDispatchToProps = {
  onAppointmentClick: notificationsAppointmentClick,
  onMessageClick: notificationsMessageClick,
  onAppointmentNotificationClick: notificationsAppointmentItemClick,
  onAppointmentNotificationCloseClick: notificationsAppointmentCloseClick,
  onClientMessageCloseClick: notificationsClientMessageCloseClick,
};

export default connect(mapStateToProps, mapDispatchToProps)(Notifications);
