import { h } from 'preact';
import { useEffect } from 'preact/hooks';
import { RouterProvider } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useQuery } from 'react-query';

import {
  ROUTES,
  INACTIVE_SUBSCRIPTION_ROUTES,
  INIT_APP_ROUTES,
} from './routes';
import { useGlobals } from './globals-context';
import { window } from './env/browser';
import { changeLocation } from './actions';
import { SUBSCRIPTION_STATUS } from './constants';
import EVENT_BUS_EVENTS from './constants/event-bus-events';
import useMerchantSettings from './hooks/use-merchant-settings';
import { useInitializeError } from './hooks/use-initialize-error';
import createQueryConfig from './queries';
import { Offline } from './components/Offline';
import { EmailVerification } from './components/EmailVerification';

function Loading({ create }) {
  return <RouterProvider router={create(INIT_APP_ROUTES)} />;
}

function isSpecialClick(event) {
  return event.ctrlKey || event.metaKey || event.shiftKey;
}

function findRouteLink(node) {
  if (!node || !node.tagName) return undefined;
  if (node.tagName.toLowerCase() === 'body') return undefined;

  const hasDataRoute = node.hasAttribute('data-route');
  const href = node.getAttribute('href');

  if (hasDataRoute && href) return node;

  return findRouteLink(node.parentNode);
}

function subscriptionInactive(subscription) {
  if (subscription) {
    return [SUBSCRIPTION_STATUS.CANCELLED, SUBSCRIPTION_STATUS.PAUSED].includes(
      subscription.status,
    );
  }

  return false;
}

function Router({ create, origin, merchantId }) {
  const { eventBus, queryClient, apiClient } = useGlobals();
  const dispatch = useDispatch();
  const queryConfig = createQueryConfig(queryClient, apiClient);
  const {
    data: subscription,
    status: subscriptionStatus,
    isFetchedAfterMount: isSubscriptionFetchingAfterMount,
  } = useQuery(queryConfig.subscription({ merchantId }));
  const isInitialSubscriptionLoading =
    subscriptionStatus === 'loading' && !isSubscriptionFetchingAfterMount;

  if (isInitialSubscriptionLoading) {
    return <Loading create={create} />;
  }

  const router = subscriptionInactive(subscription)
    ? create(INACTIVE_SUBSCRIPTION_ROUTES)
    : create(ROUTES);

  function subscribeToEventBusNavigate() {
    function navigateTo({ payload: { route } }) {
      router.navigate(route);
    }

    eventBus.on(EVENT_BUS_EVENTS.NAVIGATE, navigateTo);

    return () => {
      eventBus.off(EVENT_BUS_EVENTS.NAVIGATE, navigateTo);
    };
  }

  function subscribeToDeprecatedRouterLinkClick() {
    dispatch(changeLocation(origin()));

    function handleRouteLinkClick(event) {
      if (isSpecialClick(event)) return;

      const routeLink = findRouteLink(event.target);

      if (!routeLink) return;

      event.preventDefault();

      const { pathname, hash } = new URL(routeLink.href);
      router.navigate(pathname + hash);
      dispatch(changeLocation(routeLink.href));
    }

    window.addEventListener('click', handleRouteLinkClick);

    return () => {
      window.removeEventListener('click', handleRouteLinkClick);
    };
  }

  useEffect(subscribeToEventBusNavigate, [router]);
  useEffect(subscribeToDeprecatedRouterLinkClick, [router]);

  return <RouterProvider router={router} />;
}

function currentLocation() {
  return window.location.href;
}

function RouterContainer({ create, origin = currentLocation }) {
  const { isLoading: isLoadingSettings, merchantSettings } =
    useMerchantSettings();

  const { hasInitializationError, initializationErrorName } =
    useInitializeError();

  if (hasInitializationError) {
    if (
      initializationErrorName &&
      initializationErrorName === 'UnconfirmedEmailError'
    ) {
      return <EmailVerification />;
    }
    return <Offline />;
  }

  if (isLoadingSettings) {
    return <Loading create={create} />;
  }

  return (
    <Router
      create={create}
      origin={origin}
      merchantId={merchantSettings.merchantId}
    />
  );
}

export default RouterContainer;
