import mitt from 'mitt';
import createDebug from '../utils/debug';

const eventBus = mitt();
const eventDebugger = createDebug('event-bus');

export function createEventBus() {
  function getFilteredArgs(args) {
    return args.filter((arg) => typeof arg !== 'function');
  }

  function eventBusOn(...args) {
    const filteredArgs = getFilteredArgs(args);
    eventDebugger('on', ...filteredArgs);
    eventBus.on(...args);
  }

  function eventBusOff(...args) {
    const filteredArgs = getFilteredArgs(args);
    eventDebugger('off', ...filteredArgs);
    eventBus.off(...args);
  }

  function eventBusEmit(...args) {
    const filteredArgs = getFilteredArgs(args);
    eventDebugger('emit', ...filteredArgs);
    eventBus.emit(...args);
  }

  return Object.freeze({
    on: eventBusOn,
    off: eventBusOff,
    emit: eventBusEmit,
  });
}
