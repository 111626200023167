import _promisescript from 'promisescript';
import { document } from '../env/browser';

export function loadAssetsSequentially(urls, promisescript = _promisescript) {
  return urls.reduce(
    (promise, url) =>
      promise.then((values) =>
        promisescript(url).then((value) => [...values, value]),
      ),
    Promise.resolve([]),
  );
}

function injectScript({ url, type }) {
  return new Promise((resolve, reject) => {
    const tag = {
      style: 'link',
      script: 'script',
    }[type];
    const node = document.createElement(tag);

    if (!tag) {
      throw new Error('Invalid dependency type');
    }

    if (tag === 'link') {
      node.href = url;
      node.rel = 'stylesheet';
    } else {
      // Invalidates old cache. This is temporary needed to avoid CORS issues when injecting
      // the crossorigin tag attribute
      node.src = `${url}?n=1`;
      node.charset = 'utf8';
      node.setAttribute('crossorigin', 'anonymous');
    }

    node.addEventListener('load', resolve);
    node.addEventListener('error', (e) => reject(e));
    node.addEventListener('abort', () =>
      reject(new Error(`Script loading aborted for ${url}`)),
    );

    document.head.appendChild(node);
  });
}

export function loadAssetsConcurrently(urls, loadAsset = injectScript) {
  return Promise.all(urls.map((url) => loadAsset(url)));
}

export function loadSomeAssetsConcurrently(
  urls,
  promisescript = _promisescript,
) {
  const promises = promisescript(urls);
  const safePromises = promises.map(
    (promise) =>
      new Promise((resolve) =>
        promise.then(
          (value) => resolve(value),
          // ensure having an instance of Error
          (error) => resolve(error instanceof Error ? error : new Error(error)),
        ),
      ),
  );

  return Promise.all(safePromises).then((results) => {
    if (results.every((result) => result instanceof Error)) {
      throw new Error('no asset could be loaded');
    }
    return results;
  });
}
