import { FREE_PRODUCT_ALLOWED_APP_NAMES } from '../constants/index';
import { window } from '../env/browser';

// eslint-disable-next-line import/prefer-default-export
export const isAllowedToLoadAppName = (appName) =>
  typeof appName === 'string'
    ? FREE_PRODUCT_ALLOWED_APP_NAMES.includes(appName.toLowerCase())
    : true;

export const openCelloWidget = () => {
  window.cello.cmd.push((cello) => {
    cello.open();
  });
};
