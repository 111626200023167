import { Component } from 'preact';
import { window, document } from '../../../env/browser';
import { createAsyncScriptNode } from '../../../utils/dom';
import makeStatic from '../../../hocs/makeStatic';

export default makeStatic(
  class BeamerWidget extends Component {
    componentDidMount() {
      if (!window.beamer_config) {
        const {
          email,
          firstName,
          lastName,
          locale,
          productId,
          segments,
          targetElementId,
          userId,
          customAttributes,
        } = this.props;

        window.beamer_config = {
          post_request: true,
          filter: segments.join(';'),
          language: locale,
          left: 12,
          product_id: productId,
          selector: `#${targetElementId}`,
          top: -8,
          user_email: email,
          user_firstname: firstName,
          user_id: userId,
          user_lastname: lastName,
          ...customAttributes,
        };

        document.head.appendChild(
          createAsyncScriptNode({
            src: `https://app.getbeamer.com/js/beamer-embed.js`,
            defer: 'defer',
          }),
        );
      }
    }
  },
);
