import moment from 'moment';
import { roleForMerchant, ROLES } from './account';
import { COMPANY_NAME } from '../constants';

const USERLANE_TAGS = {
  'ONBOARDING:MEMBER': 'Onboarding:Member',
  'ONBOARDING:OWNER': 'Onboarding:Owner',
};

export const rolesToTag = (role) => {
  switch (role) {
    case ROLES.MEMBER:
      return USERLANE_TAGS['ONBOARDING:MEMBER'];
    case ROLES.OWNER:
    case ROLES.ADMIN:
      return USERLANE_TAGS['ONBOARDING:OWNER'];
    default:
      return USERLANE_TAGS['ONBOARDING:MEMBER'];
  }
};

export const getKeyAccountTag = (isKeyAccount) =>
  isKeyAccount ? ['key-account'] : [];

export const getPackageCodeTag = (packageCode) => {
  switch (packageCode) {
    case 'shore_plus':
      return ['plus'];
    default:
      return [];
  }
};

export const getTrialTag = (trialEndsOn) =>
  trialEndsOn !== null ? ['trial'] : [];

export const getTestTag = (isTest) => (isTest ? ['test'] : []);

export const getSourceTag = (source) => {
  switch (source) {
    case 'jimdo':
      return ['jimdo'];
    case 'epages':
      return ['epages'];
    case 'vrpayment':
      return ['vrpayment'];
    case 'sparkassen-haendlerservice':
      return ['sparkassen-haendlerservice'];
    case 'payone':
      return ['payone'];
    default:
      return [];
  }
};

export const getCategoryTag = (category) => {
  switch (category) {
    case 'hairdresser':
      return ['hair'];
    case 'beauty':
      return ['beauty'];
    case 'retail':
      return ['retail'];
    default:
      return [];
  }
};

export const getFreeProductTypeTag = (featureToggles) =>
  featureToggles && featureToggles.free_product ? ['freeProduct'] : [];

export const getSMSEnabledToggleStatusTag = (featureToggles) =>
  featureToggles && featureToggles.sms_charge_enabled
    ? ['sms-charge-enabled']
    : [];

export const getUserlaneTags = (merchant, account) => {
  const role = roleForMerchant(merchant.id, account.roles);

  const minimumDisplayDate =
    COMPANY_NAME === 'localsearch'
      ? moment('2018-12-01 00:00:00')
      : moment('2018-10-11 00:00:00');

  const isNewMerchant =
    merchant.createdAt &&
    moment(merchant.createdAt).isAfter(minimumDisplayDate);

  return isNewMerchant
    ? [
        rolesToTag(role),
        ...getSourceTag(merchant.source),
        ...getCategoryTag(merchant.category),
        ...getTrialTag(merchant.trialEndsOn),
        ...getTestTag(merchant.isTest),
        ...getPackageCodeTag(merchant.packageCode),
        ...getFreeProductTypeTag(merchant.featureToggles),
        ...getKeyAccountTag(account.isKeyAccount),
        ...getSMSEnabledToggleStatusTag(merchant.featureToggles),
      ]
    : [];
};

export const shouldActivateUserlane = ({
  whiteLabel,
  merchant,
  account,
  isChatInitialized = false,
}) => {
  const hasMerchant = !!merchant.id;
  const { isKeyAccount } = account;
  const tags = getUserlaneTags(merchant, account);
  const isWhiteLabelOrChatInitialized =
    whiteLabel === 'shore' ? isChatInitialized : true;

  return (
    hasMerchant &&
    !isKeyAccount &&
    !tags.includes(USERLANE_TAGS['ONBOARDING:MEMBER']) &&
    isWhiteLabelOrChatInitialized
  );
};
