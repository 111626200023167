/* eslint-disable import/prefer-default-export */
export const values = (obj) => Object.keys(obj).map((key) => obj[key]);

export const getValue = (obj = {}, path, defaultValue = undefined) => {
  if (!path.includes('.')) {
    return obj[path] || defaultValue;
  }

  let shouldBeDefault = false;
  return path.split('.').reduce((acc, cur) => {
    if (shouldBeDefault) return defaultValue;

    const value = acc[cur];

    if (typeof value === 'undefined') {
      shouldBeDefault = true;
      return defaultValue;
    }

    return value;
  }, obj);
};
