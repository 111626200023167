import createDebug from '../utils/debug';
import { REDUNDANT_DEPENDENCIES } from '../constants';
import { isDefined } from '../utils/validators';
import getGlobalValue from '../utils/get-global-value';
import { loadTranslations } from '../utils/translations';
import { WrappedError } from '../utils/custom-errors';
import {
  loadAssetsSequentially,
  loadAssetsConcurrently,
} from '../utils/asset-loaders';
import { createStyleEntry, createScriptEntry } from './asset-entry-creators';

const debug = createDebug('manifest:assets-loader');

const getTranslationEntries = (manifest) =>
  manifest.translations ||
  (manifest.translation && [manifest.translation]) ||
  [];

const loadTranslationDataList = (translationEntries, baseURL, language) =>
  Promise.all(
    translationEntries.map(({ template, namespace }) => {
      const urlTemplate = baseURL + template;
      return loadTranslations(urlTemplate, language).then((data) => ({
        data,
        namespace,
      }));
    }),
  );

export default (async function loadAppAssets(appName, manifest, language) {
  // load external dependencies
  if (manifest.externalDependencies) {
    debug('load external dependencies', appName, manifest.externalDependencies);
    const filteredExternalDependencies = manifest.externalDependencies.filter(
      (exDep) => !REDUNDANT_DEPENDENCIES.some((reDep) => exDep.match(reDep)),
    );

    try {
      await loadAssetsSequentially(filteredExternalDependencies);
    } catch (error) {
      throw new WrappedError(
        `Loading external dependencies of ${appName} failed`,
        error,
      );
    }
  }

  // load translation files (multiple entries possible)
  let translationDataList;
  try {
    translationDataList = await loadTranslationDataList(
      getTranslationEntries(manifest),
      manifest.baseURL,
      language,
    );
  } catch (error) {
    throw new WrappedError(`Loading translations of ${appName} failed`, error);
  }

  // load style and script
  const maybeStyleEntry = createStyleEntry(manifest);
  const scriptEntry = createScriptEntry(manifest);

  const assetEntries = [maybeStyleEntry, scriptEntry].filter(isDefined); // reject undefined entries

  debug('load js / css assets', appName, assetEntries);
  try {
    await loadAssetsConcurrently(assetEntries);
  } catch (error) {
    throw new WrappedError(`Loading App assets of ${appName} failed`, error);
  }

  const App = getGlobalValue(manifest.exposed);

  if (!App) {
    throw new Error(`${manifest.exposed} is not defined`);
  }

  return { App, translationDataList };
});
