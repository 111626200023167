import { h } from 'preact';
import cn from 'classnames';
import { t } from '../../utils/i18n';
import { location } from '../../env/browser';
import { SEVERITY } from '../../constants';

function SMSInfoMessage({
  status,
  isHiddenSm,
  isVisibleSm,
  onCloseClicked,
  isAdmin,
}) {
  const { origin } = location;

  const severity = (msgStatus) => {
    switch (msgStatus) {
      case SEVERITY.WARNING:
        return {
          langStart: 'sms-info.mild-warning-start',
          langEnd: 'sms-info.mild-warning-end',
          style: 'as-SMSInfoMessage-warning',
          iconStyle: 'as-SMSInfoMessage-warningIcon',
        };
      case SEVERITY.DANGER:
        return {
          langStart: 'sms-info.danger-warning-start',
          langEnd: 'sms-info.danger-warning-end',
          style: 'as-SMSInfoMessage-danger',
          iconStyle: 'as-SMSInfoMessage-dangerIcon',
        };
      default:
        return { langStart: '', langEnd: '', style: '', iconStyle: '' };
    }
  };

  // eslint-disable-next-line no-shadow
  const smsDisplayText = (status, isAdmin) => (
    <div className="as-SMSInfoMessage-infoText">
      <span>{`${t(severity(status).langStart)} `}</span>
      {isAdmin ? (
        <a
          class={cn(`as-SMSInfoMessage-infoTextLink`, {
            [`${severity(status).iconStyle}`]: true,
          })}
          href={`${origin}/settings/sms-settings`}
          data-route
        >
          <span className="as-SMSInfoMessage-smsSettingsStyle">
            {t('sms-info.warning-middle-settings')}
          </span>
        </a>
      ) : (
        <span className="as-SMSInfoMessage-smsSettingsStyle">
          {t('sms-info.warning-middle-settings')}
        </span>
      )}
      <span>{`${t(severity(status).langEnd)} `}</span>
    </div>
  );

  return (
    <div
      class={cn(`as-SMSInfoMessage`, {
        'is-hidden-sm': isHiddenSm,
        'is-visible-sm': isVisibleSm,
        [`${severity(status).style}`]: true,
      })}
    >
      <div className="as-SMSInfoMessage-smsIconContainer">
        <div
          class={cn(`as-SMSInfoMessage-messageIcon`, {
            [`${severity(status).iconStyle}`]: true,
          })}
        >
          <shore-icon name="envelope" />
        </div>
      </div>
      {smsDisplayText(status, isAdmin)}
      <button
        type="button"
        className="as-SMSInfoMessage-infoCloseBtn"
        onClick={onCloseClicked}
      >
        x
      </button>
    </div>
  );
}

export default SMSInfoMessage;
