/* eslint-disable jsx-a11y/no-static-element-interactions, jsx-a11y/click-events-have-key-events, jsx-a11y/no-noninteractive-element-to-interactive-role, react/jsx-props-no-spreading, no-undef, func-names */
import { h } from 'preact';
import cn from 'classnames';
import moment from 'moment';
import { NOTIFICATION_ELEMENT_ID, CELLO_PRODUCT_ID } from '../../constants';
import { antdThemeToken } from '../../styles/antd-theme';
import { t } from '../../utils/i18n';
import { openCelloWidget } from '../../utils';

const MESSAGES_APP_LINK_ID = 'messages-app-link';

const stopPropagation = (e) => {
  if (!(e.target?.id === MESSAGES_APP_LINK_ID)) {
    e.stopPropagation();
  }
};

const onHandshakeClick = () => {
  openCelloWidget();
};

const messengerAppLinkStyle = {
  display: 'flex',
  color: antdThemeToken.colorText,
};

const formatAppointmentCount = (count) => (count > 99 ? '99+' : count);

function Notifications({
  class: className,
  messageCount,
  appointmentCount,
  onAppointmentClick,
  onMessageClick,
  showAppointments,
  appointments: appointmentNotifications,
  onAppointmentNotificationClick,
  onAppointmentNotificationCloseClick,
  isLoaded,
  featureToggles,
  shouldShowProductNotification,
  clientMessage,
  onClientMessageCloseClick,
}) {
  return (
    <div class={`${className} as-Notifications`} onClick={stopPropagation}>
      {clientMessage && (
        <ClientMessage
          id={clientMessage.id}
          onClose={() => onClientMessageCloseClick(clientMessage.id)}
        />
      )}
      <shore-icon
        name="time"
        class="as-Notifications-icon"
        onClick={() => onAppointmentClick(!showAppointments)}
      >
        {appointmentCount > 0 && (
          <span class="as-Notifications-badge">
            {formatAppointmentCount(appointmentCount)}
          </span>
        )}
      </shore-icon>
      {featureToggles.messaging && (
        <a
          data-route
          href="#/messenger/conversations"
          style={messengerAppLinkStyle}
        >
          <shore-icon
            id={MESSAGES_APP_LINK_ID}
            name="comment"
            class="as-Notifications-icon"
            onClick={() => onMessageClick(messageCount)}
          >
            {messageCount > 0 && <span class="as-Notifications-badge" />}
          </shore-icon>
        </a>
      )}

      {CELLO_PRODUCT_ID && (
        <div style={{ position: 'relative' }}>
          <shore-icon
            name="handshake"
            class="as-Notifications-icon"
            onClick={onHandshakeClick}
            style={{ margin: '4px 10px' }}
          />
          <div id="cello-launcher" />
          <div id="cello-launcher-announcement" />
        </div>
      )}

      {shouldShowProductNotification && (
        <shore-icon
          name="bell"
          id={NOTIFICATION_ELEMENT_ID}
          class={cn('as-Notifications-icon', 'as-Notifications-iconbell')}
        />
      )}

      <div
        class={cn('as-Notifications-appointments', {
          'is-visible': showAppointments,
        })}
      >
        <header class="as-Notifications-appointmentsHeader">
          {t('notifications.menu-header.appointment')}
        </header>
        {!isLoaded ? (
          <shore-spinner class="as-Notifications-spinner" />
        ) : appointmentNotifications.length === 0 ? (
          <div class="as-Notifications-appointmentEmpty">
            {t('notifications.appointment.empty')}
          </div>
        ) : (
          <ul class="as-Notifications-appointmentList">
            {appointmentNotifications.map((notification) => (
              <AppointmentNotification
                {...notification}
                onClick={() =>
                  onAppointmentNotificationClick({
                    id: notification.id,
                    appointmentId: notification.appointmentId,
                    appointmentType: notification.appointmentType,
                    groupId: notification.groupId,
                  })
                }
                onClose={(e) => {
                  e.stopPropagation();
                  onAppointmentNotificationCloseClick(notification.id);
                }}
              />
            ))}
          </ul>
        )}
      </div>
    </div>
  );
}

const apmtStatusToHeadline = (apmtState) =>
  t(
    `notifications.appointment.states.${apmtState.replace(/_/g, '-')}.headline`,
  );

const apmtStatusToClass = (apmtStatus) => {
  switch (apmtStatus) {
    case 'dispatched':
    case 'alternative_proposed':
    case 'waiting':
      return 'shore-icon-appointment-notification-info-01 is-warning';
    case 'rejected':
    case 'rejected_by_customer':
    case 'canceled':
    case 'expired':
      return 'shore-icon-backend-fail is-danger';
    case 'accepted':
    case 'competing':
    default:
      return 'shore-icon-backend-success is-success';
  }
};

function AppointmentNotification({
  id,
  services,
  timestamp,
  customerName,
  status,
  requestedDate,
  onClick,
  onClose,
}) {
  return (
    // Needed because of this bug https://github.com/evcohen/eslint-plugin-jsx-a11y/issues/295
    <li
      key={id}
      class="as-Notifications-appointmentItem"
      onClick={onClick}
      role="button"
    >
      <shore-icon
        role="button"
        name="delete"
        class="as-Notifications-appointmentClose"
        onClick={onClose}
      />
      <div
        class="as-Notifications-appointmentCreatedAt"
        title={moment(timestamp).format('llll')}
      >
        {moment(timestamp).format('ll')}
      </div>
      <div
        class={`as-Notifications-appointmentStatusIcon ${apmtStatusToClass(
          status,
        )}`}
      />
      <div
        class="as-Notifications-appointmentHeadline"
        title={apmtStatusToHeadline(status)}
      >
        {apmtStatusToHeadline(status)}
      </div>
      <div class="as-Notifications-appointmentDetails">
        <div class="as-Notifications-appointmentRequestedDate">
          {moment(requestedDate).format('ddd, YYYY/MM/DD')}
        </div>
        <div>{services}</div>
        <div>{customerName}</div>
      </div>
    </li>
  );
}

function ClientMessage({ onClose, id }) {
  return (
    <div class="as-Notifications-clientMessage">
      <div class="as-Notifications-clientMessageBoundaryBlock" />
      <div>
        <h6>{t(`notifications.client-messages.${id}.title`)}</h6>
        <p>{t(`notifications.client-messages.${id}.body`)}</p>
      </div>
      <div class="as-Notifications-clientMessageBoundaryBlock">
        <button type="button" class="as-Modalclose" onClick={onClose}>
          <shore-icon name="delete" />
        </button>
      </div>
    </div>
  );
}

export default Notifications;
/* eslint-enable jsx-a11y/no-static-element-interactions, jsx-a11y/click-events-have-key-events, jsx-a11y/no-noninteractive-element-to-interactive-role, react/jsx-props-no-spreading, no-undef, func-names */
