/* eslint-disable import/prefer-default-export */

import { h } from 'preact';

function DowntimeIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="237"
      height="169"
      viewBox="0 0 237 169"
    >
      <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
        <g transform="translate(-722 -445)">
          <g transform="translate(340 196)">
            <g transform="translate(358 226)">
              <g
                fill="#5860D6"
                opacity="0.1"
                transform="rotate(-20 242.462 42.753)"
              >
                <path d="M238.809 62.882h-84.727c-4.853-.094-8.762-4.051-8.762-8.926a8.937 8.937 0 018.935-8.937h51.85c6.169 0 11.169-5 11.169-11.169v-.558c0-6.169-5-11.17-11.17-11.17h-25.66c6.109 0 11.061-4.952 11.061-11.06 0-6.11-4.952-11.062-11.06-11.062H95.929c-6.11 0-11.063 4.952-11.063 11.061 0 6.11 4.952 11.06 11.063 11.06H40.767c-6.168 0-11.17 5.002-11.17 11.17v.559c0 6.169 5.002 11.169 11.17 11.169h16.754a8.935 8.935 0 018.936 8.937 8.934 8.934 0 01-8.936 8.934H11.17C5 62.89 0 67.891 0 74.06s5 11.169 11.17 11.169h65.354c.224.014.446.034.673.034h16.376a8.733 8.733 0 018.735 8.735 8.733 8.733 0 01-8.735 8.733H48.265c-6.03 0-10.917 4.888-10.917 10.918 0 6.03 4.887 10.917 10.917 10.917H96.43c-6.11 0-11.062 4.953-11.062 11.062 0 6.11 4.953 11.062 11.062 11.062h71.765c6.109 0 11.062-4.953 11.062-11.062 0-6.11-4.953-11.062-11.062-11.062H204.4c6.03 0 10.918-4.887 10.918-10.917S210.43 102.73 204.4 102.73h-16.272a8.734 8.734 0 010-17.468h50.68c6.031 0 10.918-4.888 10.918-10.918V73.8c0-6.03-4.887-10.917-10.917-10.917" />
              </g>
              <path fill="#FFF" d="M48.17 160.74h13-13z" />
              <path
                stroke="#00D0BE"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="3.25"
                d="M48.17 160.74L61.17 160.74"
              />
              <path fill="#FFF" d="M223.33 160.74h13-13z" />
              <path
                stroke="#00D0BE"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="3.25"
                d="M223.33 160.74L236.33 160.74"
              />
              <path fill="#FFF" d="M186.427 160.74h19.5-19.5z" />
              <path
                stroke="#00D0BE"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="3.25"
                d="M186.427 160.74L205.927 160.74"
              />
              <g transform="translate(51.9 28)">
                <path
                  fill="#FFF"
                  d="M15.77 143.673h112.257a6.5 6.5 0 006.5-6.5V41.29H9.27v95.883a6.5 6.5 0 006.5 6.5"
                />
                <path
                  stroke="#5860D6"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="3.25"
                  d="M15.77 143.673h112.257a6.5 6.5 0 006.5-6.5V41.29H9.27v95.883a6.5 6.5 0 006.5 6.5z"
                />
                <path
                  fill="#FFF"
                  d="M25.67 41.29H118.127c1.806 0 3.441.731 4.625 1.914a6.518 6.518 0 011.917 4.625v71.91H19.129V47.832c0-1.806.732-3.441 1.916-4.626a6.524 6.524 0 014.626-1.916"
                />
                <path
                  stroke="#00D0BE"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="3.25"
                  d="M25.67 41.29H118.127c1.806 0 3.441.731 4.625 1.914a6.518 6.518 0 011.917 4.625v.002h0v71.908H19.129V47.832c0-1.806.732-3.441 1.916-4.626a6.524 6.524 0 014.626-1.916z"
                />
                <path
                  fill="#00D0BE"
                  d="M71.896 12.417h26.842l8.945 28.873v7.22c.041 9.923-7.969 18.002-17.893 18.046-9.924-.042-17.936-8.122-17.895-18.047V41.29l.001-28.873z"
                />
                <path
                  stroke="#5862D6"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="3.25"
                  d="M71.896 12.417h26.842l8.945 28.873v7.22c.041 9.923-7.969 18.002-17.893 18.046-9.924-.042-17.936-8.122-17.895-18.047V41.29l.001-28.873z"
                />
                <path
                  fill="#00D0BE"
                  d="M98.738 12.417h26.84l4.474 7.218 4.473 7.219 4.473 7.218 4.474 7.218v7.22c.04 9.923-7.97 18.002-17.893 18.046-9.925-.042-17.937-8.122-17.896-18.047V41.29l-8.945-28.873z"
                />
                <path
                  stroke="#5862D6"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="3.25"
                  d="M98.738 12.417h26.84l4.474 7.218 4.473 7.219 4.473 7.218 4.474 7.218v7.22c.04 9.923-7.97 18.002-17.893 18.046-9.925-.042-17.937-8.122-17.896-18.047V41.29l-8.945-28.873z"
                />
                <path
                  fill="#00D0BE"
                  d="M71.896 12.417h-26.84L36.11 41.29v7.22c-.04 9.923 7.969 18.002 17.893 18.046 9.924-.042 17.936-8.122 17.896-18.047V41.29l-.003-28.873z"
                />
                <path
                  stroke="#5862D6"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="3.25"
                  d="M71.896 12.417h-26.84L36.11 41.29v7.22c-.04 9.923 7.969 18.002 17.893 18.046 9.924-.042 17.936-8.122 17.896-18.047V41.29l-.003-28.873z"
                />
                <path
                  fill="#00D0BE"
                  d="M45.055 12.417h-26.84l-4.474 7.218-4.473 7.219-4.473 7.218L.322 41.29v7.22C.28 58.432 8.29 66.511 18.214 66.555c9.925-.042 17.937-8.122 17.896-18.047V41.29l8.945-28.873z"
                />
                <path
                  stroke="#5862D6"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="3.25"
                  d="M45.055 12.417h-26.84l-4.474 7.218-4.473 7.219-4.473 7.218L.322 41.29v7.22C.28 58.432 8.29 66.511 18.214 66.555c9.925-.042 17.937-8.122 17.896-18.047V41.29l8.945-28.873z"
                />
                <path
                  stroke="#5862D6"
                  strokeLinecap="round"
                  strokeWidth="3.25"
                  d="M62.085 82.982a9.251 9.251 0 01-13.084 0"
                />
                <path
                  stroke="#5862D6"
                  strokeLinecap="round"
                  strokeWidth="3.25"
                  d="M94.795 82.982a9.251 9.251 0 01-13.084 0"
                />
                <g transform="translate(8.45 40.95)">
                  <path fill="#00D0BE" d="M0.016 0.34L19.516 0.34" />
                  <path
                    stroke="#5862D6"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="3.25"
                    d="M0.016 0.34L19.516 0.34"
                  />
                </g>
                <g transform="translate(44.2 40.95)">
                  <path fill="#00D0BE" d="M0.054 0.34L19.554 0.34" />
                  <path
                    stroke="#5862D6"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="3.25"
                    d="M0.054 0.34L19.554 0.34"
                  />
                </g>
                <g transform="translate(79.95 40.95)">
                  <path fill="#00D0BE" d="M0.089 0.34L19.589 0.34" />
                  <path
                    stroke="#5862D6"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="3.25"
                    d="M0.089 0.34L19.589 0.34"
                  />
                </g>
                <g transform="translate(115.7 40.95)">
                  <path fill="#00D0BE" d="M0.127 0.34L19.627 0.34" />
                  <path
                    stroke="#5862D6"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="3.25"
                    d="M0.127 0.34L19.627 0.34"
                  />
                </g>
                <path
                  fill="#FFF"
                  d="M162.896 104.673a6.5 6.5 0 00-6.5 6.5v32.5h13v-32.5a6.5 6.5 0 00-6.5-6.5"
                />
                <path
                  stroke="#5862D6"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="3.25"
                  d="M162.896 104.673h0a6.5 6.5 0 00-6.5 6.5v32.5h13v-32.5a6.5 6.5 0 00-6.5-6.5z"
                />
                <g transform="translate(162.5 39)">
                  <path fill="#00D0BE" d="M0.396 65.673L0.396 0.627" />
                  <path
                    stroke="#5862D6"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="3.25"
                    d="M0.396 65.673L0.396 0.627"
                  />
                </g>
                <path
                  fill="#FFF"
                  d="M169.358345 39.626535L156.435045 39.626535 153.203895 7.317635 172.589495 7.317635z"
                />
                <path
                  stroke="#5862D6"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="3.25"
                  d="M169.358345 39.626535L156.435045 39.626535 153.203895 7.317635 172.589495 7.317635z"
                />
                <path
                  fill="#00D0BE"
                  d="M179.05 7.318h-32.308c8.922-8.922 23.387-8.922 32.309 0"
                />
                <path
                  stroke="#5862D6"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="3.25"
                  d="M179.05 7.318h-32.308c8.922-8.922 23.387-8.922 32.309 0z"
                />
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}

export { DowntimeIcon };
