import cn from 'classnames';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  setLoadingOverlay,
  proxyAppAction,
  overlayError,
  reloadAppShell,
} from '../../actions';
import { getAppSettings } from '../../selectors';
import AppLoader from '../../components/AppLoader';
import { document } from '../../env/browser';
import { MODAL_ELEMENT_ID } from '../../constants';

const mapStateToProps = (state) => ({
  settings: getAppSettings(state),
  name: state.currentOverlay.name,
  disabled: !state.initialization.success,
  pathAndQuery: state.currentOverlay.pathAndQuery,
  useHashHistory: true,
  routingType: 'hash',
  documentDate: state.documentHeaders.date,
  modal: {
    element: document.getElementById(MODAL_ELEMENT_ID),
  },
  class: cn('as-Overlay', {
    'is-visible': !!state.currentOverlay.name,
    'is-temporarilyHidden': state.currentOverlay.isTemporarilyHidden,
  }),
});

const mapDispatchToProps = (dispatch, { onProxyAction }) =>
  bindActionCreators(
    {
      onLoadingApp: setLoadingOverlay,
      onAppAction: proxyAppAction(onProxyAction),
      onError: overlayError,
      onDocumentOutdated: reloadAppShell,
    },
    (action) => action && dispatch(action),
  );

export default connect(mapStateToProps, mapDispatchToProps)(AppLoader);
