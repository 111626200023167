import cn from 'classnames';
import { h } from 'preact';
import { useQuery } from 'react-query';

import { t } from '../../utils/i18n';
import { location } from '../../env/browser';
import { useGlobals } from '../../globals-context';
import { SUBSCRIPTION_STATUS } from '../../constants';
import createQueryConfig from '../../queries';

function TrialMessage({ merchantId, trialDaysLeft, isHiddenSm, isVisibleSm }) {
  const { origin } = location;
  const { queryClient, apiClient } = useGlobals();
  const queryConfig = createQueryConfig(queryClient, apiClient);
  const { data: subscription, status: subscriptionFetchingStatus } = useQuery(
    queryConfig.subscription({ merchantId }),
  );

  if (subscriptionFetchingStatus === 'loading') {
    return null;
  }

  if (
    subscription?.status !== SUBSCRIPTION_STATUS.TRIAL ||
    trialDaysLeft === null
  ) {
    return null;
  }

  return (
    <div
      class={cn('as-TrialMessage', {
        'is-hidden-sm': isHiddenSm,
        'is-visible-sm': isVisibleSm,
      })}
    >
      <div className="as-TrialMessage-daysIconContainer">
        <div className="as-TrialMessage-daysIcon">
          <div className="as-TrialMessage-daysIconDays">{trialDaysLeft}</div>
          <div className="as-TrialMessage-daysIconText">
            {trialDaysLeft === 1
              ? t('trial-days.days-singular')
              : t('trial-days.days-multiple')}
          </div>
        </div>
      </div>
      <div className="as-TrialMessage-infoText">
        <b className="as-TrialMessage-infoTextBold">
          {t('trial-days.message-before-link-multiple', {
            days: trialDaysLeft,
          })}
        </b>
        <a
          className="as-TrialMessage-infoTextLink"
          href={`${origin}/contract`}
          data-route
        >
          {t('trial-days.message-link-text')}
        </a>
        {t('trial-days.message-after-link')}
      </div>
    </div>
  );
}

export default TrialMessage;
