import { h } from 'preact';
import { useEffect, useRef } from 'preact/compat';
import { useQuery, useQueries } from 'react-query';
import createQueryConfig from '../src/queries';

import { window } from '../src/env/browser';
import { useGlobals } from '../src/globals-context';

import useCreateAppConfig from './use-create-app-config';
import {
  containerStyle,
  iframeStyle,
  MICRO_APP_STARTUP_MESSAGE,
  MICRO_APP_URL,
} from './constants';

const APP_NAME = 'launchpad';

export function LaunchpadApp() {
  const deps = useGlobals();
  const iframeRef = useRef(null);
  const { isLoading: isCreateAppConfigLoading, config } = useCreateAppConfig(
    APP_NAME,
    deps,
  );

  const queryConfig = createQueryConfig(deps.queryClient, deps.apiClient);

  const authTokenQuery = useQuery(queryConfig.authToken());

  if (authTokenQuery.isLoading || isCreateAppConfigLoading) {
    return null;
  }

  const authToken = authTokenQuery.data;

  const queries = useQueries([
    queryConfig.merchantAccountV2(),
    queryConfig.merchantV2(config.settings.merchantId),
    queryConfig.merchantsSearch(),
  ]);

  const [
    { data: merchantAccountData },
    { data: merchantData },
    { data: merchantsSearchData },
  ] = queries;

  const contractQuery = useQuery({
    ...queryConfig.contractV2.findAll(merchantAccountData?.organization.id),
    enabled: !!merchantAccountData,
  });

  const { data: contractData } = contractQuery;
  const allQueries = [...queries, contractQuery];

  const isLoadingLaunchpadData = allQueries.some(({ isLoading }) => isLoading);

  useEffect(() => {
    if (
      iframeRef.current &&
      !isCreateAppConfigLoading &&
      !isLoadingLaunchpadData
    ) {
      setTimeout(() => {
        window.Shore.microAppArgs[APP_NAME] = {
          ...config,
          authToken,
          contract: contractData,
          merchantAccount: merchantAccountData,
          merchant: merchantData,
          merchantSearch: merchantsSearchData,
        };
        iframeRef.current.contentWindow.postMessage(
          { type: MICRO_APP_STARTUP_MESSAGE },
          '*',
        );
      }, 1000);
    }
  }, [iframeRef.current, isCreateAppConfigLoading, isLoadingLaunchpadData]);

  return (
    <div style={containerStyle}>
      <iframe
        title="LaunchpadApp"
        ref={iframeRef}
        style={iframeStyle}
        src={`${MICRO_APP_URL}/launchpad/`}
      />
    </div>
  );
}
