import { h } from 'preact';
import {
  USERLANE_APP_ID,
  HUBSPOT_ID,
  BEAMER_ID,
  NOTIFICATION_ELEMENT_ID,
  INTERCOM_APP_ID,
} from '../../constants';
import Userlane from './Userlane';
import { HubSpotWidget as HubSpot } from './HubSpot';
import Delighted from './Delighted';
import Beamer from './Beamer';
import { IntercomChatbot } from './Intercom';

const isAccountFullyLoaded = (account) =>
  account.id !== null &&
  account.contractDetails.defaultPaymentSourceType !== undefined;

function Widgets({
  account,
  activateHubSpot,
  activateUserlane,
  locale,
  merchant,
  whiteLabel,
  segments,
  showDelighted,
  showHubspot,
  showIntercomChatbot,
  isUserAdmin,
  isContractApp,
  isTrialUser,
  isKeyAccount,
  hubspotChatflow,
  beamerCustomAttributes,
  delightedCustomAttributes,
  onHubspotReady,
  onIntercomReady,
}) {
  const shouldDisplayHubspot = HUBSPOT_ID && activateHubSpot;

  return (
    <div>
      {BEAMER_ID && isAccountFullyLoaded(account) && !isContractApp && (
        <Beamer
          email={account.email}
          firstName={account.firstName}
          lastName={account.lastName}
          locale={locale}
          productId={BEAMER_ID}
          segments={segments}
          targetElementId={NOTIFICATION_ELEMENT_ID}
          userId={account.id}
          customAttributes={beamerCustomAttributes}
        />
      )}
      {USERLANE_APP_ID && activateUserlane && (
        <Userlane
          appId={USERLANE_APP_ID}
          {...{ merchant, account, whiteLabel }}
        />
      )}
      {shouldDisplayHubspot && !showIntercomChatbot && (
        <HubSpot
          hubId={HUBSPOT_ID}
          show={showHubspot}
          chatflow={hubspotChatflow}
          email={account.email}
          organizationId={account.organizationId}
          onHubspotReady={onHubspotReady}
        />
      )}
      {showDelighted && account.email !== '' && (
        <Delighted
          email={account.email}
          locale={account.locale}
          delightedCustomAttributes={delightedCustomAttributes}
        />
      )}
      {showIntercomChatbot && (
        <IntercomChatbot
          appId={INTERCOM_APP_ID}
          userId={account.id}
          email={account.email}
          name={[account.firstName, account.lastName].filter(Boolean).join(' ')}
          isUserAdmin={isUserAdmin}
          isTrialUser={isTrialUser}
          isKeyAccount={isKeyAccount}
          merchantId={merchant.id}
          merchantPhoneNumber={merchant.phoneNumber}
          merchantIndustry={merchant.category}
          organizationId={account.organizationId}
          organisationName={account.organisationName}
          onIntercomReady={onIntercomReady}
        />
      )}
    </div>
  );
}

export default Widgets;
