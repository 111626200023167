import Chance from 'chance';
import { compose } from 'redux';

import Tracer from './tracer';
import { localStorage } from '../env/browser';

const chance = new Chance();

const ACTIVE = '1';
const INACTIVE = '0';

function storageKey(featureName) {
  return `feature_toggle_${featureName}`;
}

function cachedValue(featureName) {
  const cache = localStorage.getItem(storageKey(featureName));

  if (!cache) {
    return {};
  }

  return JSON.parse(cache);
}

function isCacheEmpty(cache) {
  return Object.keys(cache).length === 0;
}

function castToggleValue(toggleStrValue) {
  return compose(Boolean, Number)(toggleStrValue);
}

function cacheFeatureToggle(featureName, percentage, isActive) {
  const percentageStr = String(percentage);

  localStorage.setItem(
    storageKey(featureName),
    JSON.stringify({
      ...cachedValue(featureName),
      [percentageStr]: isActive ? ACTIVE : INACTIVE,
      activeRolloutPercentage: percentage,
    }),
  );
}

function setTracerRolloutInfo(featureName, isActive, percentage) {
  const featureRolloutContext =
    Tracer.getGlobalContextProperty('feature_rollout');

  Tracer.setGlobalContext('feature_rollout', {
    ...(featureRolloutContext || {}),
    [featureName]: {
      percentage,
      value: isActive,
    },
  });
}

function toggleValue(featureName, newRolloutPercentage) {
  const cache = cachedValue(featureName);
  const freshToggleValue = chance.bool({ likelihood: newRolloutPercentage });

  if (isCacheEmpty(cache)) {
    return freshToggleValue;
  }

  if (newRolloutPercentage > cache.activeRolloutPercentage) {
    const currentToggleValue = castToggleValue(
      cache[cache.activeRolloutPercentage],
    );

    return currentToggleValue === true ? true : freshToggleValue;
  }

  return freshToggleValue;
}

const FeatureRollout = {
  features() {
    return new Set(['services-app-refactor', 'api-consumption-analysis']);
  },

  validateFeatureName(featureName) {
    if (!this.features().has(featureName)) {
      throw new Error(`Unknown feature toggle name: ${featureName}`);
    }
  },

  activate(featureName, { percentage }) {
    this.validateFeatureName(featureName);

    const currentValue = cachedValue(featureName);
    const percentageStr = String(percentage);

    if (currentValue[percentageStr] === undefined) {
      const shouldToggle = toggleValue(featureName, percentage);

      cacheFeatureToggle(featureName, percentage, shouldToggle);

      setTracerRolloutInfo(featureName, shouldToggle, percentage);

      return shouldToggle;
    }

    const currentToggleValue = castToggleValue(currentValue[percentageStr]);

    if (percentageStr !== currentValue.activeRolloutPercentage) {
      localStorage.setItem(
        storageKey(featureName),
        JSON.stringify({
          ...cachedValue(featureName),
          activeRolloutPercentage: percentage,
        }),
      );
    }

    setTracerRolloutInfo(featureName, currentToggleValue, percentage);

    return currentToggleValue;
  },

  isActive(featureName) {
    this.validateFeatureName(featureName);

    const cache = cachedValue(featureName);
    const { activeRolloutPercentage } = cache;

    return (
      (cache[activeRolloutPercentage] &&
        castToggleValue(cache[activeRolloutPercentage])) ||
      false
    );
  },
};

export default FeatureRollout;
