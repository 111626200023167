export const ROLES = {
  MEMBER: 'member',
  OWNER: 'owner',
  ADMIN: 'admin',
};

export const roleForMerchant = (merchantId, roles) => {
  const roleObj = roles.find((role) => role.merchant.id === merchantId);
  return roleObj ? roleObj.name : ROLES.MEMBER;
};
