import _i18next from 'i18next';
import uniq from 'uniq';
import isPlainObject from 'lodash.isplainobject';
import createDebug from './debug';
import { loadSomeAssetsConcurrently } from './asset-loaders';
import { FALLBACK_LANGUAGES } from '../constants';

const debug = createDebug('utils:translations');

export function registerTranslations(namespace, data = {}, i18next = _i18next) {
  Object.keys(data).forEach((lang) => {
    if (!isPlainObject(data[lang])) return;
    i18next.addResourceBundle(lang, namespace, data[lang], true, true);
  });
}

export async function loadTranslations(urlTemplate, language) {
  const languages = uniq([language, ...FALLBACK_LANGUAGES]);
  const translationEntries = languages.map((lang) => ({
    url: urlTemplate.replace('__LANG__', lang),
    type: 'json',
  }));
  let translationDataList;

  try {
    translationDataList = await loadSomeAssetsConcurrently(translationEntries);
  } catch (error) {
    debug(error);
    throw new Error('Loading translations failed');
  }

  return languages.reduce((result, lang, index) => {
    const data = translationDataList[index];
    return { ...result, [lang]: data instanceof Error ? undefined : data };
  }, {});
}
