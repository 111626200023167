import {
  hitEscape,
  receiveDocumentHeaders,
  newNotificationCounts,
  documentClick,
} from '../actions';
import { addEventListener, document } from '../env/browser';
import { getDocumentUrl } from '../utils/url';
import { fetchHeaders } from '../utils/fetch';
import {
  CHECK_FOR_UPDATES_INTERVAL,
  CHECK_FOR_NOTIFICATION_COUNT_INTERVAL,
} from '../constants';

export function observeEscKey(store) {
  addEventListener('keydown', (event) => {
    if (event.key === 'Escape' || event.keyCode === 27) {
      store.dispatch(hitEscape());
    }
  });
}

export function observeDocumentHeaders(store) {
  const url = getDocumentUrl(true);
  const update = () =>
    fetchHeaders(url, ['last-modified', 'date']).then(([lastModified, date]) =>
      store.dispatch(receiveDocumentHeaders({ lastModified, date })),
    );

  setInterval(update, CHECK_FOR_UPDATES_INTERVAL);
  return update();
}

/* istanbul ignore next */
export async function observeUnreadNotificationCount(
  store,
  { apiClient },
  merchantId,
) {
  const update = (client, dispatch, action) => async () => {
    if (!document.hidden) {
      // TODO replace with web socket
      const unreadNotifications = await client.notificationsV2.unread({
        merchant_id: merchantId,
      });

      dispatch(action(unreadNotifications));
    }
  };

  setInterval(
    update(apiClient, store.dispatch, newNotificationCounts),
    CHECK_FOR_NOTIFICATION_COUNT_INTERVAL,
  );
}

export const observeDocumentClick = ({ dispatch }) => {
  document.addEventListener('click', () => {
    dispatch(documentClick());
  });
};

export const websocketListener =
  (apiClient) => (merchantId) => (channelName) => (eventName) => (fn) => {
    // eslint-disable-next-line no-shadow
    const getChannel = (channelName, merchantId) =>
      `${channelName}=${merchantId}`;

    apiClient.realtime.connect().then((state) => {
      if (state === 'connected') {
        const channel = apiClient.realtime.subscribe(
          getChannel(channelName, merchantId),
        );

        channel.bind(eventName, fn);
      } else {
        // TODO: post error to sentry
        // eslint-disable-next-line no-console
        console.log(`Failed to bind to channel ${channelName}`);
      }
    });
  };
