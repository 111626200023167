import { h } from 'preact';
import { useQuery } from 'react-query';

import { useGlobals } from '../globals-context';
import { API_ENV } from '../constants';
import { location } from '../env/browser';
import createQueryConfig from '../queries';
import useMerchantSettings from '../hooks/use-merchant-settings';

const iframeStyle = {
  flex: 1,
  height: '100%',
};

const CHECKOUT_SUCCESS_REDIRECT_URL = `${location.origin}`;

const CHECKOUT_APP_URL =
  API_ENV === 'staging'
    ? 'https://subscription-app-staging.shore-internal.com/checkout'
    : 'https://subscription-app.shore-internal.com/checkout';

function checkoutAppUrl(merchantId) {
  const queryParams = `product_family=shore_bm&merchant_id=${merchantId}&redirect_url=${CHECKOUT_SUCCESS_REDIRECT_URL}`;

  return `${CHECKOUT_APP_URL}?${queryParams}`;
}

export function SubscriptionCheckout() {
  const { isLoading: isLoadingMerchantSettings, merchantSettings } =
    useMerchantSettings();
  const { queryClient, apiClient } = useGlobals();
  const queryConfig = createQueryConfig(queryClient, apiClient);
  const { status: subscriptionStatus } = merchantSettings
    ? useQuery(queryConfig.subscription(merchantSettings))
    : { status: 'loading' };

  if (isLoadingMerchantSettings || subscriptionStatus === 'loading') {
    return null;
  }

  if (subscriptionStatus === 'error') {
    return (
      <p>Failed to get subscription information. Please try again later.</p>
    );
  }

  return (
    <iframe
      title="Shore's subscription checkout"
      style={iframeStyle}
      src={checkoutAppUrl(merchantSettings.merchantId)}
    />
  );
}
