/* eslint-disable react/jsx-props-no-spreading  */
import { h } from 'preact';
import cn from 'classnames';
import { useEffect } from 'preact/hooks';

import App from '../../containers/App';
import ModalApp from '../../containers/ModalApp';
import Sidebar from '../../containers/Sidebar';
import Error from '../../containers/Error';
import { useGlobals } from '../../globals-context';
import { MODAL_ELEMENT_ID } from '../../constants';

const proxyAction = (instances) => (action) => {
  instances.forEach((getInstance) => {
    const instance = getInstance();
    if (!instance || typeof instance.dispatchAction !== 'function') return;
    instance.dispatchAction(action);
  });
};

let app;
let sidebar;

const wrapInModal = (
  reactComponent,
  onCloseClick,
  isModalOpen,
  addHeader = false,
) => (
  <div // eslint-disable-line
    className={cn('as-Modalcontainer', {
      'is-open': isModalOpen,
    })}
    onClick={() => {
      proxyAction([() => sidebar, () => app])(onCloseClick());
    }}
  >
    <div // eslint-disable-line
      className={cn('as-Modal', {
        'is-open': isModalOpen,
      })}
      id={MODAL_ELEMENT_ID}
      onClick={(e) => {
        e.stopPropagation();
      }}
    >
      {addHeader && (
        <div class="as-Modalheader">
          <button type="button" class="as-Modalclose" onClick={onCloseClick}>
            <shore-icon name="delete" />
          </button>
        </div>
      )}
      {reactComponent}
    </div>
  </div>
);

function Main({
  children,
  sidebarShown,
  modalOpen,
  currentModalApp = {},
  onModalAppLoaded,
  onModalCloseClick,
  changeLocation,
}) {
  const deps = useGlobals();

  useEffect(() => {
    const { router } = deps;

    router.onChangeLocation(changeLocation, true);

    router.subscribe();

    return router.destroy;
  }, []);

  return (
    <div class="as-Appwrapper">
      <div
        class={cn('as-Body', { 'is-showingSidebar': sidebarShown })}
        id="asBody"
      >
        <App
          key={deps.name}
          {...deps}
          onProxyAction={proxyAction([() => sidebar])}
          onInstance={(instance) => {
            app = instance;
          }}
        />
        <Sidebar
          {...deps}
          onProxyAction={proxyAction([() => app])}
          onInstance={(instance) => {
            sidebar = instance;
          }}
        />
        {wrapInModal(null, onModalCloseClick, modalOpen)}
      </div>
      {wrapInModal(
        <ModalApp
          {...{
            ...deps,
            ...currentModalApp,
            onInstance(instance) {
              if (instance) {
                onModalAppLoaded(instance);
              }
            },
          }}
        />,
        onModalCloseClick,
        currentModalApp.visible,
        true,
      )}
      <Error />
      {children}
    </div>
  );
}

export default Main;
/* eslint-enable react/jsx-props-no-spreading  */
