import { Component } from 'preact';
import { window } from '../../../env/browser';

const scriptContentNPSSurvey = `!function(e,t,r,n){if(!e[n]){for(var a=e[n]=[],i=["survey","reset","config","init","set","get","event","identify","track","page","screen","group","alias"],s=0;s<i.length;s++){var c=i[s];a[c]=a[c]||function(e){return function(){var t=Array.prototype.slice.call(arguments);a.push([e,t])}}(c)}a.SNIPPET_VERSION="1.0.1";var o=t.createElement("script");o.type="text/javascript",o.async=!0,o.src="https://d2yyd1h5u9mauk.cloudfront.net/integrations/web/v1/library/"+r+"/"+n+".js";var p=t.getElementsByTagName("script")[0];p.parentNode.insertBefore(o,p)}}(window,document,"q5I0VB4WeqGfjd0z","delighted");`;

const scriptContentPMFSurvey = `!function(e,t,r,n){if(!e[n]){for(var a=e[n]=[],i=["survey","reset","config","init","set","get","event","identify","track","page","screen","group","alias"],s=0;s<i.length;s++){var c=i[s];a[c]=a[c]||function(e){return function(){var t=Array.prototype.slice.call(arguments);a.push([e,t])}}(c)}a.SNIPPET_VERSION="1.0.1";var o=t.createElement("script");o.type="text/javascript",o.async=!0,o.src="https://d2yyd1h5u9mauk.cloudfront.net/integrations/web/v1/library/"+r+"/"+n+".js";var f=t.getElementsByTagName("script")[0];f.parentNode.insertBefore(o,f)}}(window,document,"Lvn7xlAf234vhBVI","delightedPmf");`;

export default class DelightedWidget extends Component {
  componentDidMount() {
    // eslint-disable-next-line no-eval
    eval(scriptContentNPSSurvey);
    // eslint-disable-next-line no-eval
    eval(scriptContentPMFSurvey);

    const { email, locale, delightedCustomAttributes } = this.props;
    const normalizeLocale = (value) => {
      const language = value.split('-')[0];
      if (language === 'de') {
        return 'de-f';
      }
      return language;
    };

    const delightedAttributes = {
      properties: {
        locale: normalizeLocale(locale),
        ...delightedCustomAttributes,
      },
      email,
      initialDelay: 1, // in sec
    };

    // Initialize NPS Survey
    window.delighted.survey(delightedAttributes);

    // Initialize PMF Survey
    window.delightedPmf.survey(delightedAttributes);
  }
}
