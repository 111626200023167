import { h, Component } from 'preact';
import cn from 'classnames';
import { document, window } from '../../env/browser';
import { t } from '../../utils/i18n';
import Button from '../Button';
import ProfileImage from '../ProfileImage';
import { CELLO_PRODUCT_ID } from '../../constants';
import { openCelloWidget } from '../../utils';

const ACTIVE = 'active';

class Profile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
    };
    this.toggleDropDown = this.toggleDropDown.bind(this);
    this.handleOutsideClick = this.handleOutsideClick.bind(this);
  }

  componentWillUnmount() {
    document.removeEventListener('click', this.handleOutsideClick, false);
  }

  handleOutsideClick(event) {
    if (this.base.contains(event.target)) return;
    this.toggleDropDown();
  }

  toggleDropDown() {
    const { isOpen } = this.state;

    if (!isOpen) {
      document.addEventListener('click', this.handleOutsideClick, false);
    } else {
      document.removeEventListener('click', this.handleOutsideClick, false);
    }

    this.setState({ isOpen: !isOpen });
  }

  render(
    { account, merchant, onClickLogout, uiVisibility, helpcenterLink },
    { isOpen },
  ) {
    const preventDefault = (callback) => (event) => {
      event.preventDefault();
      callback(event);
    };
    const handleClick = (name) => (event) => {
      this.toggleDropDown();
      this.props.onClickButton(name, event);
    };
    const handleProductTourClick = (event) => {
      handleClick('product_tour')(event);
      window.Userlane('openAssistant');
    };
    const handleUserProfileClick = (event) => {
      if (this.state.isOpen) {
        this.toggleDropDown();
      } else {
        this.toggleDropDown();
        this.props.onClickButton('show_profile_actions', event);
      }
    };
    const handleLogout = (event) => {
      handleClick('logout', event);
      onClickLogout();
    };

    const {
      user_profile: { company: companyPermission, settings: settingPermission },
    } = uiVisibility;

    const referallBtnTitle = t('button.referral.title', {
      amount: `500€`,
    });

    return (
      <div class={cn('as-Profile', { 'is-open': isOpen })}>
        <Button
          class="as-Profile-toggleActions"
          title={t('button.profile.title')}
          onClick={preventDefault(handleUserProfileClick)}
        >
          <ProfileImage
            src={account.avatarUrl}
            placeholder={
              <shore-initials
                class="as-ProfileImage"
                first={account.firstName}
                last={account.lastName}
                bgcolor={account.color}
              />
            }
          />
          <div class="as-Profile-info">
            <span class="as-Profile-userName u-truncateText">
              {account.displayName}
            </span>
            {merchant.name && (
              <span class="as-Profile-locationName u-truncateText">
                {t('button.profile.at-location', {
                  location: merchant.name,
                })}
              </span>
            )}
          </div>
          <shore-icon name="arrow-down" class="as-Profile-arrow" />
        </Button>
        <nav class="as-Profile-actions">
          <Button
            href="/user-profile"
            class="as-Profile-navButton u-truncateText"
            title={t('button.account.title')}
            onClick={handleClick('account_settings')}
          >
            {t('button.account.title')}
          </Button>
          {companyPermission === ACTIVE && (
            <Button
              href="/settings"
              class="as-Profile-navButton u-truncateText"
              title={t('button.company.title')}
              onClick={handleClick('company_subscriptions')}
            >
              {t('button.company.title')}
            </Button>
          )}
          {settingPermission === ACTIVE && (
            <Button
              href="/application-settings"
              class="as-Profile-navButton u-truncateText"
              title={t('button.application-settings.title')}
              onClick={handleClick('application_settings')}
            >
              {t('button.application-settings.title')}
            </Button>
          )}
          {helpcenterLink && (
            <Button
              class="as-Button as-Profile-navButton u-truncateText"
              title={t('button.help-center.title')}
              href={helpcenterLink}
              target="_blank"
              rel="noopener noreferrer"
              onClick={handleClick('help_center')}
              dataRoute={false}
            >
              {t('button.help-center.title')}
            </Button>
          )}

          {window.Userlane && (
            <Button
              class="as-Button as-Profile-navButton u-truncateText"
              title={t('button.product-tour.title')}
              onClick={handleProductTourClick}
            >
              {t('button.product-tour.title')}
            </Button>
          )}

          {account.roles.length > 1 && (
            <Button
              href="/locations"
              class="as-Profile-navButton u-truncateText"
              title={t('button.location.title')}
              onClick={handleClick('location_picker')}
            >
              {t('button.location.title')}
              <span class="as-Profile-icon as-Profile-iconLocation">
                <shore-icon name="location" />
              </span>
            </Button>
          )}

          {CELLO_PRODUCT_ID && (
            <div class="as-Profile-refLink">
              <Button
                class="as-Button as-Profile-navButton u-truncateText"
                title={referallBtnTitle}
                onClick={() => openCelloWidget()}
              >
                {referallBtnTitle}&nbsp;
                <shore-badge
                  class="as-Profile-badgeNew"
                  number={t(
                    'notifications.appointment.states.created-by-merchant-profile.headline',
                  )}
                />
              </Button>
            </div>
          )}

          <Button
            class="as-Profile-navButton u-truncateText"
            title={t('button.logout.title')}
            onClick={handleLogout}
          >
            {t('button.logout.title')}
            <span class="as-Profile-icon as-Profile-iconLogout">
              <shore-icon name="logout" />
            </span>
          </Button>
        </nav>
      </div>
    );
  }
}

export default Profile;
