import { h } from 'preact';
import { PageLayout } from '../Layouts';
import { t } from '../../utils/i18n';

const apiClient = {};
const eventBus = {};
const trackMethod = () => {};

export function Offline() {
  return (
    <PageLayout
      apiClient={apiClient}
      eventBus={eventBus}
      trackMethod={trackMethod}
    >
      <div class="as-Offline">
        <h1 class="title">{t('page-404.title')}</h1>
        <p>{t('offline.description')}</p>
        <a href="https://shore.instatus.com/" target="_blank" rel="noreferrer">
          <shore-button variant="primary">
            {t('offline.button.status-page')}
          </shore-button>
        </a>
      </div>
    </PageLayout>
  );
}
