/* eslint-disable import/prefer-default-export */
import { h } from 'preact';
import { useState, useEffect } from 'preact/hooks';
import { useMutation } from 'react-query';
// eslint-disable-next-line import/no-extraneous-dependencies
import { MailTwoTone } from '@ant-design/icons';
import { Image, Result, Button, Typography, Space } from 'antd';
import { getLogoUrl } from '../../utils/url';
import { COMPANY_NAME, API_BASE_URL } from '../../constants';
import { t } from '../../utils/i18n';

export function EmailVerification() {
  const logoUrl = getLogoUrl(COMPANY_NAME);
  const [isTimerActive, setIsTimerActive] = useState(false);
  const [timeLeft, setTimeLeft] = useState(0);

  const { mutate: mutateSendEmail } = useMutation({
    mutationKey: ['email_confirmation'],
    mutationFn: () => {
      const emailVerificationUrl = `${API_BASE_URL}/v2/email_confirmation/resend`;
      return fetch(emailVerificationUrl, {
        method: 'POST',
        credentials: 'include',
      });
    },
  });

  const handleOnClickSendEmail = () => {
    setIsTimerActive(true);
    setTimeLeft(60);
    mutateSendEmail();
  };

  useEffect(() => {
    let timer;
    if (isTimerActive && timeLeft > 0) {
      timer = setInterval(() => {
        setTimeLeft((prevTime) => prevTime - 1);
      }, 1000);
    } else if (timeLeft === 0) {
      setIsTimerActive(false);
    }
    return () => clearInterval(timer);
  }, [isTimerActive, timeLeft]);

  return (
    <div class="as-EmailVerification">
      <header class="as-EmailVerification-header">
        <Image alt="logo" src={logoUrl} height={25} preview={false} />
      </header>
      <main class="as-EmailVerification-body">
        <section class="as-EmailVerification-bodyInner">
          <Result
            icon={<MailTwoTone twoToneColor="#00d0be" />}
            title={
              <Typography.Title level={4}>
                {t('email-verification.title')}
              </Typography.Title>
            }
            subTitle={
              <Space direction="vertical" size="middle">
                <Typography.Text type="secondary">
                  {t('email-verification.description')}
                </Typography.Text>
                {isTimerActive && (
                  <Typography.Text type="secondary" strong>
                    {t('email-verification.retry-counter', {
                      count: timeLeft,
                    })}
                  </Typography.Text>
                )}
              </Space>
            }
            extra={[
              <Button
                type="primary"
                key="email-verification"
                disabled={isTimerActive}
                onClick={handleOnClickSendEmail}
              >
                {t('email-verification.button')}
              </Button>,
            ]}
          />
          {COMPANY_NAME === 'shore' && (
            <Typography.Text type="secondary" style={{ fontSize: 10 }}>
              {t('email-verification.support-question-text')}{' '}
              <Typography.Link
                href="https://help.shore.com/"
                target="_blank"
                style={{
                  fontSize: '10px',
                  color: '#222222',
                  textDecoration: 'underline',
                }}
              >
                {t('email-verification.support-link-text')}
              </Typography.Link>
            </Typography.Text>
          )}
        </section>
      </main>
    </div>
  );
}
