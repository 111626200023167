import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Header from '../../components/Header';
import {
  navigateTo,
  clickHeaderButton,
  clickLogout,
  notificationSMSDisplaySeverity,
  proxyAppAction,
} from '../../actions';
import { COMPANY_NAME, SEVERITY } from '../../constants';
import { getLogoUrl } from '../../utils/url';
import {
  getAppSettings,
  getTaskProgress,
  getUiVisibility,
} from '../../selectors';

const isAdmin = (currentAccount, currentMerchant) =>
  currentAccount.organization_admin ||
  currentAccount.roles
    .filter(({ merchant: { id } }) => id === currentMerchant.id)
    .reduce((acc, { name }) => acc || ['admin'].includes(name), false);

const mapStateToProps = (state) => ({
  logoUrl: COMPANY_NAME && getLogoUrl(COMPANY_NAME),
  merchant: state.currentMerchant,
  account: state.currentAccount,
  company: getAppSettings(state).company,
  taskProgress: getTaskProgress(state),
  uiVisibility: getUiVisibility(state),
  severity: state.notifications.severity,
  isAdmin: isAdmin(state.currentAccount, state.currentMerchant),
  settings: getAppSettings(state),
  whiteLabelSchemaless:
    getAppSettings(state).whiteLabelConfiguration.schemaless,
  locale: state.currentAccount.locale,
});

const mapDispatchToProps = (dispatch, { onProxyAction }) =>
  bindActionCreators(
    {
      onAction: (action) => proxyAppAction(onProxyAction)('launchpad', action),
      onSearchActivation: () => navigateTo('#/search'),
      onClickButton: clickHeaderButton,
      onClickLogout: clickLogout,
      onClickSMSClose: () => notificationSMSDisplaySeverity(SEVERITY.NONE),
    },
    (action) => action && dispatch(action),
  );

export default connect(mapStateToProps, mapDispatchToProps)(Header);
