import { h } from 'preact';
import { useEffect, useRef } from 'preact/compat';

import { window } from '../src/env/browser';
import { useGlobals } from '../src/globals-context';

import useCreateAppConfig from './use-create-app-config';
import {
  containerStyle,
  iframeStyle,
  MICRO_APP_STARTUP_MESSAGE,
  MICRO_APP_URL,
} from './constants';

const APP_NAME = 'locations';

export function LocationsApp() {
  const deps = useGlobals();
  const iframeRef = useRef(null);
  const { isLoading, config } = useCreateAppConfig(APP_NAME, deps);

  useEffect(() => {
    if (iframeRef.current && !isLoading) {
      setTimeout(() => {
        window.Shore.microAppArgs[APP_NAME] = config;
        iframeRef.current.contentWindow.postMessage(
          { type: MICRO_APP_STARTUP_MESSAGE },
          '*',
        );
      }, 1000);
    }
  }, [iframeRef.current, isLoading]);

  return (
    <div style={containerStyle}>
      <iframe
        title="LocationsApp"
        ref={iframeRef}
        style={iframeStyle}
        src={`${MICRO_APP_URL}/locations`}
      />
    </div>
  );
}
